import "./single-choice.css";
import { useNavigate } from "react-router";
import { useEffect, useState } from "react";
import ResultService from "../../../services/ResultService";
import { submissionAnswers } from "../../../routes/Lesson";
import SubmissionService from "../../../services/SubmissionService";

function SingleChoice({ signal, currentExercise, setCurrentExercise, isResult, submittedChoice }) {
  const navigate = useNavigate();
  const [selectedChoice, setSelectedChoice] = useState("");
  const [isSubmited, setIsSubmited] = useState(isResult);
  const [isCorrect, setIsCorrect] = useState(false);
  const question = signal.value.sections[0].questions[currentExercise];
  const numberOfQuestions = signal.value.sections[0].questions.length;

  useEffect(() => {
    if (isResult) {
      setSelectedChoice(submittedChoice);
    } else {
      let radidoBtns = document.querySelectorAll(`input[name='${question.id}']`);
      let findSelected = () => {
        let selected = document.querySelector(`input[name='${question.id}']:checked`).value;
        setSelectedChoice(selected);
      };

      radidoBtns.forEach((radioBtn) => {
        radioBtn.addEventListener("change", findSelected);
      });

      return () => {
        radidoBtns.forEach((radioBtn) => {
          radioBtn.removeEventListener("change", findSelected);
        });
      };
    }
  });

  useEffect(() => {
    if (selectedChoice) {
      const copy = [...submissionAnswers.value];
      const submissionIndex = submissionAnswers.value.findIndex(
        (submission) => submission.questionId === question.id
      );
      if (submissionIndex === -1) {
        copy.push({
          questionId: question.id,
          answer: selectedChoice
        });
      } else {
        copy[submissionIndex] = {
          questionId: question.id,
          answer: selectedChoice
        };
      }
      submissionAnswers.value = copy;

      if (isResult) {
        setIsCorrect(question.answer === selectedChoice);
      } else {
        setIsCorrect(question.answer === selectedChoice && selectedChoice);
      }
    }
  }, [selectedChoice]);

  useEffect(() => {
    if (!isResult) {
      if (isSubmited) {
        if (isCorrect) {
          const audio = new Audio("/assets/sound-effects/correct.mp3");
          audio.play();
          audio.remove();
        } else {
          const audio = new Audio("/assets/sound-effects/wrong.mp3");
          audio.play();
          audio.remove();
        }
      }
    }
  }, [isSubmited]);

  async function onClickContinue() {
    signal.value = {
      ...signal.value,
      sections: signal.value.sections.map((section) => ({
        ...section,
        questions: section.questions.map((question, i) => {
          if (i === currentExercise) {
            return {
              ...question,
              isFinished: true,
              userChoice: selectedChoice
            };
          }
          return question;
        })
      }))
    };

    if (currentExercise === numberOfQuestions - 1) {
      try {
        const res = await ResultService.startExam({ id: signal.value.id });
        const resultId = res.data.id;

        await SubmissionService.submitAnswers({
          resultId,
          answers: submissionAnswers.value
        });

        submissionAnswers.value = [];

        navigate(`/result?id=${resultId}`);
      } catch (error) {
        console.error(error);
      }
    } else {
      setSelectedChoice("");
      setIsCorrect(false);
      setIsSubmited(false);
      setCurrentExercise(currentExercise + 1);
    }
  }

  return (
    <div className="w-full py-14 flex flex-col items-center">
      <h3 className="w-[90%] md:w-[60%] text-center text-[#1B2559] text-lg font-semibold leading-normal mb-[2.3rem]">
        {question.title}
      </h3>
      {isSubmited ? (
        <>
          <div className="container">
            <div className="radio-tile-group">
              {question.content.map((choice, index) => (
                <div key={index}>
                  <div className="input-container">
                    <div className="radio-tile">
                      <img
                        src={
                          question.answer === choice
                            ? "/assets/correct-check-icon.svg"
                            : selectedChoice === choice
                              ? "/assets/incorrect-check-icon.svg"
                              : "/assets/check-box.svg"
                        }
                        alt="check box"
                        id="check-box"
                      />
                      <label htmlFor="answer">
                        <p
                          className={`${
                            question.answer === choice
                              ? "text-[#28B446]"
                              : selectedChoice === choice
                                ? "text-[#E94444]"
                                : "text-[#7D8BB5]"
                          }`}>
                          {choice}
                        </p>
                      </label>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="flex flex-col w-full items-center">
            <div
              className={`w-[39rem] h-[0.0625rem] bg-[#A3AED0] mt-4 ${
                isResult ? "w-[90%] block" : "hidden"
              }`}
            />
            <p
              className={`text-[#2B3674] text-lg font-bold leading-[118.519%] my-8 ${
                isResult && "hidden"
              }`}>
              {isCorrect ? "Correct answer! 👏" : "Wrong answer"}
            </p>
            <button
              onClick={onClickContinue}
              className={`w-[17rem] h-[2.875rem] rounded-2xl bg-[#3D10EA] text-white text-sm font-semibold leading-normal tracking-[-0.0175rem] ${
                isResult && "hidden"
              }`}>
              Continue
            </button>
          </div>
        </>
      ) : (
        <>
          <div className="container">
            <div className="radio-tile-group">
              {question.content.map((choice, index) => (
                <div key={index}>
                  <div className="input-container">
                    <input type="radio" name={question.id} value={choice} />
                    <div className="radio-tile">
                      <img src="/assets/check-icon.svg" alt="check" id="check" />
                      <img src="/assets/check-box.svg" alt="check box" id="check-box" />
                      <label htmlFor="answer">
                        <p>{choice}</p>
                      </label>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <button
            onClick={() => setIsSubmited(true)}
            className={`w-[17rem] h-[2.875rem] mt-7 rounded-2xl bg-[#3D10EA] text-white text-sm font-semibold leading-normal tracking-[-0.0175rem] ${
              !selectedChoice && "bg-gray-300 cursor-default"
            }`}
            disabled={!selectedChoice}>
            Submit
          </button>
        </>
      )}
    </div>
  );
}

export default SingleChoice;
