import { Link } from "react-router-dom";
import SingleInputField from "../common/SingleInputField";
import { useState } from "react";

function NewPassWord() {
  const [password, setPassword] = useState({
    value: "",
    visible: false
  });
  const [confirmPassword, setConfirmPassword] = useState({
    value: "",
    visible: false
  });

  const passwordFields = [
    {
      id: "new-password",
      tailingComponent: (
        <img
          src={`${
            password.visible
              ? "../../../assets/visibility-off-icon.svg"
              : "../../../assets/visibility-icon.svg"
          }`}
          alt="password visibility icon"
        />
      ),
      type: password.visible ? "text" : "password",
      value: password.value,
      label: "Mật khẩu mới",
      onChange: (value) => setPassword({ ...password, value: value })
    },
    {
      id: "confirm-password",
      tailingComponent: (
        <img
          src={`${
            confirmPassword.visible
              ? "../../../assets/visibility-off-icon.svg"
              : "../../../assets/visibility-icon.svg"
          }`}
          alt="confirm password visibility icon"
        />
      ),
      type: confirmPassword.visible ? "text" : "password",
      value: confirmPassword.value,
      label: "Xác nhận mật khẩu",
      onChange: (value) => setConfirmPassword({ ...confirmPassword, value: value })
    }
  ];
  return (
    <div className="relative w-full flex flex-col items-center px-5 md:px-0 mb-12 md:mb-0">
      <div className="w-full absolute -top-10 md:top-auto md:relative md:w-[21.875rem] font-[Inter] text-[#2B3674] text-lg text-center font-semibold leading-8 tracking-[-0.03375rem] uppercase">
        <h1>Thiết lập mật khẩu</h1>
      </div>
      <div className="w-full px-5 md:px-0 md:w-[23.375rem] md:h-[18.625rem] rounded-xl bg-white flex flex-col items-center mt-4 md:mt-8">
        <p className="text-[#2B3674] text-sm font-[Inter] font-normal leading-[1.375rem] tracking-[-0.02625rem] my-5">
          Tạo mật khẩu mới cho tài khoản của bạn
        </p>
        <div className="w-full flex flex-col items-center gap-4">
          {passwordFields.map((field, index) => (
            <SingleInputField
              id={field.id}
              key={index}
              tailingComponent={field.tailingComponent}
              type={field.type}
              value={field.value}
              label={field.label}
              onChange={field.onChange}
            />
          ))}
        </div>
        <button className="w-full py-6 md:w-[20.375rem] md:h-[3.75rem] my-5 bg-[#3D10EA] rounded-xl md:rounded-lg text-white text-base font-[Inter] font-semibold leading-4 tracking-[-0.03rem]">
          Xác nhận
        </button>
      </div>
      <div className="w-full md:w-[23.375rem] mt-[1.6rem] flex justify-center">
        <p className="text-[#2B3674] text-sm font-[Inter] font-medium leading-[1.375rem] tracking-[-0.02625rem]">
          Nhớ mật khẩu?{" "}
          <Link to="/login" className="font-bold">
            Đăng nhập
          </Link>
        </p>
      </div>
    </div>
  );
}

export default NewPassWord;
