import axiosClient from "./AxiosClient";

class TransactionService {
  static getSumTransactions() {
    return axiosClient.get("/transactions/sum");
  }

  static getTransactions({ page, size, sortBy, order }) {
    return axiosClient.get("/transactions", {
      params: {
        page,
        size,
        sortBy,
        order
      }
    });
  }
}

export default TransactionService;
