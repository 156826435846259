import axiosClient from "./AxiosClient";

class ExamService {
  static getExam({ id }) {
    return axiosClient.get(`/exams?id=${id}`);
  }

  static getExamEditor({ id }) {
    return axiosClient.get(`/exams/editor?id=${id}`);
  }

  static searchExams({ page, size, sortBy, order, searchCriteriaList, dataOption }) {
    return axiosClient.post(
      `/exams/search?sortBy=${sortBy}&page=${page}&size=${size}&order=${order}`,
      {
        dataOption,
        searchCriteriaList
      }
    );
  }

  static createExam({ title, description, duration, order }) {
    return axiosClient.post("/exams", {
      title,
      description,
      duration: Number.parseInt(duration),
      order
    });
  }

  static createFullExam({ exam }) {
    exam = {
      ...exam,
      duration: Number.parseInt(exam.duration),
      sections: exam.sections.map((section) => {
        return {
          ...section,
          questions: section.questions.map((question) => {
            return {
              ...question,
              content: JSON.stringify(question.content)
            };
          })
        };
      })
    };

    return axiosClient.post("/exams/full", exam);
  }

  static startExam({ id }) {
    return axiosClient.post("/results/start-exam", {
      examId: id,
      startedAt: new Date().toISOString()
    });
  }

  static deleteExam({ id }) {
    return axiosClient.delete(`/exams?id=${id}`);
  }

  static deleteExams({ ids }) {
    return axiosClient.post("/exams/bulk-delete", {
      ids
    });
  }

  static updateExam({ id, title, description, duration, order, hasCertificate, certificateId }) {
    return axiosClient.put("/exams", {
      id,
      title,
      description,
      duration: Number.parseInt(duration),
      order,
      hasCertificate,
      certificateId
    });
  }
}

export default ExamService;
