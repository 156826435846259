import { DndContext, closestCenter } from "@dnd-kit/core";
import { SortableContext, arrayMove, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { restrictToFirstScrollableAncestor, restrictToVerticalAxis } from "@dnd-kit/modifiers";
import { exam } from "../../../signals/ExamSignal";
import { SortableOption } from "./SortableOption";

export function SortableOptionsContainer({ ariaLabel, sectionIndex, questionIndex }) {
  const question = exam.value.sections[sectionIndex].questions[questionIndex];
  const content = exam.value.sections[sectionIndex].questions[questionIndex].content;
  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      const oldIndex = items.findIndex((i) => i === active.id);
      const newIndex = items.findIndex((i) => i === over.id);
      const newArray = arrayMove(items, oldIndex, newIndex);

      if (question.type === "CONVERSATION_SINGLE_CHOICE") {
        exam.value.sections[sectionIndex].questions[questionIndex].content.choices = newArray;
      } else {
        exam.value.sections[sectionIndex].questions[questionIndex].content = newArray;
      }
    }
  };

  const getItems = () => {
    if (question.type === "CONVERSATION_SINGLE_CHOICE") {
      return content.choices;
    } else {
      return content;
    }
  };

  const items = getItems();

  return (
    <DndContext
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
      modifiers={[restrictToVerticalAxis, restrictToFirstScrollableAncestor]}>
      <SortableContext items={items} strategy={verticalListSortingStrategy}>
        {items.map((item, index) => (
          <SortableOption
            key={`${item}-${index}`}
            id={`${item}-${index}`}
            ariaLabel={ariaLabel}
            sectionIndex={sectionIndex}
            questionIndex={questionIndex}
            index={index}
            option={item}
          />
        ))}
      </SortableContext>
    </DndContext>
  );
}
