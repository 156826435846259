import { EditorSortableQuestionsContainer } from "../question/EditorSortableQuestionsContainer";
import { EditorSectionHeader } from "./EditorSectionHeader";

export function EditorSection({ index }) {
  return (
    <div>
      <EditorSectionHeader index={index} />
      <EditorSortableQuestionsContainer sectionIndex={index} />
    </div>
  );
}
