import React, { useEffect, useState } from "react";
import { signal } from "@preact/signals-react";
import { Transition, Menu } from "@headlessui/react";
import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from "@mui/material";
import ExamService from "../services/ExamService";
import { useNavigate, useSearchParams } from "react-router-dom";
import Util from "../utils/Util";
import CookieService from "../services/CookieService";
import CustomPagination from "../components/CustomPagination";
import LectureService from "../services/LectureService";
import { exam } from "../signals/ExamSignal";
import CertificateService from "../services/CertificateService";

const pageSignal = signal(1);
const lectureSignal = signal({});
const modalOpen = signal(false);
const openingExamSignal = signal(null);
const searchCriteriaListSignal = signal([
  {
    filterKey: "title",
    operation: "cn",
    value: ""
  }
]);
const examsSignal = signal({
  result: [],
  totalPages: 0
});

const listViewModeSignal = signal(false);
const deletingExamIdsSignal = signal([]);
const selectAllSignal = signal(false);

const certificatesSignal = signal([]);

const searchExams = async (lectureId) => {
  try {
    const userId = Util.getClaim(CookieService.getCookie("token"), "id");
    if (userId && lectureId) {
      const searchCriteriaList = [
        {
          filterKey: "userId",
          operation: "eq",
          value: userId
        },
        {
          filterKey: "lectureId",
          operation: "eq",
          value: lectureId
        },
        ...searchCriteriaListSignal.value
      ];

      const res = await ExamService.searchExams({
        page: pageSignal.value,
        size: 12,
        sortBy: "createdAt",
        order: 0,
        dataOption: "all",
        searchCriteriaList: searchCriteriaList
      });

      return res.data;
    }
  } catch (error) {
    console.error(error);
  }
};

function EditorExamListOfLecturePage() {
  const navigate = useNavigate();
  let [searchParams] = useSearchParams();

  useEffect(() => {
    pageSignal.value = 1;

    if (searchParams.get("id")) {
      LectureService.getLecture({ id: searchParams.get("id") })
        .then((res) => {
          lectureSignal.value = res.data;
          exam.value = {
            ...exam.value,
            lectureId: res.data.id
          };
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [searchParams.get("id")]);

  useEffect(() => {
    searchExams(lectureSignal.value.id)
      .then((res) => {
        examsSignal.value = res;
      })
      .catch((error) => {
        console.error(error);
      });

    selectAllSignal.value = false;
    deletingExamIdsSignal.value = [];
  }, [pageSignal.value, lectureSignal.value.id]);

  return (
    <div className="py-12 px-16 bg-[#F4F7FE] min-h-[calc(100vh-6rem)] w-full grow">
      <FormDialog />
      {lectureSignal.value.id && (
        <h1 className="text-6xl font-bold mb-8">Exams of {lectureSignal.value.title}</h1>
      )}
      <div className="w-full h-max flex items-center justify-between mb-10">
        <button
          onClick={() => navigate("/editor/new")}
          className="w-max h-max bg-[#2639ED] rounded-md text-white text-sm font-medium leading-3 flex items-center justify-start px-5 gap-4 py-3 disabled:bg-gray-300"
          disabled={(examsSignal.value?.result?.length || 0) > 0}>
          <img src="/assets/plus.svg" alt="plus" />
          <p>New exam</p>
        </button>
        <div className="flex items-center justify-end gap-6">
          <SearchBar />
          <img
            style={{
              filter: listViewModeSignal.value
                ? "brightness(0) saturate(100%) invert(18%) sepia(88%) saturate(1968%) hue-rotate(214deg) brightness(86%) contrast(96%)"
                : "none"
            }}
            className="w-5 h-5 cursor-pointer"
            src="/assets/list-view.svg"
            alt="list view"
            onClick={() => {
              listViewModeSignal.value = true;
            }}
          />
          <img
            style={{
              filter: !listViewModeSignal.value
                ? "brightness(0) saturate(100%) invert(18%) sepia(88%) saturate(1968%) hue-rotate(214deg) brightness(86%) contrast(96%)"
                : "none"
            }}
            className="w-5 h-5 cursor-pointer"
            src="/assets/grid-view.svg"
            alt="grid view"
            onClick={() => {
              listViewModeSignal.value = false;
            }}
          />
        </div>
      </div>
      <div className="flex items-center gap-5">
        <div className="w-56 h-20">
          <FilterButton
            icon={{ src: "/assets/filter-clock.svg", alt: "list all" }}
            text="All"
            onClick={() => {}}
          />
        </div>
        <div className="w-56 h-20">
          <FilterButton
            icon={{ src: "/assets/filter-favorite.svg", alt: "favorite" }}
            text="Favorite"
            onClick={() => {}}
          />
        </div>
      </div>
      <EditorExamList
        data={examsSignal.value?.result || []}
        pagingSignal={pageSignal}
        totalPages={examsSignal.value?.totalPages || 0}
        listViewMode={listViewModeSignal.value}
      />
    </div>
  );
}

function FilterButton({ text, icon, onClick }) {
  return (
    <button
      className="w-full h-full flex items-center justify-start gap-4 px-4 py-5 pr-7 bg-white rounded-[1.25rem]"
      onClick={onClick}>
      <img src={icon.src} alt={icon.alt} />
      <p className="text-[#2B3674] font-bold text-xl">{text}</p>
    </button>
  );
}

function EditorExamList({ data, pagingSignal, totalPages, listViewMode }) {
  const handleSelectAll = (e) => {
    if (e.target.checked) {
      selectAllSignal.value = true;
      deletingExamIdsSignal.value = data.map((item) => item.id);
    } else {
      selectAllSignal.value = false;
      deletingExamIdsSignal.value = [];
    }
  };

  const handleDelete = async () => {
    try {
      await ExamService.deleteExams({ ids: deletingExamIdsSignal.value });
      const res = await searchExams(lectureSignal.value.id);
      examsSignal.value = res;
      selectAllSignal.value = false;
      deletingExamIdsSignal.value = [];
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      {data.length < 1 ? (
        <div className="w-full h-[35rem] flex flex-col items-center justify-start">
          <img src="/assets/empty.png" alt="empty" />
          <div className="-mt-24 text-center">
            <p className="text-[#4A4A4A] text-xl font-bold">There is no exam</p>
            <p className="text-[#4B4B4B] text-xl font-normal leading-6">
              Let&apos;s create a new one!
            </p>
          </div>
        </div>
      ) : (
        <div className="flex flex-col mt-[3.44rem]">
          <div className="flex gap-3 flex-wrap mb-16">
            {data.map((exam, index) =>
              listViewMode ? (
                <ExamListRow key={index} index={index} exam={exam} />
              ) : (
                <ExamCard key={index} exam={exam} />
              )
            )}
          </div>
          <div className={`flex items-center justify-start ${listViewMode ? "w-[80%]" : "w-full"}`}>
            {listViewMode && (
              <div className="flex items-center pl-6">
                <Checkbox onChange={handleSelectAll} />
                <p>Select all</p>
                <button
                  className="flex items-center ml-8 text-[#004EC3] text-sm gap-2 pt-[0.56rem] pb-[0.62rem] px-4 bg-[#C6E6FF] rounded-full"
                  onClick={handleDelete}>
                  <i className="mb-0.5">
                    <img src="/assets/recycle-bin.svg" alt="recycle bin" />
                  </i>
                  <p>Delete</p>
                </button>
              </div>
            )}
            <div
              className={`flex grow items-center ${
                listViewMode ? "justify-end" : "justify-center"
              }`}>
              <CustomPagination
                count={totalPages}
                setCurrentPage={(page) => {
                  pagingSignal.value = page;
                }}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
}

function SearchBar() {
  const [searchText, setSearchText] = useState("");

  const handleSearch = (e) => {
    e.preventDefault();
    searchCriteriaListSignal.value = searchCriteriaListSignal.value.map((item) => {
      if (item.filterKey === "title") {
        return {
          ...item,
          value: searchText
        };
      }
      return item;
    });
    searchExams(lectureSignal.value.id).then((res) => {
      examsSignal.value = res;
    });
  };

  return (
    <form className="px-8 py-4 bg-white rounded-full flex items-center justify-start gap-5">
      <button onClick={handleSearch}>
        <img
          className="w-[0.8125rem] h-[0.8125rem]"
          src="/assets/magnifying-glass.svg"
          alt="search"
        />
      </button>
      <input
        className="placeholder:text-[#8F9BBA] text-base font-normal outline-none"
        type="text"
        placeholder="Search"
        value={searchText}
        onChange={(e) => {
          setSearchText(e.target.value);
        }}
      />
    </form>
  );
}

function ExamCard({ exam }) {
  const navigate = useNavigate();

  const handleDelete = async () => {
    try {
      await ExamService.deleteExam({ id: exam.id });
      const res = await searchExams(lectureSignal.value.id);
      examsSignal.value = res;
    } catch (error) {
      console.error(error);
    }
  };

  const openEditModal = () => {
    modalOpen.value = true;
    openingExamSignal.value = exam;
  };

  return (
    <div className="w-[19rem] h-[21rem] relative flex flex-col">
      <img
        className="h-[9.375rem] w-full object-cover rounded-t"
        src="/assets/exam-thumbnail.jpg"
        alt="thumbnail"
      />
      <div className="px-3 pt-2 bg-white pb-[1.55rem] grow flex flex-col rounded-b">
        <div className="flex items-center justify-between">
          <h6
            className="text-[#4B4B4B] text-base font-normal hover:underline cursor-pointer"
            onClick={() => {
              navigate(`/editor/exam?id=${exam.id}`);
            }}>
            {exam.title}
          </h6>
          <i>
            <img src="/assets/star.svg" alt="star" />
          </i>
        </div>
        <div className="flex items-center gap-[0.4rem] mt-auto">
          <img src="/assets/clock.svg" alt="duration" />
          <p className="text-[#8E8E8E] text-sm font-normal">{exam.duration} mins</p>
        </div>
        <Menu as="div" className="absolute right-3 bottom-3 text-left z-10">
          <div>
            <Menu.Button className="inline-flex justify-center w-6 h-6 items-center">
              <i>
                <img src="/assets/three-dots.svg" alt="three dot" />
              </i>
            </Menu.Button>
          </div>
          <Transition
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95">
            <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
              <div className="px-1 py-1 ">
                <Menu.Item>
                  {({ active }) => (
                    <button
                      className={`${
                        active ? "bg-violet-500 text-white" : "text-gray-900"
                      } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                      onClick={openEditModal}>
                      Edit
                    </button>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <button
                      className={`${
                        active ? "bg-violet-500 text-white" : "text-gray-900"
                      } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                      onClick={handleDelete}>
                      Delete
                    </button>
                  )}
                </Menu.Item>
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
    </div>
  );
}

function ExamListRow({ exam, index }) {
  const navigate = useNavigate();
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    if (selectAllSignal.value) {
      setChecked(true);
    } else {
      setChecked(deletingExamIdsSignal.value.includes(exam.id));
    }
  }, [selectAllSignal.value, deletingExamIdsSignal.value]);

  const handleCheck = (e) => {
    if (e.target.checked) {
      setChecked(true);
      deletingExamIdsSignal.value = [...deletingExamIdsSignal.value, exam.id];
    } else {
      setChecked(false);
      deletingExamIdsSignal.value = deletingExamIdsSignal.value.filter((id) => id !== exam.id);
    }
  };

  return (
    <div
      className={`flex items-center w-[80%] h-14 text-[#4B4B4B] text-sm font-normal pl-6 ${
        index % 2 === 0 ? "" : "bg-white"
      }`}>
      <div className="w-[5%]">
        <Checkbox onChange={handleCheck} checked={checked} />
      </div>
      <p
        className="w-1/5 hover:underline cursor-pointer"
        onClick={() => {
          navigate(`/editor/exam?id=${exam.id}`);
        }}>
        {exam.title}
      </p>
      <p className="w-[10%]">{exam.duration}</p>
    </div>
  );
}

function FormDialog() {
  const [isSaving, setIsSaving] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [id, setId] = useState("");
  const [order, setOrder] = useState(0);
  const [duration, setDuration] = useState(0);
  const [hasCertificate, setHasCertificate] = useState(false);
  const [certificateId, setCertificateId] = useState("no-cert");
  const [error, setError] = useState("");

  useEffect(() => {
    const getCertificates = async () => {
      try {
        const res = await CertificateService.getAllCertificates();
        certificatesSignal.value = res.data;
      } catch (error) {
        console.error(error);
      }
    };

    getCertificates();
  }, []);

  useEffect(() => {
    if (openingExamSignal.value) {
      setTitle(openingExamSignal.value.title);
      setDescription(openingExamSignal.value.description);
      setId(openingExamSignal.value.id);
      setOrder(openingExamSignal.value.order);
      setDuration(openingExamSignal.value.duration);
      setHasCertificate(openingExamSignal.value.hasCertificate);
      setCertificateId(openingExamSignal.value.certificate?.id || "no-cert");
    }
  }, [openingExamSignal.value]);

  const handleClose = () => {
    modalOpen.value = false;
    clearForm();
  };

  const handleSave = async () => {
    const requiredFields = [title];

    if (requiredFields.some((field) => !field)) {
      setErrorMessage("Please fill in all required fields");
      return;
    }

    setIsSaving(true);

    try {
      if (id) {
        await ExamService.updateExam({
          id,
          title,
          description,
          order,
          duration,
          hasCertificate,
          certificateId: certificateId === "no-cert" ? null : certificateId
        });
      }

      const res = await searchExams(lectureSignal.value.id);
      examsSignal.value = res;
    } catch (error) {
      console.error(error);
      if (error.response) {
        setErrorMessage(error.response.data.message);
        return;
      }
    }

    setIsSaving(false);
    clearForm();
    modalOpen.value = false;
  };

  const clearForm = () => {
    setTitle("");
    setDescription("");
    setId("");
    setOrder(0);
    setDuration(0);
    setHasCertificate(false);
    setCertificateId(null);
    openingExamSignal.value = null;
    setError("");
  };

  const handleTitleChange = (e) => {
    setError("");
    setTitle(e.target.value);
  };

  const handleDescriptionChange = (e) => {
    setError("");
    setDescription(e.target.value);
  };

  // const handleOrderChange = (e) => {
  //   setError("");
  //   // check if valid number and > 0
  //   if (isNaN(e.target.value)) return;
  //   const value = parseInt(e.target.value);
  //   if (value < 0) return;
  //   setOrder(e.target.value);
  // };

  const handleDurationChange = (e) => {
    setError("");
    // check if valid number and > 0
    if (isNaN(e.target.value)) return;
    const value = parseInt(e.target.value);
    if (value < 0) return;
    setDuration(e.target.value);
  };

  const handleHasCertificateChange = (e) => {
    setHasCertificate(e.target.checked);
  };

  const handleCertificateChange = (e) => {
    setCertificateId(e.target.value);
  };

  const setErrorMessage = (message) => {
    setError(message);
    setIsSaving(false);
  };

  return (
    <React.Fragment>
      <Dialog open={modalOpen.value} onClose={handleClose}>
        <DialogTitle>{openingExamSignal.value ? "Edit chapter" : "New chapter"}</DialogTitle>
        <DialogContent>
          <DialogContentText
            sx={{
              color: "red"
            }}>
            {error}
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="title"
            label="Title"
            type="text"
            fullWidth
            variant="standard"
            value={title}
            required
            onChange={handleTitleChange}
          />
          <TextField
            margin="dense"
            id="description"
            label="Description"
            type="text"
            fullWidth
            variant="standard"
            multiline
            value={description}
            onChange={handleDescriptionChange}
          />
          <TextField
            margin="dense"
            id="lecture"
            label="Lecture"
            type="text"
            fullWidth
            variant="standard"
            defaultValue={lectureSignal.value.title}
            InputProps={{
              readOnly: true
            }}
          />
          {/* <TextField
            margin="dense"
            id="order"
            label="Order"
            type="number"
            fullWidth
            variant="standard"
            value={order}
            onChange={handleOrderChange}
          /> */}
          <TextField
            margin="dense"
            id="duration"
            label="Duration"
            type="number"
            fullWidth
            variant="standard"
            value={duration}
            onChange={handleDurationChange}
          />
          <InputLabel htmlFor="hasCertificate">
            Cấp chứng chỉ{" "}
            <Checkbox
              id="hasCertificate"
              checked={hasCertificate}
              onChange={handleHasCertificateChange}
            />
          </InputLabel>
          <InputLabel htmlFor="certificate">Chứng chỉ</InputLabel>
          <Select
            id="certificate"
            value={certificateId}
            onChange={handleCertificateChange}
            fullWidth
            variant="standard">
            <MenuItem value="no-cert">Không có chứng chỉ</MenuItem>
            {certificatesSignal.value.map((certificate) => (
              <MenuItem key={certificate.id} value={certificate.id}>
                {certificate.name}
              </MenuItem>
            ))}
          </Select>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSave}>{isSaving ? <CircularProgress /> : "Save"}</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default EditorExamListOfLecturePage;
