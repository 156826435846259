import "./filter.css";
import dayjs from "dayjs";
import * as React from "react";
import OptionFilter from "../OptionFilter";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";

export function Filter() {
  const [value, setValue] = React.useState(dayjs());
  return (
    <div className="w-[94%] md:w-[76%] md:py-12 md:bg-[#EDF2FF] rounded-[0.625rem] mt-6 mb-8 flex flex-wrap justify-center md:justify-start">
      <div className="flex flex-col w-[20rem] md:ml-16">
        <span
          className="text-[0.875rem] font-medium leading-[1.664rem]"
          style={{ color: "var(--text, #232323)" }}>
          Lorem ipsum dolor
        </span>
        <CalenderPicker value={value} setValue={setValue} />
      </div>
      <div className="flex flex-col mt-4 md:mt-0 w-[20rem] md:ml-16">
        <OptionFilter />
      </div>
      <div className="flex flex-col mt-4 md:mt-8 w-[20rem] md:ml-16">
        <OptionFilter />
      </div>
      <div className="flex flex-row mt-8 w-[20rem] items-end justify-evenly md:justify-start md:gap-4 md:pb-1 md:ml-16">
        <button className="w-[40%] h-[2.5rem] rounded-[6.25rem] border border-solid border-[#1E90FF] text-[#1E90FF] text-[0.8125rem] font-medium leading-5">
          Clear Filter
        </button>
        <button className="w-[40%] h-[2.5rem] rounded-[6.25rem] border border-solid bg-[#1E90FF] text-white text-[0.8125rem] font-medium leading-5">
          Filter
        </button>
      </div>
    </div>
  );
}

function CalenderPicker({ value, setValue }) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DesktopDatePicker value={value} onChange={(newValue) => setValue(newValue)} />
    </LocalizationProvider>
  );
}
