import { useState } from "react";

function SingleInputField({
  leadingComponent,
  tailingComponent,
  id,
  type,
  value,
  label,
  onChange,
  required,
  readOnly,
  options,
  showLabel = false
}) {
  const [isFocus, setIsFocus] = useState(false);

  return (
    <div
      className={`${"w-full"} h-[3.5rem] rounded-xl flex items-center justify-center ${
        isFocus ? "bg-[#E5F1FF]" : "bg-[#979797]/[0.1]"
      }`}>
      {leadingComponent && (
        <div
          className={`w-1/6 flex items-center justify-center ${
            isFocus ? "opacity-100" : "opacity-50"
          }`}>
          {leadingComponent}
        </div>
      )}
      {showLabel && (
        <label
          htmlFor={id}
          className={`hidden md:block mr-2 ml-7 ${isFocus ? "opacity-100" : "opacity-50"}`}>
          {label}:
        </label>
      )}
      {type === "select" ? (
        <select
          className={`grow h-[3.59744rem] font-[Manrope] font-bold text-base leading-[0.875rem] tracking-[-0.03rem] text-[#2B3674] ${
            isFocus ? "opacity-100" : "opacity-50"
          } placeholder:text-[#2B3674] rounded-xl focus:outline-none bg-transparent`}
          onChange={(event) => onChange(event.target.value)}>
          {options.map((option, index) => (
            <option key={index} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      ) : (
        <input
          className={`grow h-[3.59744rem] font-[Manrope] font-bold text-base leading-[0.875rem] tracking-[-0.03rem] text-[#2B3674] ${
            isFocus ? "opacity-100" : "opacity-50"
          } ${
            leadingComponent ? "" : showLabel ? "" : "ml-7"
          } placeholder:text-[#2B3674] rounded-xl focus:outline-none bg-transparent`}
          type={type}
          defaultValue={value}
          id={id}
          name={id}
          placeholder={!showLabel ? label : ""}
          onChange={(event) => onChange(event.target.value)}
          onFocus={() => setIsFocus(true)}
          onBlur={() => setIsFocus(false)}
          required={required}
          readOnly={readOnly}
        />
      )}
      {tailingComponent && (
        <div
          className={`w-1/6 flex items-center justify-center cursor-pointer ${
            isFocus ? "opacity-100 text-[#41579B]" : "opacity-50"
          }`}>
          {tailingComponent}
        </div>
      )}
    </div>
  );
}

export default SingleInputField;
