import { signal } from "@preact/signals-react";
import Util from "../utils/Util";
import CookieService from "../services/CookieService";

export const currentRequests = signal([]);

export const requestIdCount = signal(0);

export const globalError = signal(undefined);

export const signedInState = signal({
  isSignedIn: Util.isTokenValid(CookieService.getCookie("token"))
});
