import "./conversation.css";
import { useNavigate } from "react-router";
import { useEffect, useState } from "react";
import { submissionAnswers } from "../../../routes/Lesson";
import ResultService from "../../../services/ResultService";
import SubmissionService from "../../../services/SubmissionService";

function Conversation({ signal, currentExercise, setCurrentExercise, isResult, submittedChoice }) {
  const navigate = useNavigate();
  const [selectedChoice, setSelectedChoice] = useState("");
  const [isCorrect, setIsCorrect] = useState(false);
  const [isSubmited, setIsSubmited] = useState(isResult);
  const question = signal.value.sections[0].questions[currentExercise];
  const numberOfQuestions = signal.value.sections[0].questions.length;

  useEffect(() => {
    if (isResult) {
      setSelectedChoice(submittedChoice);
    } else {
      let radidoBtns = document.querySelectorAll(`input[name='${question.id}']`);
      let findSelected = () => {
        let selected = document.querySelector(`input[name='${question.id}']:checked`).value;
        setSelectedChoice(selected);
      };

      radidoBtns.forEach((radioBtn) => {
        radioBtn.addEventListener("change", findSelected);
      });

      return () => {
        radidoBtns.forEach((radioBtn) => {
          radioBtn.removeEventListener("change", findSelected);
        });
      };
    }
  });

  useEffect(() => {
    if (selectedChoice) {
      const copy = [...submissionAnswers.value];
      const submissionIndex = submissionAnswers.value.findIndex(
        (submission) => submission.questionId === question.id
      );
      if (submissionIndex === -1) {
        copy.push({
          questionId: question.id,
          answer: selectedChoice
        });
      } else {
        copy[submissionIndex] = {
          questionId: question.id,
          answer: selectedChoice
        };
      }
      submissionAnswers.value = copy;

      if (isResult) {
        setIsCorrect(question.answer === selectedChoice);
      } else {
        setIsCorrect(question.answer === selectedChoice && selectedChoice);
      }
    }
  }, [selectedChoice]);

  useEffect(() => {
    if (isSubmited) {
      if (isCorrect) {
        const audio = new Audio("/assets/sound-effects/correct.mp3");
        audio.play();
        audio.remove();
      } else {
        const audio = new Audio("/assets/sound-effects/wrong.mp3");
        audio.play();
        audio.remove();
      }
    }
  }, [isSubmited]);

  async function onClickContinue() {
    signal.value = {
      ...signal.value,
      sections: signal.value.sections.map((section) => ({
        ...section,
        questions: section.questions.map((question, i) => {
          if (i === currentExercise) {
            return {
              ...question,
              isFinished: true,
              userChoice: selectedChoice
            };
          }
          return question;
        })
      }))
    };

    if (currentExercise === numberOfQuestions - 1) {
      try {
        const res = await ResultService.startExam({ id: signal.value.id });
        const resultId = res.data.id;

        await SubmissionService.submitAnswers({
          resultId,
          answers: submissionAnswers.value
        });

        submissionAnswers.value = [];

        navigate(`/result?id=${resultId}`);
      } catch (error) {
        console.error(error);
      }
    } else {
      setSelectedChoice("");
      setIsCorrect(false);
      setIsSubmited(false);
      setCurrentExercise(currentExercise + 1);
    }
  }

  function onClickSubmit() {
    setIsSubmited(true);
    let correctSpan = `<span class='text-[#28B446] font-bold'>${question.answer}</span>`;
    let incorrectSpan = `<span class='text-[#FF0000] font-bold decoration-[#FF0000] line-through '>${selectedChoice}</span>`;
    let displayAnswer = isCorrect ? correctSpan : incorrectSpan + " " + correctSpan;
    const messages = question.content.conversation;
    for (const i in messages) {
      if (messages[i].includes("___")) {
        messages[i] = messages[i].replace("___", displayAnswer);
      }
    }
    question.conversation = messages;
  }

  return (
    <div className="w-full py-14 flex flex-col items-center">
      <h3 className="text-[#1B2559] text-lg font-semibold leading-normal mb-[2.3rem]">
        Complete the sentence
      </h3>
      <audio
        src={question.media.mediaUrl}
        preload="none"
        controls
        controlsList="nodownload"
        className="w-[90%] md:w-[60%] h-[3.625rem]"></audio>
      <div className="w-full md:w-[60%] flex flex-col gap-5 my-8 p-4 rounded-2xl">
        {question.content.conversation.map((message, index) => (
          <div key={index} className={`flex flex-row gap-5 ${index % 2 === 0 ? "" : "ml-auto"}`}>
            {index % 2 === 0 ? (
              <>
                <img
                  src="/assets/avatar3.png"
                  alt="avatar"
                  className="w-[2.9375rem] h-[2.9375rem] rounded-full"
                />
              </>
            ) : (
              <></>
            )}
            <div
              className={`w-[17.875rem] flex items-center justify-center p-6 ${
                index % 2 === 0 ? "bg-[#2675EC]" : "bg-[#F6F6F6]"
              }`}
              style={{
                borderRadius:
                  index % 2 === 0
                    ? "2.1875rem 2.1875rem 2.1875rem 0.625rem"
                    : "2.1875rem 0.625rem 2.1875rem 2.1875rem"
              }}>
              <p
                dangerouslySetInnerHTML={{ __html: message }}
                className={`text-[0.9375rem] font-medium leading-7 tracking-[-0.01875rem] ${
                  index % 2 === 0 ? "text-white" : "text-[#1B2559]"
                }`}></p>
            </div>
            {index % 2 !== 0 ? (
              <div>
                <img
                  src="/assets/avatar4.png"
                  alt="avatar"
                  className="w-[2.9375rem] h-[2.9375rem] rounded-full"
                />
              </div>
            ) : (
              <></>
            )}
          </div>
        ))}
      </div>
      {isSubmited ? (
        <>
          <div className="flex flex-col w-full items-center">
            <div className={`flex flex-row mt-8 gap-2 ${!isResult && "hidden"}`}>
              {question.content.choices.map((choice, index) => (
                <div key={index}>
                  <div
                    className={`${
                      question.answer === choice
                        ? "min-w-[6.4375rem] max-w-[9rem] px-1 text-center h-[5.3125rem] rounded-[0.625rem] border border-solid border-[#28B446] bg-[#62FF84] flex items-center justify-center text-[#04821F] text-base font-normal leading-[133.333%]"
                        : (isResult ? question.userChoice : selectedChoice) === choice
                          ? "min-w-[6.4375rem] max-w-[9rem] px-1 text-center h-[5.3125rem] rounded-[0.625rem] border border-solid border-[#E94444] bg-[#FFA999] flex items-center justify-center text-[#FF0000] text-base font-normal leading-[133.333%]"
                          : "hidden"
                    }`}>
                    <p>{choice}</p>
                  </div>
                </div>
              ))}
            </div>
            <div
              className={`w-[39rem] h-[0.0625rem] bg-[#A3AED0] mt-4 ${
                isResult ? "w-[98%] block" : "hidden"
              }`}
            />
            <p
              className={`text-[#2B3674] text-lg font-bold leading-[118.519%] my-8 ${
                isResult && "hidden"
              }`}>
              {isCorrect ? "Correct answer! 👏" : "Wrong answer"}
            </p>
            <button
              onClick={onClickContinue}
              className={`w-[17rem] h-[2.875rem] rounded-2xl bg-[#3D10EA] text-white text-sm font-semibold leading-normal tracking-[-0.0175rem] ${
                isResult && "hidden"
              }`}>
              Continue
            </button>
          </div>
        </>
      ) : (
        <>
          <div className="flex flex-col w-full items-center">
            <div className="conversation-container">
              <div className="conversation-radio-tile-group">
                {question.content.choices.map((choice, index) => (
                  <div key={index}>
                    <div className="conversation-input-container">
                      <input type="radio" name={question.id} value={choice} />
                      <div className="conversation-radio-tile">
                        <img src="/assets/check-icon.svg" alt="check" />
                        <label htmlFor="answer">{choice}</label>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <button
              onClick={onClickSubmit}
              className={`w-[17rem] h-[2.875rem] mt-7 rounded-2xl bg-[#3D10EA] text-white text-sm font-semibold leading-normal tracking-[-0.0175rem] ${
                !selectedChoice && "bg-gray-300 cursor-default"
              }`}
              disabled={!selectedChoice}>
              Submit
            </button>
          </div>
        </>
      )}
    </div>
  );
}

export default Conversation;
