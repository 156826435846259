import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import UserService from "../../services/UserService";
import CookieService from "../../services/CookieService";
import SingleInputField from "../common/SingleInputField";
import { globalError } from "../../signals";
import { Typography } from "@mui/material";
import { SERVER_URL } from "../../services/AxiosClient";

function Login() {
  const navigate = useNavigate();
  useEffect(() => {
    globalError.value = undefined;
  }, []);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState({
    value: "",
    visible: false
  });

  const loginFields = [
    {
      id: "email",
      leadingComponent: <img src="/assets/email-icon.svg" alt="email icon" />,
      type: "text",
      value: email,
      label: "Địa chỉ email",
      onChange: (value) => setEmail(value)
    },
    {
      id: "password",
      tailingComponent: (
        <img
          src={`${
            password.visible
              ? "../../../assets/visibility-off-icon.svg"
              : "../../../assets/visibility-icon.svg"
          }`}
          alt="password visibility icon"
        />
      ),
      type: password.visible ? "text" : "password",
      value: password.value,
      label: "Mật khẩu",
      onChange: (value) => setPassword({ ...password, value: value })
    }
  ];

  useEffect(() => {
    document.title = "German Learning | Đăng nhập";
  }, []);

  const login = async () => {
    try {
      const res = await UserService.login({
        email: email,
        password: password.value
      });
      CookieService.setCookie("token", res.data.token, 1);
      navigate("/");
    } catch (error) {
      globalError.value = error.response.data;
    }
  };

  return (
    <div className="flex flex-col w-full relative items-center justify-center">
      <div className="w-[21.875rem] absolute -top-14 md:top-auto z-20 md:relative font-[Inter] text-[#2B3674] text-3xl text-center font-semibold leading-normal uppercase">
        <h1>CHÀO MỪNG TỚI ÔN THI TIẾNG ĐỨC</h1>
      </div>
      <div className="w-full px-8 md:px-4 md:w-[25.875rem] h-[30rem] mt-[1.6rem] bg-white rounded-3xl flex flex-col items-center justify-start">
        <p className="my-3 text-[#2B3674] text-base font-[Inter] font-medium tracking-[-0.03rem]">
          Đăng nhập với email
        </p>
        <div className="w-full md:w-[25.875rem] h-[0.0625rem] bg-[#FFF3E9]"></div>
        <div className="w-full flex flex-col items-center gap-2 mt-4">
          {loginFields.map((field, index) => (
            <SingleInputField
              id={field.id}
              key={index}
              focusState={field.focusState}
              leadingImg={field.leadingImg}
              tailingImg={field.tailingImg}
              tailingAction={field.tailingAction}
              type={field.type}
              value={field.value}
              label={field.label}
              onChange={field.onChange}
            />
          ))}
          {globalError.value && (
            <Typography
              color="error"
              sx={{
                alignSelf: "start",
                fontSize: "small",
                fontStyle: "italic",
                marginTop: "-10px"
              }}>
              {globalError.value?.status === "UNAUTHORIZED" ||
              globalError.value?.status === "NOT_FOUND"
                ? "Tên đăng nhập hoặc mật khẩu không chính xác, xin vui lòng nhập lại!"
                : "Lỗi không xác định, xin vui lòng thử lại sau ít phút!"}
            </Typography>
          )}
        </div>
        <button
          className="w-full md:w-[23.375rem] h-[3.75rem] mt-4 bg-[#3D10EA] rounded-lg text-white font-[Inter] font-bold text-base leading-4"
          onClick={login}>
          Đăng nhập
        </button>
        <Link
          to="/reset-password"
          className="mt-[0.8rem] text-[#2B3674] text-sm font-[Inter] leading-[1.375rem] tracking-[-0.02625rem] underline">
          Quên mật khẩu?
        </Link>
        <div className="flex flex-row items-center gap-2 mt-2">
          <div className="w-[8.375rem] h-[1px] bg-[#41579B]/20"></div>
          <span className="text-[#41579B]/50 text-sm font-[Inter] font-medium leading-[1.375rem] tracking-[-0.02625rem] w-max">
            Hoặc đăng nhập với
          </span>
          <div className="w-[8.375rem] h-[1px] bg-[#41579B]/20"></div>
        </div>
        <div className="flex flex-row gap-3 mt-5">
          <Link
            to={`${SERVER_URL}/oauth2/authorization/google`}
            className="w-[11.3125rem] h-[3.125rem] rounded-xl bg-white flex flex-row items-center">
            <div className="h-8 ml-8 mr-3 items-center justify-center">
              <img src="../../../assets/google-icon.svg" alt="google icon" />
            </div>
            <p className="text-[#2B3674] text-base font-[Inter] font-semibold leading-4 tracking-[-0.03rem]">
              Google
            </p>
          </Link>
          <Link
            to={`${SERVER_URL}/oauth2/authorization/facebook`}
            className="w-[11.3125rem] h-[3.125rem] rounded-xl bg-white flex flex-row items-center">
            <div className="h-10 ml-6 mr-1 items-center justify-center">
              <img src="../../../assets/facebook-icon.svg" alt="facebook icon" />
            </div>
            <p className="text-[#2B3674] text-base font-[Inter] font-semibold leading-4 tracking-[-0.03rem]">
              Facebook
            </p>
          </Link>
        </div>
        <p className="mt-2 md:mt-[1.6rem] text-[#2B3674] text-sm font-[Inter] font-medium leading-[1.375rem] tracking-[-0.02625rem]">
          Bạn chưa có tài khoản?{" "}
          <Link to="/register" className="font-bold">
            Đăng ký
          </Link>
        </p>
      </div>
    </div>
  );
}

export default Login;
