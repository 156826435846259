import { exam } from "../../signals/ExamSignal";
import { EditorSection } from "./section/EditorSection";

export function EditorBody() {
  const sections = exam.value.sections;

  return (
    <div
      className="w-full pt-10 pb-32 flex items-center flex-col overflow-y-auto flex-auto bg-cover bg-no-repeat bg-center"
      style={{
        backgroundImage: "url(/assets/editor-bg.jpg)"
      }}>
      <div className="overflow-y-auto w-full h-full flex flex-col items-center justify-start gap-2">
        {sections.map((_, index) => (
          <div
            id="editor-body"
            key={index}
            className="bg-white w-[53rem] h-max flex flex-col pt-[1.63rem] pb-[4.63rem] px-[1.69rem]">
            <EditorSection index={index} />
          </div>
        ))}
      </div>
    </div>
  );
}
