import { useEffect, useState } from "react";
import Util from "../utils/Util";
import CookieService from "../services/CookieService";
import { Alert, Avatar, CircularProgress, Slide, Snackbar, Stack } from "@mui/material";
import { userInfoSignal } from "../signals/UserInfoSignal";
import UserService from "../services/UserService";
import { useMutation } from "@tanstack/react-query";

const exampleStarPoint = 200000;

function Transition(props) {
  return <Slide {...props} direction="down" />;
}

export function TotalPoints({ isStarPoint }) {
  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState("success");
  const [detailStatus, setDetailStatus] = useState("Chỉnh sửa hồ sơ thành công!");

  const unitMap = {
    star: (
      <>
        <img src="/assets/star-icon.svg" alt="star" className="w-6 md:w-12 h-6 md:h-12" />
      </>
    ),
    money: <></>
  };

  const openAlert = () => {
    setOpen(true);
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") return;
    setOpen(false);
  };

  const requestWithdrawMutation = useMutation({
    mutationFn: UserService.requestWithdraw,
    onError: (error) => {
      console.error(error);
      if (error.response?.status === 400) {
        setStatus("error");
        setDetailStatus("Số dư không đủ để thực hiện giao dịch!");
        openAlert();
        return;
      }
      setStatus("error");
      setDetailStatus("Gửi yêu cầu thất bại!");
      openAlert();
    },
    onSuccess: () => {
      setStatus("success");
      setDetailStatus("Gửi yêu cầu thành công!");
      openAlert();
    }
  });

  return (
    <div className="relative w-full flex flex-col items-center justify-center">
      <TransactionAvatar />
      <div
        className={
          "absolute w-[43.9%] min-w-[19.4695rem] max-w-[29.90844rem] h-[11.32875rem] md:h-[17.40288rem] rounded-3xl z-0"
        }
        style={{
          clipPath: "polygon(0 0, 50% 2%, 100% 0, 100% 100%, 50% 95%, 0 100%)",
          background: isStarPoint
            ? "linear-gradient(90deg, #3fd3b6 0%, #00b28f 120%)"
            : "linear-gradient(90deg, #3d67f3 0%, #315adf 120%)"
        }}>
        <div className="relative">
          <img
            src="/assets/bg2.svg"
            alt="background"
            className="w-[10.64556rem] h-[10.64556rem] md:w-[16.35344rem] md:h-[16.35344rem] absolute -right-[7.5rem] md:-right-[10rem]"
          />
          <img
            src="/assets/bg2.svg"
            alt="background"
            className="w-[10.64556rem] h-[10.64556rem] md:w-[16.35344rem] md:h-[16.35344rem] absolute -left-[7.5rem] md:-left-[10rem]"
          />
        </div>
        <div className="w-full h-full flex flex-col items-center mt-12 md:mt-[5rem]">
          <span
            className="text-[0.875rem] md:text-lg font-medium leading-[1.76563rem] tracking-[0.02756rem]"
            style={{
              color: "var(--Off-white, #FFF)",
              fontFeatureSettings: "'clig' off, 'liga' off",
              opacity: "0.7"
            }}>
            Total Coins
          </span>
          <div
            className={`flex flex-row items-center ${isStarPoint && "gap-2"} mt-2 ${
              isStarPoint ? "md:mt-3" : "md:mt-6"
            }`}>
            <span
              className="text-white text-2xl md:text-[3.125rem] font-normal leading-[1.76563rem] tracking-[0.02756rem]"
              style={{ fontFeatureSettings: "'clig' off, 'liga' off" }}>
              {isStarPoint
                ? exampleStarPoint.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                : userInfoSignal.value?.sumTransaction
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
            </span>
            <div className="text-white text-2xl md:text-[3.125rem] font-normal leading-[1.76563rem] tracking-[0.02756rem]">
              {unitMap[isStarPoint ? "star" : "money"]}
            </div>
          </div>
          <button
            onClick={() => requestWithdrawMutation.mutate()}
            className="mt-8 text-white text-xl rounded-xl bg-yellow-600 px-4 py-2">
            {requestWithdrawMutation.isPending ? <CircularProgress size={20} /> : "Đổi điểm"}
          </button>
          {/* <GrowthRate isStarPoint={isStarPoint} /> */}
        </div>
      </div>
      <Stack>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          TransitionComponent={Transition}
          open={open}
          autoHideDuration={6000}
          onClose={handleClose}>
          <Alert onClose={handleClose} severity={status} sx={{ width: "100%" }}>
            {detailStatus}
          </Alert>
        </Snackbar>
      </Stack>
    </div>
  );
}

function TransactionAvatar() {
  const [avatarText, setAvatarText] = useState("");

  useEffect(() => {
    const userInfo = Util.getClaims(CookieService.getCookie("token"));
    if (!userInfo) return;
    setAvatarText(`${userInfo.firstName} ${userInfo.lastName}`);
  }, []);

  return (
    <div className="absolute w-[6.71756rem] md:w-[10.31931rem] h-[6.71756rem] md:h-[10.31931rem] flex items-center justify-center z-[1] -top-[9rem] md:-top-[13.5rem]">
      <img
        src="/assets/bg1.svg"
        alt="background"
        className="absolute w-[6.71756rem] md:w-[10.31931rem] h-[6.71756rem] md:h-[10.31931rem]"
      />
      <div className="absolute w-[3.47263rem] md:w-[5.33456rem] h-[3.47263rem] md:h-[5.33456rem] rounded-2xl bg-white" />
      <Avatar
        className="!absolute !w-[3rem] md:!w-[4.5rem] !h-[3rem] md:!h-[4.5rem] !rounded-2xl !text-3xl"
        {...Util.stringAvatar(avatarText)}
      />
    </div>
  );
}
