import { useEffect, useRef, useState } from "react";
import { exam, removeSection } from "../../../signals/ExamSignal";
import { EditorAddNewButton } from "../EditorAddNewButton";

export function EditorSectionHeader({ index }) {
  const [isExpanded, setIsExpanded] = useState(false);
  const [titleValue, setTitleValue] = useState(exam.value.sections[index]?.title);
  const [descriptionValue, setDescriptionValue] = useState(exam.value.sections[index]?.description);
  const ref = useRef(null);

  useEffect(() => {
    setTitleValue(exam.value.sections[index]?.title);
    setDescriptionValue(exam.value.sections[index]?.description);
  }, [exam.value]);

  // Set event listener for click outside
  useEffect(() => {
    const handleClickOutside = (e) => {
      if (ref.current && !ref.current.contains(e.target)) {
        setIsExpanded(false);
      }
    };
    document.addEventListener("mouseup", handleClickOutside);
    return () => {
      document.removeEventListener("mouseup", handleClickOutside);
    };
  }, []);

  return (
    <div
      ref={ref}
      className={`${
        isExpanded ? "h-52" : "h-24"
      } mb-7 flex flex-col transition-all duration-200 ease-linear pl-8`}
      tabIndex={0}
      onClick={() => setIsExpanded(true)}
      aria-label={`section header ${index}`}>
      {isExpanded ? (
        <div className="flex flex-col gap-2">
          <i
            className={`w-6 h-6 self-end ${
              exam.value.sections.length === 1
                ? "opacity-50 cursor-default"
                : "opacity-100 cursor-pointer"
            }`}
            style={{
              filter:
                "brightness(0) saturate(100%) invert(37%) sepia(4%) saturate(4%) hue-rotate(33deg) brightness(98%) contrast(89%)"
            }}
            onClick={() => {
              if (exam.value.sections.length === 1) return;
              removeSection({ sectionIndex: index });
            }}>
            <img src="/assets/recycle-bin.svg" alt="recycle bin" />
          </i>
          <div className="bg-gray-100 p-4 flex flex-col gap-4 mb-6">
            <input
              autoFocus
              className="px-2 py-1 outline-none border-b-2 border-transparent focus:border-[#2639ED] hover:border-[#2639ED]"
              value={titleValue || ""}
              onFocus={() => {
                setIsExpanded(true);
              }}
              onChange={(e) => {
                setTitleValue(e.target.value);
              }}
              aria-label={`section header ${index}`}
            />
            <input
              className="px-2 py-1 outline-none border-b-2 border-transparent focus:border-[#2639ED] hover:border-[#2639ED]"
              value={descriptionValue || ""}
              onFocus={() => {
                setIsExpanded(true);
              }}
              onChange={(e) => {
                setDescriptionValue(e.target.value);
              }}
              aria-label={`section header ${index}`}
            />
          </div>
          <EditorAddNewButton sectionIndex={index} ariaLabel={`section header ${index}`} />
        </div>
      ) : (
        <div>
          <h3 className="text-[#1D0C0C] text-[1.375rem] font-bold leading-8">{titleValue}</h3>
          <p className="text-[#1D0C0C] text-xs font-normal">{descriptionValue}</p>
        </div>
      )}
    </div>
  );
}
