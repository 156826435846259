import { Outlet } from "react-router";
import Footer from "../components/Footer";
import NavBar from "../components/NavBar";
import { globalError } from "../signals";
import { Typography } from "@mui/joy";
import Dialog, { DialogButtons } from "../components/dialog";

function Root() {
  return (
    <div className="w-full h-screen bg-[#F4F7FE]">
      <div className="w-full h-full flex flex-col">
        <NavBar />
        <main className="mb-auto pb-8">
          <Outlet />
        </main>
        <Footer />
      </div>
      <Dialog
        open={!!globalError.value}
        onClose={() => (globalError.value = undefined)}
        onConfirm={() => (globalError.value = undefined)}
        buttons={DialogButtons.HOME_CONFIRM}
        headerBgColor="#f92222"
        headerTitleColor="white"
        title={"Lỗi hệ thống"}
        textContent={globalError.value?.message || ""}>
        <Typography color="#141414" sx={{ padding: "0.5rem" }}>
          {globalError.value?.detailsMsg || ""}
        </Typography>
      </Dialog>
    </div>
  );
}

export default Root;
