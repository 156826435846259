import Stack from "@mui/joy/Stack";
import { useCountUp } from "use-count-up";
import Typography from "@mui/joy/Typography";
import CircularProgress from "@mui/joy/CircularProgress";

function CircularProgressBar({ currentValue = 0, total = 0 }) {
  const { value } = useCountUp({
    isCounting: true,
    duration: 1,
    start: 0,
    end: currentValue
  });
  return (
    <div className="font-[Inter]">
      <Stack>
        <CircularProgress
          determinate
          value={(value / total) * 100}
          sx={{
            "--CircularProgress-size": "12.5rem",
            "--CircularProgress-trackThickness": "1.5rem",
            "--CircularProgress-progressThickness": "1.5rem",
            "--CircularProgress-progressColor": "#3b5cff",
            "--CircularProgress-trackColor": "#49c9c8"
          }}>
          <Typography component="div">
            <div className="flex flex-col pb-2">
              <span className="text-center text-[#A3AED0] text-[0.9375rem] font-medium leading-7 tracking-[-0.01875rem]">
                Score
              </span>
              <span className="text-center text-[#2B3674] text-[1.5625rem] font-bold leading-normal tracking-[-0.03125rem]">
                {`${value}/${total}`}
              </span>
            </div>
          </Typography>
        </CircularProgress>
      </Stack>
    </div>
  );
}

export default CircularProgressBar;
