export const BANKS = [
  {
    shortName: "ABBANK",
    name: "Ngân hàng TMCP An Bình"
  },
  {
    shortName: "ACB",
    name: "Ngân hàng TMCP Á Châu"
  },
  {
    shortName: "Agribank",
    name: "Ngân hàng Nông nghiệp và Phát triển Nông thôn Việt Nam"
  },
  {
    shortName: "BacABank",
    name: "Ngân hàng TMCP Bắc Á"
  },
  {
    shortName: "BaoVietBank",
    name: "Ngân hàng TMCP Bảo Việt"
  },
  {
    shortName: "BIDV",
    name: "Ngân hàng TMCP Đầu tư và Phát triển Việt Nam"
  },
  {
    shortName: "CAKE",
    name: "TMCP Việt Nam Thịnh Vượng - Ngân hàng số CAKE by VPBank"
  },
  {
    shortName: "CBBank",
    name: "Ngân hàng Thương mại TNHH MTV Xây dựng Việt Nam"
  },
  {
    shortName: "CIMB",
    name: "Ngân hàng TNHH MTV CIMB Việt Nam"
  },
  {
    shortName: "Citibank",
    name: "Ngân hàng Citibank, N.A. - Chi nhánh Hà Nội"
  },
  {
    shortName: "COOPBANK",
    name: "Ngân hàng Hợp tác xã Việt Nam"
  },
  {
    shortName: "DBSBank",
    name: "DBS Bank Ltd - Chi nhánh Thành phố Hồ Chí Minh"
  },
  {
    shortName: "DongABank",
    name: "Ngân hàng TMCP Đông Á"
  },
  {
    shortName: "Eximbank",
    name: "Ngân hàng TMCP Xuất Nhập khẩu Việt Nam"
  },
  {
    shortName: "GPBank",
    name: "Ngân hàng Thương mại TNHH MTV Dầu Khí Toàn Cầu"
  },
  {
    shortName: "HDBank",
    name: "Ngân hàng TMCP Phát triển Thành phố Hồ Chí Minh"
  },
  {
    shortName: "HongLeong",
    name: "Ngân hàng TNHH MTV Hong Leong Việt Nam"
  },
  {
    shortName: "HSBC",
    name: "Ngân hàng TNHH MTV HSBC (Việt Nam)"
  },
  {
    shortName: "IBKHCM",
    name: "Ngân hàng Công nghiệp Hàn Quốc - Chi nhánh TP. Hồ Chí Minh"
  },
  {
    shortName: "IBKHN",
    name: "Ngân hàng Công nghiệp Hàn Quốc - Chi nhánh Hà Nội"
  },
  {
    shortName: "IndovinaBank",
    name: "Ngân hàng TNHH Indovina"
  },
  {
    shortName: "KBank",
    name: "Ngân hàng Đại chúng TNHH Kasikornbank"
  },
  {
    shortName: "KEBHanaHCM",
    name: "Ngân hàng KEB Hana – Chi nhánh Thành phố Hồ Chí Minh"
  },
  {
    shortName: "KEBHANAHN",
    name: "Ngân hàng KEB Hana – Chi nhánh Hà Nội"
  },
  {
    shortName: "KienLongBank",
    name: "Ngân hàng TMCP Kiên Long"
  },
  {
    shortName: "KookminHCM",
    name: "Ngân hàng Kookmin - Chi nhánh Thành phố Hồ Chí Minh"
  },
  {
    shortName: "KookminHN",
    name: "Ngân hàng Kookmin - Chi nhánh Hà Nội"
  },
  {
    shortName: "LienVietPostBank",
    name: "Ngân hàng TMCP Bưu Điện Liên Việt"
  },
  {
    shortName: "MAFC",
    name: "Công ty Tài chính TNHH MTV Mirae Asset (Việt Nam) "
  },
  {
    shortName: "MBBank",
    name: "Ngân hàng TMCP Quân đội"
  },
  {
    shortName: "MSB",
    name: "Ngân hàng TMCP Hàng Hải"
  },
  {
    shortName: "NamABank",
    name: "Ngân hàng TMCP Nam Á"
  },
  {
    shortName: "NCB",
    name: "Ngân hàng TMCP Quốc Dân"
  },
  {
    shortName: "Nonghyup",
    name: "Ngân hàng Nonghyup - Chi nhánh Hà Nội"
  },
  {
    shortName: "OCB",
    name: "Ngân hàng TMCP Phương Đông"
  },
  {
    shortName: "Oceanbank",
    name: "Ngân hàng Thương mại TNHH MTV Đại Dương"
  },
  {
    shortName: "PGBank",
    name: "Ngân hàng TMCP Xăng dầu Petrolimex"
  },
  {
    shortName: "PublicBank",
    name: "Ngân hàng TNHH MTV Public Việt Nam"
  },
  {
    shortName: "PVcomBank",
    name: "Ngân hàng TMCP Đại Chúng Việt Nam"
  },
  {
    shortName: "Sacombank",
    name: "Ngân hàng TMCP Sài Gòn Thương Tín"
  },
  {
    shortName: "SaigonBank",
    name: "Ngân hàng TMCP Sài Gòn Công Thương"
  },
  {
    shortName: "SCB",
    name: "Ngân hàng TMCP Sài Gòn"
  },
  {
    shortName: "SeABank",
    name: "Ngân hàng TMCP Đông Nam Á"
  },
  {
    shortName: "SHB",
    name: "Ngân hàng TMCP Sài Gòn - Hà Nội"
  },
  {
    shortName: "ShinhanBank",
    name: "Ngân hàng TNHH MTV Shinhan Việt Nam"
  },
  {
    shortName: "StandardChartered",
    name: "Ngân hàng TNHH MTV Standard Chartered Bank Việt Nam"
  },
  {
    shortName: "Techcombank",
    name: "Ngân hàng TMCP Kỹ thương Việt Nam"
  },
  {
    shortName: "Timo",
    name: "Ngân hàng số Timo by Ban Viet Bank (Timo by Ban Viet Bank)"
  },
  {
    shortName: "TPBank",
    name: "Ngân hàng TMCP Tiên Phong"
  },
  {
    shortName: "Ubank",
    name: "TMCP Việt Nam Thịnh Vượng - Ngân hàng số Ubank by VPBank"
  },
  {
    shortName: "UnitedOverseas",
    name: "Ngân hàng United Overseas - Chi nhánh TP. Hồ Chí Minh"
  },
  {
    shortName: "VBSP",
    name: "Ngân hàng Chính sách Xã hội"
  },
  {
    shortName: "VIB",
    name: "Ngân hàng TMCP Quốc tế Việt Nam"
  },
  {
    shortName: "VietABank",
    name: "Ngân hàng TMCP Việt Á"
  },
  {
    shortName: "VietBank",
    name: "Ngân hàng TMCP Việt Nam Thương Tín"
  },
  {
    shortName: "VietCapitalBank",
    name: "Ngân hàng TMCP Bản Việt"
  },
  {
    shortName: "Vietcombank",
    name: "Ngân hàng TMCP Ngoại Thương Việt Nam"
  },
  {
    shortName: "VietinBank",
    name: "Ngân hàng TMCP Công thương Việt Nam"
  },
  {
    shortName: "ViettelMoney",
    name: "Tổng Công ty Dịch vụ số Viettel - Chi nhánh tập đoàn công nghiệp viễn thông Quân Đội"
  },
  {
    shortName: "VNPTMoney",
    name: "VNPT Money"
  },
  {
    shortName: "VPBank",
    name: "Ngân hàng TMCP Việt Nam Thịnh Vượng"
  },
  {
    shortName: "VRB",
    name: "Ngân hàng Liên doanh Việt - Nga"
  },
  {
    shortName: "Woori",
    name: "Ngân hàng TNHH MTV Woori Việt Nam"
  }
];
