import { Pagination } from "@mui/material";

function CustomPagination({ currentPage, setCurrentPage, count }) {
  return (
    <>
      <Pagination
        count={count}
        siblingCount={0}
        shape="rounded"
        page={currentPage}
        onChange={(event, newPage) => setCurrentPage(newPage)}
        sx={{
          "Button.MuiPaginationItem-rounded.Mui-selected": {
            bgcolor: "#6764FB",
            color: "#FFFFFF",
            fontFamily: "Inter"
          },
          "Button.MuiButtonBase-root": {
            fontFamily: "Inter",
            fontSize: "0.875rem",
            fontWeight: "400",
            lineHeight: "1.5rem",
            bgcolor: "#FFFFFF",
            color: "#6764FB",
            marginLeft: "1rem",
            borderRadius: "0.3125rem"
          },
          "div.MuiPaginationItem-root": {
            fontFamily: "Inter",
            fontSize: "0.875rem",
            fontWeight: "400",
            lineHeight: "1.5rem",
            bgcolor: "#FFFFFF",
            color: "#6764FB",
            height: "2rem",
            borderRadius: "0.3125rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginLeft: "1rem"
          },
          "Button.MuiPaginationItem-previousNext": {
            backgroundColor: "#F89E44",
            color: "#FFFFFF"
          },
          "svg.MuiSvgIcon-root": {
            fill: "#FFFFFF"
          }
        }}
      />
    </>
  );
}

export default CustomPagination;
