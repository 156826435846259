import { arrayMove } from "@dnd-kit/sortable";
import { signal } from "@preact/signals-react";

const defaultExam = {
  title: "New exam",
  description: "",
  duration: 0,
  order: 1,
  sections: [
    {
      title: "New section",
      description: "",
      questions: [
        {
          type: "SINGLE_CHOICE",
          title: "New Question",
          content: ["Option 1", "Option 2"],
          answer: "Option 1"
        }
      ]
    }
  ]
};

const exam = new signal(defaultExam);

const defaultSection = {
  title: "New section",
  description: "",
  questions: [
    {
      type: "SINGLE_CHOICE",
      title: "New Question",
      content: ["Option 1", "Option 2"],
      answer: ""
    }
  ]
};

const defaultQuestion = {
  type: "SINGLE_CHOICE",
  title: "New Question",
  content: ["Option 1", "Option 2"],
  answer: ""
};

function updateExam({ exam }) {
  exam.value = exam;
}

function addSection({ title, description, questions }) {
  exam.value = {
    ...exam.value,
    sections: [
      ...exam.value.sections,
      {
        title,
        description,
        questions: questions || [],
        examId: exam.value.id
      }
    ]
  };
}

function updateSection({ sectionIndex, section }) {
  exam.value = {
    ...exam.value,
    sections: exam.value.sections.map((s, index) => {
      if (index === sectionIndex) {
        return section;
      } else {
        return s;
      }
    })
  };
}

function removeSection({ sectionIndex }) {
  exam.value = {
    ...exam.value,
    sections: exam.value.sections.filter((_, index) => index !== sectionIndex)
  };
}

function addQuestion({ sectionIndex, question }) {
  exam.value = {
    ...exam.value,
    sections: exam.value.sections.map((section, index) => {
      if (index === sectionIndex) {
        return {
          ...section,
          questions: [
            ...section.questions,
            {
              ...question,
              sectionId: section.id
            }
          ]
        };
      } else {
        return section;
      }
    })
  };
}

function updateQuestion({ sectionIndex, questionIndex, question }) {
  exam.value = {
    ...exam.value,
    sections: exam.value.sections.map((section, index) => {
      if (index === sectionIndex) {
        return {
          ...section,
          questions: section.questions.map((q, i) => {
            if (i === questionIndex) {
              return question;
            } else {
              return q;
            }
          })
        };
      } else {
        return section;
      }
    })
  };
}

function moveQuestion({ sectionIndex, oldIndex, newIndex }) {
  exam.value = {
    ...exam.value,
    sections: exam.value.sections.map((section, index) => {
      if (index === sectionIndex) {
        return {
          ...section,
          questions: arrayMove(section.questions, oldIndex, newIndex)
        };
      } else {
        return section;
      }
    })
  };
}

function removeQuestion({ sectionIndex, questionIndex }) {
  exam.value = {
    ...exam.value,
    sections: exam.value.sections.map((section, index) => {
      if (index === sectionIndex) {
        return {
          ...section,
          questions: section.questions.filter((_, i) => i !== questionIndex)
        };
      } else {
        return section;
      }
    })
  };
}

export {
  exam,
  defaultExam,
  defaultSection,
  defaultQuestion,
  addSection,
  updateSection,
  addQuestion,
  updateQuestion,
  moveQuestion,
  removeQuestion,
  updateExam,
  removeSection
};
