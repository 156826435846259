import axiosClient from "./AxiosClient";

class StorageService {
  static uploadFile(file) {
    return axiosClient.postForm("/storage/upload", {
      file: file
    });
  }
}

export default StorageService;
