import EditorNavBar from "../components/editor/EditorNavBar";
import { Outlet } from "react-router";

function EditorRoot() {
  return (
    <main className="w-full flex flex-col items-center min-h-screen">
      <EditorNavBar />
      <div className="w-full grow flex flex-col items-center">
        <Outlet />
      </div>
    </main>
  );
}

export default EditorRoot;
