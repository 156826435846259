import React, { createRef, useEffect, useState } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import Paper from "@mui/material/Paper";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import { useTheme } from "@mui/material/styles";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import {
  Avatar,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  TableHead,
  TextField
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import TableFooter from "@mui/material/TableFooter";
import LastPageIcon from "@mui/icons-material/LastPage";
import TableContainer from "@mui/material/TableContainer";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import TablePagination from "@mui/material/TablePagination";
import { faUserGroup } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import Util from "../utils/Util";
import CookieService from "../services/CookieService";
import UserService from "../services/UserService";
import PermissionService from "../services/PermissionService";
import { signal } from "@preact/signals-react";
import { Link } from "react-router-dom";
import { keepPreviousData, useQuery, useQueryClient } from "@tanstack/react-query";
import _ from "lodash";

const AdminPage = () => {
  const [searchText, setSearchText] = useState("");
  const searchInputRef = createRef();

  const search = (e) => {
    e.preventDefault();
    setSearchText(searchInputRef.current.value);
  };
  return (
    <div className="w-full h-screen flex flex-row bg-[#141a28] font-['DM_Sans']">
      <LeftBar />
      <div className="grow bg-transparent pt-8 px-4 ">
        <form className="w-[15rem] h-[2.5rem] px-2 bg-[#1b2537] flex flex-row items-center rounded-[0.1875rem]">
          <input
            ref={searchInputRef}
            type="text"
            placeholder="Search"
            className="grow px-2 bg-transparent focus:outline-none text-white"
          />
          <button onClick={search} type="submit">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={2}
              stroke="#FFF"
              className="w-5 h-5">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
              />
            </svg>
          </button>
        </form>
        <CustomTable searchText={searchText} />
      </div>
    </div>
  );
};

export default AdminPage;

const LeftBar = () => {
  const firstName = Util.getClaim(CookieService.getCookie("token"), "firstName");
  const lastName = Util.getClaim(CookieService.getCookie("token"), "lastName");

  return (
    <div className="w-[18.75rem] px-8 bg-[#1c2438]">
      <div className="mt-4 flex flex-row items-center justify-between">
        <Link to="/" className="flex items-center justify-center">
          <div className="flex items-center justify-center gap-2">
            <div className="w-[2.5625rem] md:w-[3.1875rem] h-[2.5625rem] md:h-[3.1875rem]">
              <img src="/assets/logo.png" alt="logo" />
            </div>
          </div>
        </Link>
        <h1 className="uppercase text-white text-[2.25rem] font-semibold leading-normal tracking-[0.125rem]">
          Admin
        </h1>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={2.5}
          stroke="#FFF"
          className="w-6 h-6">
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
          />
        </svg>
      </div>
      <div className="mt-8 flex flex-col items-center justify-center">
        <Avatar
          className="!w-[5rem] !h-[5rem] !text-[2.5rem]"
          {...Util.stringAvatar(`${lastName} ${firstName}`)}
        />
        <span className="mt-2 text-white text-base font-bold leading-normal tracking-[0.125rem]">
          {`${lastName} ${firstName}`}
        </span>
      </div>
      <div className="flex flex-col px-4">
        <div className="mt-12 flex flex-row items-center">
          <FontAwesomeIcon icon={faUserGroup} className="text-[#51559c]" />
          <p className="ml-4 text-[#51559c] text-base font-medium">Manage Users</p>
        </div>
      </div>
    </div>
  );
};

const getUsers = async ({ page, size, searchText }) => {
  let searchCriteriaList = [];
  if (searchText) {
    searchCriteriaList = [
      {
        filterKey: "email",
        value: searchText,
        operation: "cn"
      }
    ];
  }
  const res = await UserService.searchUsers({
    page,
    size,
    sortBy: "firstName",
    order: "asc",
    dataOption: "any",
    searchCriteriaList
  });
  return res.data;
};

const CustomTable = ({ searchText }) => {
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#373b8a",
      color: theme.palette.common.white
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      backgroundColor: "#1b2537",
      color: theme.palette.common.white
    },
    [`&.${tableCellClasses.footer}`]: {
      backgroundColor: "#373b8a",
      color: theme.palette.common.white
    }
  }));

  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [selectedRow, setSelectedRow] = useState(null);
  const [isFormOpen, setIsFormOpen] = useState(false);

  const queryClient = useQueryClient();

  const rowsData = useQuery({
    queryKey: ["users", page, rowsPerPage, searchText],
    queryFn: () => getUsers({ page, size: rowsPerPage, searchText }),
    placeholderData: keepPreviousData
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const handleRowSelect = (event, rowId) => {
    setSelectedRow(rowId);
    setIsFormOpen(true);
  };

  function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page">
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page">
          {theme.direction === "rtl" ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page">
          {theme.direction === "rtl" ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page">
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </Box>
    );
  }

  TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired
  };

  const handlePayment = async (e, userId) => {
    e.stopPropagation();
    try {
      await UserService.processFullPayment({ userId });
      queryClient.invalidateQueries("users");
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="w-full mt-12">
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Họ và tên</StyledTableCell>
              <StyledTableCell>Email</StyledTableCell>
              <StyledTableCell>Số điện thoại</StyledTableCell>
              <StyledTableCell>Số xu hiện có</StyledTableCell>
              <StyledTableCell>Quyền truy cập</StyledTableCell>
              <StyledTableCell>Loại tài khoản</StyledTableCell>
              <StyledTableCell>Hành động</StyledTableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {rowsData.data?.result.map((row) => (
              <TableRow
                key={row.id}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                  cursor: "pointer"
                }}
                onClick={(e) => handleRowSelect(e, row.id)}>
                <StyledTableCell align="left">{`${row.lastName} ${row.firstName}`}</StyledTableCell>
                <StyledTableCell align="left">{row.email}</StyledTableCell>
                <StyledTableCell align="left">{row.phoneNumber}</StyledTableCell>
                <StyledTableCell align="left">{row.totalMoney}</StyledTableCell>
                <StyledTableCell align="left">
                  {row.permissions.map((permission) => permission.name).join(", ")}
                </StyledTableCell>
                <StyledTableCell align="left">{row.subscriptionType}</StyledTableCell>
                <StyledTableCell align="left">
                  <button
                    onClick={(e) => handlePayment(e, row.id)}
                    className={`rounded px-4 py-2 bg-white text-black ${
                      row.totalMoney === 0 ? "opacity-50 cursor-default" : ""
                    }`}
                    disabled={row.totalMoney === 0}>
                    Chuyển đổi toàn bộ xu
                  </button>
                </StyledTableCell>
              </TableRow>
            ))}
          </TableBody>

          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50]}
                colSpan={3}
                count={rowsData.data?.total ?? 0}
                rowsPerPage={rowsPerPage}
                page={page - 1}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
                className="text-white"
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      <FormDialog
        data={rowsData.data?.result.find((r) => r.id === selectedRow)}
        isOpen={isFormOpen}
        setIsOpen={setIsFormOpen}
      />
    </div>
  );
};

const permissions = signal([]);
const subscriptionTypes = signal(["FREE", "PREMIUM"]);

function FormDialog({ data, isOpen, setIsOpen }) {
  const [isSaving, setIsSaving] = useState(false);
  const [userPermissions, setUserPermissions] = useState([]);
  const [userSubscriptionType, setUserSubscriptionType] = useState("");
  const [error, setError] = useState("");

  const queryClient = useQueryClient();

  useEffect(() => {
    if (isOpen) {
      setUserPermissions(data?.permissions.map((p) => p.name));
      setUserSubscriptionType(data?.subscriptionType);

      const getPermissions = async () => {
        try {
          const res = await PermissionService.getPermissions();
          permissions.value = res.data.map((p) => p.name);
        } catch (error) {
          console.error(error);
        }
      };

      if (permissions.value.length === 0) getPermissions();
    }
  }, [isOpen]);

  const handleClose = () => {
    setIsOpen(false);
    clearForm();
  };

  const handleSave = async () => {
    try {
      if (
        _.isEqual(
          userPermissions,
          data.permissions.map((p) => p.name)
        ) &&
        _.isEqual(userSubscriptionType, data.subscriptionType)
      ) {
        handleClose();
        return;
      }

      setIsSaving(true);
      const req = UserService.updatePermissions({
        userId: data.id,
        permissionNames: userPermissions
      });

      const req2 = UserService.updateSubscriptionType({
        userId: data.id,
        subscriptionType: userSubscriptionType
      });

      await Promise.all([req, req2]);

      setIsSaving(false);
      queryClient.invalidateQueries("users");
      handleClose();
    } catch (error) {
      setIsSaving(false);
      if (error.response) {
        setError(error.response.data.message);
      } else {
        setError("Something went wrong");
      }
    }
  };

  const clearForm = () => {
    setUserPermissions([]);
    setUserSubscriptionType("");
  };

  const handlePermissionChange = (event) => {
    const {
      target: { value }
    } = event;
    setUserPermissions(typeof value === "string" ? value.split(",") : value);
  };

  return (
    <React.Fragment>
      <Dialog open={isOpen} onClose={handleClose}>
        <DialogTitle>Edit user</DialogTitle>
        <DialogContent>
          <DialogContentText
            sx={{
              color: "red"
            }}>
            {error}
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Họ và tên"
            type="text"
            fullWidth
            variant="standard"
            value={`${data?.lastName} ${data?.firstName}` || ""}
            InputProps={{ readOnly: true, disableUnderline: true }}
          />
          <TextField
            margin="dense"
            id="email"
            label="Email"
            type="text"
            fullWidth
            variant="standard"
            value={data?.email || ""}
            InputProps={{ readOnly: true, disableUnderline: true }}
          />
          <TextField
            margin="dense"
            id="phoneNumber"
            label="Số điện thoại"
            type="text"
            fullWidth
            variant="standard"
            value={data?.phoneNumber || ""}
            InputProps={{ readOnly: true, disableUnderline: true }}
          />
          <TextField
            margin="dense"
            id="money"
            label="Số xu hiện có"
            type="number"
            fullWidth
            variant="standard"
            value={data?.totalMoney ?? 0}
            InputProps={{ readOnly: true, disableUnderline: true }}
          />
          <InputLabel id="permissions-multiple-lable">Quyền truy cập</InputLabel>
          <Select
            labelId="permissions-multiple-lable"
            id="permissions"
            multiple
            variant="standard"
            value={userPermissions || []}
            onChange={handlePermissionChange}
            renderValue={(selected) => (
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                {selected.map((value) => (
                  <Chip key={value} label={value} />
                ))}
              </Box>
            )}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 48 * 4.5 + 8,
                  width: 250
                }
              }
            }}>
            {permissions.value.map((name) => (
              <MenuItem key={name} value={name}>
                <Checkbox checked={userPermissions?.indexOf(name) > -1} />
                <ListItemText primary={name} />
              </MenuItem>
            ))}
          </Select>
          <InputLabel id="subscription-type-multiple-lable">Loại tài khoản</InputLabel>
          <Select
            labelId="subscription-type-multiple-lable"
            id="subscription-type"
            variant="standard"
            value={userSubscriptionType || ""}
            onChange={(e) => setUserSubscriptionType(e.target.value)}
            renderValue={(value) => value}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 48 * 4.5 + 8,
                  width: 250
                }
              }
            }}>
            {subscriptionTypes.value.map((name) => (
              <MenuItem key={name} value={name}>
                <Checkbox checked={name === userSubscriptionType} />
                <ListItemText primary={name} />
              </MenuItem>
            ))}
          </Select>
          <TextField
            margin="dense"
            id="withdrawMethod"
            label="Phương thức rút tiền"
            type="text"
            fullWidth
            variant="standard"
            value={data?.withdrawMethod ?? 0}
            InputProps={{ readOnly: true, disableUnderline: true }}
          />
          <TextField
            margin="dense"
            id="withdrawAccount"
            label="Tài khoản rút tiền"
            type="text"
            fullWidth
            variant="standard"
            value={data?.withdrawAccount ?? 0}
            InputProps={{ readOnly: true, disableUnderline: true }}
          />
          <TextField
            margin="dense"
            id="withdrawAccountName"
            label="Tên chủ tài khoản"
            type="text"
            fullWidth
            variant="standard"
            value={data?.withdrawAccountName ?? 0}
            InputProps={{ readOnly: true, disableUnderline: true }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSave}>{isSaving ? <CircularProgress /> : "Save"}</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
