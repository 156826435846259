import { Avatar as MUIAvatar } from "@mui/material";
import Util from "../../utils/Util";

const Avatar = ({ className, userInfo = {} }) => {
  const { avatarFile, avatarUrl, firstName, lastName } = userInfo;
  if (avatarFile) {
    return <MUIAvatar className={className} src={URL.createObjectURL(avatarFile)} />;
  }

  if (avatarUrl) {
    return <MUIAvatar className={className} src={avatarUrl} />;
  }

  return <MUIAvatar className={className} {...Util.stringAvatar(`${firstName} ${lastName}`)} />;
};

export default Avatar;
