import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { exam, updateQuestion } from "../../../signals/ExamSignal";

export function SortableOption({ id, ariaLabel, sectionIndex, questionIndex, index, option }) {
  const question = exam.value.sections[sectionIndex].questions[questionIndex];
  const anwser = exam.value.sections[sectionIndex].questions[questionIndex].answer;
  const { attributes, setNodeRef, transform, transition } = useSortable({ id });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  };

  const updateOption = (newOption) => {
    if (question.type === "CONVERSATION_SINGLE_CHOICE") {
      updateQuestion({
        sectionIndex,
        questionIndex,
        question: {
          ...question,
          content: {
            ...question.content,
            choices: question.content.choices.map((choice, i) => {
              if (i === index) {
                return newOption;
              } else {
                return choice;
              }
            })
          }
        }
      });
    } else {
      updateQuestion({
        sectionIndex,
        questionIndex,
        question: {
          ...question,
          content: question.content.map((choice, i) => {
            if (i === index) {
              return newOption;
            } else {
              return choice;
            }
          })
        }
      });
    }
  };

  const removeOption = () => {
    if (question.type === "CONVERSATION_SINGLE_CHOICE") {
      updateQuestion({
        sectionIndex,
        questionIndex,
        question: {
          ...question,
          content: {
            ...question.content,
            choices: question.content.choices.filter((_, i) => i !== index)
          }
        }
      });
    } else {
      updateQuestion({
        sectionIndex,
        questionIndex,
        question: {
          ...question,
          content: question.content.filter((_, i) => i !== index)
        }
      });
    }
  };

  return (
    <div
      ref={setNodeRef}
      style={style}
      {...attributes}
      className="flex items-center gap-2 w-[60%]"
      aria-label={ariaLabel}>
      {/* <i {...listeners} className="cursor-pointer">
        <img src="/assets/double-three-dots.svg" alt="double three dots" />
      </i> */}
      <input
        type="radio"
        className="w-4 h-4 rounded-full bg-white border border-[#5F5B5B50]"
        aria-label={ariaLabel}
        name={ariaLabel}
        value={option}
        checked={anwser === option}
        onChange={(event) => {
          if (event.target.checked) {
            updateQuestion({
              sectionIndex,
              questionIndex,
              question: {
                ...question,
                answer: option
              }
            });
          }
        }}></input>
      <input
        className="grow px-2 py-1 outline-none border-b-2 border-transparent focus:border-[#2639ED] hover:border-[#2639ED] rounded-[0.3rem]"
        type="text"
        defaultValue={option}
        aria-label={ariaLabel}
        onBlur={(e) => updateOption(e.target.value)}
      />
      <i aria-label={ariaLabel} onClick={() => removeOption()}>
        <img
          style={{
            filter:
              "brightness(0) saturate(100%) invert(37%) sepia(4%) saturate(4%) hue-rotate(33deg) brightness(98%) contrast(89%)"
          }}
          className="w-5 h-5 cursor-pointer"
          src="/assets/recycle-bin.svg"
          alt="recycle bin"
        />
      </i>
    </div>
  );
}
