import { useEffect, useState } from "react";
import { signal } from "@preact/signals-react";
import ExerciseBar from "../components/ExerciseBar";
import Video from "../components/exercise/video/Video";
import Flashcard from "../components/exercise/flashcard/Flashcard";
import Conversation from "../components/exercise/conversation/Conversation";
import SingleChoice from "../components/exercise/single-choice/SingleChoice";
import ExamService from "../services/ExamService";
import { Link, useSearchParams } from "react-router-dom";
import LectureService from "../services/LectureService";
import ChapterService from "../services/ChapterService";
import CourseService from "../services/CourseService";

export const examData = signal();
export const submissionAnswers = signal([]);

function Lesson() {
  let [searchParams] = useSearchParams();
  const [currentExercise, setCurrentExercise] = useState(0);
  const [courseData, setCourseData] = useState();

  useEffect(() => {
    const initData = async () => {
      const searchExamPromise = ExamService.searchExams({
        size: 1,
        page: 1,
        sortBy: "createdAt",
        order: 0,
        dataOption: "all",
        searchCriteriaList: [
          {
            filterKey: "lectureId",
            operation: "eq",
            value: searchParams.get("id")
          }
        ]
      });
      const getLecturePromise = LectureService.getLecture({
        id: searchParams.get("id")
      });

      const res1 = await Promise.all([searchExamPromise, getLecturePromise]);
      const examId = res1[0].data.result[0].id;
      const lecture = res1[1].data;

      const getChapterPromise = ChapterService.getChapter({
        id: lecture.chapterId
      });
      const getExamPromise = ExamService.getExam({
        id: examId
      });

      const res2 = await Promise.all([getChapterPromise, getExamPromise]);
      const chapter = res2[0].data;
      let exam = res2[1].data;
      if (exam.sections.length > 0) {
        exam = {
          ...exam,
          sections: exam.sections.map((section) => ({
            ...section,
            questions: section.questions.map((question) => ({
              ...question,
              isFinished: false,
              userChoice: null,
              content: JSON.parse(question.content)
            }))
          }))
        };
      }
      examData.value = exam;

      const res3 = await CourseService.getCourse({ id: chapter.courseId });
      const course = res3.data;

      setCourseData({
        ...course,
        chapter: {
          ...chapter,
          lecture: {
            ...lecture
          }
        }
      });
    };

    initData();
  }, [searchParams]);

  const renderQuestion = () => {
    const type = examData.value?.sections[0].questions[currentExercise].type;
    if (type === "VIDEO_SINGLE_CHOICE") {
      return (
        <Video
          signal={examData}
          currentExercise={currentExercise}
          setCurrentExercise={setCurrentExercise}
          isResult={false}
        />
      );
    } else if (type === "FLASHCARD") {
      return (
        <Flashcard
          signal={examData}
          currentExercise={currentExercise}
          setCurrentExercise={setCurrentExercise}
          isResult={false}
        />
      );
    } else if (type === "CONVERSATION_SINGLE_CHOICE") {
      return (
        <Conversation
          signal={examData}
          currentExercise={currentExercise}
          setCurrentExercise={setCurrentExercise}
          isResult={false}
        />
      );
    } else if (type === "SINGLE_CHOICE") {
      return (
        <SingleChoice
          signal={examData}
          currentExercise={currentExercise}
          setCurrentExercise={setCurrentExercise}
          isResult={false}
        />
      );
    } else {
      return <></>;
    }
  };

  return (
    <div className="px-4 w-full h-full flex flex-col items-center bg-[#F4F7FE] font-[Inter]">
      <div className="w-full md:w-[80%] bg-white rounded-xl">
        <div className="w-full md:h-[12.9375rem] shadow-[0px_40px_58px_-20px_rgba(112,144,176,0.12)] px-[5%] pb-8 md:pb-0">
          <h1 className="flex text-[#1B2559] text-center text-sm md:text-4xl font-bold leading-normal mt-6 md:mt-12 items-center">
            <Link to="/" className="hover:underline">
              {courseData?.title}
            </Link>
            <img
              src="/assets/lesson-tittle-icon.png"
              alt="icon"
              className="w-[0.375rem] h-[0.4375rem] mx-4"
            />
            <Link to={`/?chapter=${courseData?.chapter?.order}`} className="hover:underline">
              {courseData?.chapter?.title}
            </Link>
            <img
              src="/assets/lesson-tittle-icon.png"
              alt="icon"
              className="w-[0.375rem] h-[0.4375rem] mx-4"
            />
            {courseData?.chapter?.lecture?.title}
          </h1>
          <p className="text-[#A3AED0] text-xs md:text-[0.9375rem] font-medium leading-7 tracking-[-0.015rem] md:tracking-[-0.01875rem] mt-2 md:mt-[1.2rem]">
            Lorem ipsum dolor amet ipsum dolor amet ipsum dolor amet{" "}
          </p>
          <div className="mt-4 md:mt-[1.3rem]">
            <ExerciseBar data={examData.value?.sections[0].questions || []} />
          </div>
        </div>
        <div className="w-full">{renderQuestion()}</div>
      </div>
    </div>
  );
}

export default Lesson;
