import "./leaderboard.css";
import { useState } from "react";
import RankingCard from "../RankingCard";
import { Switch } from "@headlessui/react";
import CustomPagination from "../CustomPagination";
import ResultService from "../../services/ResultService";
import { Avatar } from "@mui/material";
import Util from "../../utils/Util";
import { useQuery } from "@tanstack/react-query";

const getRankingData = async ({ isMonthlyRanking, currentPage }) => {
  const res = await ResultService.getTopUsers({
    days: isMonthlyRanking ? 30 : 7,
    page: currentPage,
    size: 10
  });

  return res.data;
};

function Leaderboard() {
  const [currentPage, setCurrentPage] = useState(1);
  const [isMonthlyRanking, setIsMonthlyRanking] = useState(false);

  const rankingQuery = useQuery({
    queryKey: ["ranking", { isMonthlyRanking, currentPage }],
    queryFn: () => getRankingData({ isMonthlyRanking, currentPage })
  });
  const rankingData = rankingQuery.data?.result;
  const totalPages = rankingQuery.data?.totalPages;

  return (
    <div className="w-full flex justify-center">
      <div
        className="w-full md:w-[68.125rem] md:h-[135.375rem] rounded-t-xl flex flex-col items-center"
        style={{
          background: "linear-gradient(116deg,#4412ed -4.18%,#4313ec 43.23%,#4011ec 111.37%)"
        }}>
        <h2 className="text-[1.875rem] text-white font-bold leading-normal mt-14 mb-8 md:mb-20">
          Bảng xếp hạng
        </h2>
        <div className="flex flex-col w-full relative items-center justify-center ">
          <div className="flex relative w-full">
            <img
              className="absolute w-full md:-top-[16rem] md:-right-[4rem] z-[1]"
              src="/assets/leaderboard-bg.png"
              alt="background"
            />
          </div>
          <Switcher
            enabled={isMonthlyRanking}
            setEnabled={setIsMonthlyRanking}
            setCurrentPage={setCurrentPage}
          />
          <div>
            <div className="md:h-[42.8125rem] flex relative items-end justify-center">
              <div className="flex flex-col absolute items-center justify-center top-12 md:top-[5rem]">
                <img
                  src="/assets/rank1-icon.svg"
                  alt="icon"
                  className="w-12 h-[1.8125rem] md:w-[5.9375rem] md:h-[3.625rem]"
                />
                <div className="flex w-[5.54888rem] h-[5.54888rem] md:w-[11.0625rem] md:h-[11.0625rem] relative mt-2 md:mt-[0.62rem] items-center justify-center">
                  <img
                    src="/assets/avatar-bg.svg"
                    alt="background"
                    className="w-[5.54888rem] h-[5.54888rem] md:w-[11.0625rem] md:h-[11.0625rem] absolute z-[1]"
                  />
                  {/* <img
                    src={rankings.avatar}
                    alt="avatar"
                    className="w-[5.24181rem] h-[5.24181rem] md:w-[10.45044rem] md:h-[10.45044rem] rounded-[6.25rem] relative z-[2]"
                  /> */}
                  <Avatar
                    className="!w-[5.24181rem] !h-[5.24181rem] md:!w-[10.45044rem] md:!h-[10.45044rem] rounded-[6.25rem] relative z-[2] !text-4xl"
                    {...Util.stringAvatar(
                      rankingData?.at(0) &&
                        `${rankingData?.at(0)?.firstName} ${rankingData?.at(0)?.lastName}`
                    )}
                  />
                </div>
                <p className="mt-3 md:mt-4 w-28 md:w-44 max-h-12 md:max-h-16 text-white text-center text-sm md:text-xl font-semibold leading-6 tracking-[-0.0175rem] md:tracking-tight overflow-hidden text-ellipsis">
                  {rankingData?.at(0) &&
                    `${rankingData?.at(0)?.firstName} ${rankingData?.at(0)?.lastName}`}
                </p>
                <span
                  data-text={rankingData
                    ?.at(0)
                    ?.totalScore?.toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                  className="mt-2 md:mt-4 text-xl md:text-[2.5rem] leading-6 tracking-[-0.025rem] md:tracking-[-0.05rem]"></span>
              </div>
              <div className="flex flex-col absolute items-center justify-center top-[8rem] md:top-[15rem] left-[10%]">
                <div className="flex w-[4.1695rem] h-[4.1695rem] md:w-[8.3125rem] md:h-[8.3125rem] relative mt-[0.62rem] items-center justify-center">
                  <img
                    src="/assets/avatar-bg.svg"
                    alt="background"
                    className="w-[4.1695rem] h-[4.1695rem] md:w-[8.3125rem] md:h-[8.3125rem] absolute z-[1]"
                  />
                  {/* <img
                    src={rankingData.value[1].avatar}
                    alt="avatar"
                    className="w-[3.93875rem] h-[3.93875rem] md:w-[7.85256rem] md:h-[7.85256rem] rounded-[6.25rem] relative z-[2]"
                  /> */}
                  <Avatar
                    className="!w-[5.24181rem] !h-[5.24181rem] md:!w-[10.45044rem] md:!h-[10.45044rem] rounded-[6.25rem] relative z-[2] !text-4xl"
                    {...Util.stringAvatar(
                      rankingData?.at(1) &&
                        `${rankingData?.at(1)?.firstName} ${rankingData?.at(1)?.lastName}`
                    )}
                  />
                </div>
                <p className="mt-3 md:mt-4 w-28 md:w-44 max-h-12 md:max-h-16 text-white text-center text-sm md:text-xl font-semibold leading-6 tracking-[-0.0175rem] md:tracking-tight overflow-hidden text-ellipsis">
                  {rankingData?.at(1) &&
                    `${rankingData?.at(1)?.firstName} ${rankingData?.at(1)?.lastName}`}
                </p>
                <span
                  data-text={rankingData
                    ?.at(1)
                    ?.totalScore?.toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                  className="mt-2 md:mt-4 text-xl md:text-[2.5rem] leading-6 tracking-[-0.025rem] md:tracking-[-0.05rem]"></span>
              </div>
              <div className="flex flex-col absolute items-center justify-center top-[8rem] md:top-[15rem] right-[10%]">
                <div className="flex w-[4.1695rem] h-[4.1695rem] md:w-[8.3125rem] md:h-[8.3125rem] relative mt-[0.62rem] items-center justify-center">
                  <img
                    src="/assets/avatar-bg.svg"
                    alt="background"
                    className="w-[4.1695rem] h-[4.1695rem] md:w-[8.3125rem] md:h-[8.3125rem] absolute z-[1]"
                  />
                  {/* <img
                    src={rankingData.value[2].avatar}
                    alt="avatar"
                    className="w-[3.93875rem] h-[3.93875rem] md:w-[7.85256rem] md:h-[7.85256rem] rounded-[6.25rem] relative z-[2]"
                  /> */}
                  <Avatar
                    className="!w-[5.24181rem] !h-[5.24181rem] md:!w-[10.45044rem] md:!h-[10.45044rem] rounded-[6.25rem] relative z-[2] !text-4xl"
                    {...Util.stringAvatar(
                      rankingData?.at(2) &&
                        `${rankingData?.at(2)?.firstName} ${rankingData?.at(2)?.lastName}`
                    )}
                  />
                </div>
                <p className="mt-3 md:mt-4 w-28 md:w-44 max-h-12 md:max-h-16 text-white text-center text-sm md:text-xl font-semibold leading-6 tracking-[-0.0175rem] md:tracking-tight overflow-hidden text-ellipsis">
                  {rankingData?.at(2) &&
                    `${rankingData?.at(2)?.firstName} ${rankingData?.at(2)?.lastName}`}
                </p>
                <span
                  data-text={rankingData
                    ?.at(2)
                    ?.totalScore?.toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                  className="mt-2 md:mt-4 text-xl md:text-[2.5rem] leading-6 tracking-[-0.025rem] md:tracking-[-0.05rem]"></span>
              </div>
              <img
                src="/assets/top-ranking.png"
                alt="top ranking"
                className="w-[90%] md:w-[40.6875rem] md:h-[12.8125rem] mt-[17rem] md:mt-0"
              />
            </div>
          </div>
          <div
            className="mt-[2.75rem] mb-8 md:mb-0 w-full md:w-[62rem] md:h-[71.9375rem] px-4 md:px-10 rounded-[2rem] md:rounded-[3.25rem] bg-[#6442e4] relative border border-white flex flex-col items-center"
            style={{
              fill: "rgba(255, 255, 255, 0.20)",
              filter: "drop-shadow(30px 70px 120px rgba(27, 49, 66, 0.13))"
            }}>
            <div className="flex flex-row w-full pt-12 text-white text-center text-sm md:text-lg font-bold leading-[1.42994rem] md:leading-[1.83844rem] uppercase">
              <p>Hạng</p>
              <p className="pl-24">Họ và tên</p>
              <p className="ml-auto">Điểm</p>
            </div>
            <div className="w-full flex flex-col gap-[2.56rem] mt-7">
              {rankingData?.map((field, index) => (
                <RankingCard
                  key={index}
                  rank={(currentPage - 1) * 10 + index + 1}
                  avatar={
                    <Avatar
                      className="!w-[2.36169rem] !h-[2.36169rem] rounded-[6.25rem] ml-8"
                      {...Util.stringAvatar(`${field?.firstName} ${field?.lastName}`)}
                    />
                  }
                  name={`${field?.firstName} ${field?.lastName}`}
                  score={field?.totalScore?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                />
              ))}
            </div>
            <div className="flex my-10 md:my-0 md:absolute md:bottom-[4rem]">
              <CustomPagination
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                count={totalPages}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Leaderboard;

function Switcher({ enabled, setEnabled, setCurrentPage }) {
  return (
    <div>
      <Switch
        checked={enabled}
        onChange={() => {
          setEnabled(!enabled);
          setCurrentPage(1);
        }}
        className={
          "bg-[#6742ea] relative inline-flex h-[2.81rem] w-[10.8rem] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white/75"
        }>
        <div className="w-full h-full flex items-center justify-center z-10 font-[Inter] text-sm font-semibold leading-[1.575rem]">
          <span
            className={`${
              enabled ? "text-[#FFFFFF]/70" : "text-[#201DCE]"
            } w-1/2 transition-colors duration-200`}>
            7 ngày
          </span>
          <span
            className={`${
              !enabled ? "text-[#FFFFFF]/70" : "text-[#201DCE]"
            } w-1/2 transition-colors duration-200`}>
            30 ngày
          </span>
        </div>
        <span
          aria-hidden="true"
          className={`${
            enabled ? "left-1/2 right-[0.275rem]" : "left-[0.275rem] right-1/2"
          } absolute top-1/2 -translate-y-1/2
            pointer-events-none inline-block h-[2rem] transform rounded-full bg-white shadow-lg ring-0 transition-all ease-in-out`}
          style={{
            transition: enabled ? "left 0.25s, right 0.2s 0.1s" : "left 0.2s 0.1s, right 0.25s"
          }}
        />
      </Switch>
    </div>
  );
}
