import { CircularProgress } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import StorageService from "../../../services/StorageService";
import { addQuestion, exam, removeQuestion, updateQuestion } from "../../../signals/ExamSignal";
import Modal from "../../Modal";
import { EditorAddNewButton } from "../EditorAddNewButton";
import { SortableOptionsContainer } from "../option/SortableOptionsContainer";

export const EditorExpandedQuestion = ({
  sectionIndex,
  index,
  setIsExpanded,
  setTriggerCalculateHeight
}) => {
  const question = exam.value.sections[sectionIndex].questions[index];
  const content = exam.value.sections[sectionIndex].questions[index].content;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [media, setMedia] = useState(question.media ?? null);
  const [uploading, setUploading] = useState(false);
  const mediaLoadingRef = useRef(null);
  const ref = useRef(null);

  // Set event listener for click outside
  useEffect(() => {
    const handleClickOutside = (e) => {
      if (ref.current && !ref.current.contains(e.target)) {
        setIsExpanded(false);
      }
    };
    document.addEventListener("mouseup", handleClickOutside);
    return () => {
      document.removeEventListener("mouseup", handleClickOutside);
    };
  }, []);

  const addNewOption = () => {
    if (question.type === "CONVERSATION_SINGLE_CHOICE") {
      updateQuestion({
        sectionIndex,
        questionIndex: index,
        question: {
          ...question,
          content: {
            ...question.content,
            choices: [...question.content.choices, `Option ${content.choices.length + 1}`]
          }
        }
      });
    } else {
      updateQuestion({
        sectionIndex,
        questionIndex: index,
        question: {
          ...question,
          content: [...question.content, `Option ${content.length + 1}`]
        }
      });
    }
    setTriggerCalculateHeight(new Date().getTime());
  };

  const updateQuestionTitle = (newTitle) => {
    updateQuestion({
      sectionIndex,
      questionIndex: index,
      question: {
        ...question,
        title: newTitle
      }
    });
  };

  const renderMedia = (media) => {
    if (media.mediaType === "image") {
      return (
        <div className="flex items-center h-48">
          <img
            className="w-full h-full object-contain cursor-pointer"
            src={media.mediaUrl}
            alt="question-img"
            onClick={() => setIsModalOpen(true)}
          />
        </div>
      );
    } else if (media.mediaType === "video") {
      return (
        <div className="flex items-center h-48">
          <video
            className="w-full h-full cursor-pointer"
            src={media.mediaUrl}
            controls
            controlsList="nodownload"
          />
        </div>
      );
    } else if (media.mediaType === "audio") {
      return (
        <div className="flex items-center">
          <audio
            className="w-[90%] md:w-[60%] h-[3.625rem] mx-auto"
            src={media.mediaUrl}
            controls
            controlsList="nodownload"
          />
        </div>
      );
    }
  };

  const renderMediaSelect = () => {
    const accept = () => {
      if (["VIDEO_SINGLE_CHOICE"].includes(question.type)) {
        return "audio/*,image/*,video/*";
      } else if (["CONVERSATION_SINGLE_CHOICE"].includes(question.type)) {
        return "audio/*,image/*,video/*";
      }

      return "*";
    };

    if (["VIDEO_SINGLE_CHOICE", "CONVERSATION_SINGLE_CHOICE"].includes(question.type)) {
      return (
        <label
          className={`grid place-items-center bg-white w-8 h-full border-b-2 pb-1 pt-2 border-b-white peer-hover:border-[#2639ED] peer-focus:border-[#2639ED] rounded-r-[0.3rem] ${
            uploading ? "cursor-default opacity-50" : "cursor-pointer opacity-100"
          }`}
          tabIndex={0}>
          <input
            className="hidden"
            type="file"
            accept={accept()}
            disabled={uploading}
            onChange={async (e) => {
              try {
                const file = e.target.files[0];
                if (!file) return;

                mediaLoadingRef.current.style.display = "flex";
                setUploading(true);
                const res = await StorageService.uploadFile(file);
                mediaLoadingRef.current.style.display = "none";
                const mediaData = res.data;
                setMedia(mediaData);
                setUploading(false);
                updateQuestion({
                  sectionIndex,
                  questionIndex: index,
                  question: {
                    ...question,
                    media: mediaData
                  }
                });
              } catch (error) {
                console.error(error);
              }
            }}
          />
          <img className="w-4 h-4" src="/assets/media.svg" alt="media select" />
        </label>
      );
    }
    return null;
  };

  const handleConversationChange = (event, i) => {
    const newConversation = [...content.conversation];
    newConversation[i] = event.target.value;
    updateQuestion({
      sectionIndex,
      questionIndex: index,
      question: {
        ...question,
        content: {
          ...question.content,
          conversation: newConversation
        }
      }
    });
  };

  return (
    <div ref={ref}>
      <div className="bg-[#F5F5F5] border-t-[5px] border-[#1BAE3B] pt-3 pb-4 pr-4 pl-8 mb-6 rounded-[0.625rem]">
        <div className="flex items-center justify-end gap-6 mb-4">
          <ToolBar sectionIndex={sectionIndex} index={index} />
        </div>
        <div className="text-sm leading-6 flex flex-col gap-4">
          <div className="flex items-center h-8">
            <span className="mr-2">{index + 1}.</span>
            <input
              className="peer h-full grow px-2 py-1 outline-none border-b-2 border-transparent focus:border-[#2639ED] hover:border-[#2639ED] rounded-l-[0.3rem]"
              type="text"
              value={question.title ?? "Question"}
              onChange={(e) => updateQuestionTitle(e.target.value)}
            />
            {renderMediaSelect()}
          </div>
          <div
            ref={mediaLoadingRef}
            className="self-center w-48 h-32 flex-col items-center justify-center gap-4 bg-gray-200 hidden">
            <CircularProgress />
            <p>Uploading your media</p>
          </div>
          {media && renderMedia(media)}
          {question.type === "CONVERSATION_SINGLE_CHOICE" && (
            <div>
              <p className="text-base">Conversation</p>
              {Array.from({ length: 4 }, (_, i) => i).map((i, index) => (
                <div key={index} className="flex flex-col gap-2">
                  <label htmlFor={`speech-${i}`}>{`Speech ${i + 1}:`}</label>
                  <input
                    className="py-1 px-4 rounded-sm"
                    type="text"
                    id={`speech-${i}`}
                    value={content.conversation[i]}
                    onChange={(e) => handleConversationChange(e, i)}
                  />
                </div>
              ))}
            </div>
          )}
          <SortableOptionsContainer sectionIndex={sectionIndex} questionIndex={index} />
          <button
            className={"flex items-center gap-2 transition-all duration-200 ease-linear w-max"}
            onClick={() => addNewOption()}>
            <i>
              <img
                style={{
                  filter:
                    "brightness(0) saturate(100%) invert(17%) sepia(96%) saturate(2246%) hue-rotate(208deg) brightness(104%) contrast(106%)"
                }}
                src="/assets/plus.svg"
                alt="plus"
              />
            </i>
            <p className="font-medium">Add option</p>
          </button>
        </div>
      </div>
      <EditorAddNewButton sectionIndex={sectionIndex} />
      <Modal isOpen={isModalOpen} setIsOpen={setIsModalOpen} title="Image view">
        {media && renderMedia(media)}
      </Modal>
    </div>
  );
};

function ToolBar({ sectionIndex, index }) {
  const question = exam.value.sections[sectionIndex].questions[index];

  const copyQuestion = () => {
    addQuestion({
      sectionIndex,
      question: {
        title: question.title,
        type: question.type,
        content: question.content,
        answer: question.answer
      }
    });
  };

  const toolBarActions = Array.of(
    {
      src: "/assets/copy.svg",
      alt: "copy",
      onClick: copyQuestion,
      className: ""
    },
    {
      src: "/assets/recycle-bin.svg",
      alt: "recycle bin",
      onClick: () => {
        if (exam.value.sections[sectionIndex].questions.length === 1) return;
        removeQuestion({ sectionIndex, questionIndex: index });
      },
      className: `${
        exam.value.sections[sectionIndex].questions.length === 1
          ? "opacity-50 cursor-default"
          : "opacity-100"
      }`
    }
    // {
    //   src: "/assets/arrow-down.svg",
    //   alt: "arrow down",
    //   onClick: () => {
    //     if (index === exam.value.sections[sectionIndex].questions.length - 1)
    //       return;
    //     moveQuestion({
    //       sectionIndex,
    //       oldIndex: index,
    //       newIndex: index + 1,
    //     });
    //   },
    //   className: `${
    //     index === exam.value.sections[sectionIndex].questions.length - 1
    //       ? "opacity-50 cursor-default"
    //       : "opacity-100"
    //   }`,
    // },
    // {
    //   src: "/assets/arrow-up.svg",
    //   alt: "arrow up",
    //   onClick: () => {
    //     if (index === 0) return;
    //     moveQuestion({
    //       sectionIndex,
    //       oldIndex: index,
    //       newIndex: index - 1,
    //     });
    //   },
    //   className: `${index === 0 ? "opacity-50 cursor-default" : "opacity-100"}`,
    // }
  );
  return (
    <>
      {toolBarActions.map((icon, i) => (
        <i key={i} onClick={icon.onClick}>
          <img
            className={`w-5 h-5 cursor-pointer ${icon.className}`}
            style={{
              filter:
                "brightness(0) saturate(100%) invert(37%) sepia(4%) saturate(4%) hue-rotate(33deg) brightness(98%) contrast(89%)"
            }}
            src={icon.src}
            alt={icon.alt}
          />
        </i>
      ))}
    </>
  );
}
