import { Fragment, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";

// const type = ["All", "Gain", "Deduction", "Consumption"];
const type = ["Option 1", "Option 2", "Option 3", "Option 4"];

function OptionFilter() {
  const [selected, setSelected] = useState(type[0]);

  return (
    <>
      <span
        className="text-[0.875rem] font-medium leading-[1.664rem]"
        style={{ color: "var(--text, #232323)" }}>
        Lorem ipsum dolor
      </span>
      <div className="w-[20rem] h-[3.45rem]">
        <Listbox value={selected} onChange={setSelected}>
          <div className="w-[20rem] relative">
            <Listbox.Button className="relative w-[20rem] h-[3.45rem] cursor-default rounded-[0.625rem] bg-white py-2 pl-2 pr-10 text-left text-sm font-normal leading-3 border border-solid border-[#859BCA] focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
              <span className="block truncate px-4">{selected}</span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={2.5}
                  stroke="#859BCA"
                  className="w-5 h-5 mr-1">
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                  />
                </svg>
              </span>
            </Listbox.Button>
            <Transition
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0">
              <Listbox.Options className="absolute z-10 p-3 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
                {type.map((type, typeIdx) => (
                  <Listbox.Option
                    key={typeIdx}
                    className={({ active }) =>
                      `relative rounded-[0.625rem] cursor-default select-none py-2 pl-12 pr-4 ${
                        active
                          ? "bg-[#F4F7FE] text-[#2B3674] font-semibold"
                          : "text-[#2B3674]/80 font-medium"
                      }`
                    }
                    value={type}>
                    {({ selected }) => (
                      <>
                        <span
                          className={`block truncate  ${selected ? "font-bold" : "font-medium"}`}>
                          {type}
                        </span>
                        {selected ? (
                          <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-[#2B3674]">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={2}
                              stroke="currentColor"
                              className="w-5 h-5">
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M4.5 12.75l6 6 9-13.5"
                              />
                            </svg>
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </Listbox>
      </div>
    </>
  );
}

export default OptionFilter;
