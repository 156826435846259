import { exam } from "../../../signals/ExamSignal";
import ReactPlayer from "react-player";

export function EditorCollapsedQuestion({ ariaLabel, sectionIndex, index, dragMode }) {
  const question = exam.value.sections[sectionIndex].questions[index];
  const content = exam.value.sections[sectionIndex].questions[index].content;

  const renderCollapsedQuestion = () => {
    switch (question.type) {
      case "SINGLE_CHOICE":
        return (
          <>
            <div className="flex items-center md:px-0 justify-start">
              <div className="flex flex-col items-start justify-center md:min-w-[32rem]">
                {content.map((choice, index) => (
                  <div
                    key={index}
                    className="m-[0.8rem] md:min-w-[7rem] md:max-w-[19rem] min-h-[1.65rem] flex relative items-center justify-start">
                    <input
                      className="absolute w-full h-full m-0 cursor-pointer z-[2] opacity-0 peer"
                      type="radio"
                      name={ariaLabel}
                      id={ariaLabel}
                      value={choice}
                      checked={question.answer === choice}
                      readOnly
                    />
                    <img
                      className="w-[1.0625rem] h-[1.0625rem] rounded-full hidden peer-checked:block"
                      src="/assets/check-icon.svg"
                      alt="check"
                    />
                    <img
                      className="w-[1.0625rem] h-[1.0625rem] rounded-full peer-checked:hidden"
                      src="/assets/check-box.svg"
                      alt="check box"
                    />
                    <label
                      className="relative flex items-center justify-start w-full h-full font-['DM_Sans'] text-[#7D8BB5] text-sm text-center font-normal leading-[175%] ml-[0.8rem] gap-2"
                      htmlFor={ariaLabel}>
                      {choice}
                    </label>
                  </div>
                ))}
              </div>
            </div>
          </>
        );
      case "VIDEO_SINGLE_CHOICE":
        return (
          <>
            {question.media && (
              <div className="md:w-4/5 flex justify-center relative mx-auto">
                <ReactPlayer controls controlsList="nodownload" url={question.media?.mediaUrl} />
              </div>
            )}
            <div className="flex flex-col w-full items-center">
              <img
                src="/assets/answer-bar.png"
                alt="answer bar"
                className="mt-[5.4rem] mb-8 w-[7.9375rem] h-[0.1875rem] object-fill"
              />
              <div className="flex items-center justify-center">
                <div className="flex flex-wrap justify-center">
                  {content.map((choice, index) => (
                    <div key={index}>
                      <div className="m-[1.35rem] min-w-[6.4375rem] max-w-[9rem] h-[5.3125rem] flex relative items-center justify-center">
                        <input
                          className="absolute w-full h-full m-0 cursor-pointer z-[2] opacity-0 peer"
                          type="radio"
                          name={ariaLabel}
                          id={ariaLabel}
                          value={choice}
                          checked={question.answer === choice}
                          readOnly
                        />
                        <img
                          className="absolute rounded-full bg-white hidden -right-1 -top-1 z-10 peer-checked:block"
                          src="/assets/check-icon.svg"
                          alt="check"
                        />
                        <label
                          htmlFor="answer"
                          className="relative flex items-center justify-center w-full h-full rounded-[0.625rem] border border-solid border-[#A3AED0] px-1 text-[#6274AC] text-base text-center font-normal leading-[133.333%]">
                          {choice}
                        </label>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </>
        );
      case "CONVERSATION_SINGLE_CHOICE":
        return (
          <>
            {question.media && (
              <audio
                src={question.media?.mediaUrl}
                preload="none"
                controls
                controlsList="nodownload"
                className="w-[90%] md:w-[60%] h-[3.625rem] mx-auto"></audio>
            )}
            <div className="w-full md:w-[60%] flex flex-col gap-5 my-8 p-4 rounded-2xl mx-auto">
              {content.conversation.map((message, index) => {
                if (!message) {
                  return null;
                }

                return (
                  <div
                    key={index}
                    className={`flex flex-row gap-5 ${index % 2 === 0 ? "" : "ml-auto"}`}>
                    {index % 2 === 0 ? (
                      <>
                        <img
                          src="/assets/avatar3.png"
                          alt="avatar"
                          className="w-[2.9375rem] h-[2.9375rem] rounded-full"
                        />
                      </>
                    ) : (
                      <></>
                    )}
                    <div
                      className={`w-[17.875rem] flex items-center justify-center p-6 ${
                        index % 2 === 0 ? "bg-[#2675EC]" : "bg-[#F6F6F6]"
                      }`}
                      style={{
                        borderRadius:
                          index % 2 === 0
                            ? "2.1875rem 2.1875rem 2.1875rem 0.625rem"
                            : "2.1875rem 0.625rem 2.1875rem 2.1875rem"
                      }}>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: message.replace(
                            "___",
                            `<span class='text-[#28B446] font-bold'>${question.answer}</span>`
                          )
                        }}
                        className={`text-[0.9375rem] font-medium leading-7 tracking-[-0.01875rem] ${
                          index % 2 === 0 ? "text-white" : "text-[#1B2559]"
                        }`}></p>
                    </div>
                    {index % 2 !== 0 ? (
                      <>
                        <img
                          src="/assets/avatar4.png"
                          alt="avatar"
                          className="w-[2.9375rem] h-[2.9375rem] rounded-full"
                        />
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                );
              })}
            </div>
            <div className="flex flex-col w-full items-center">
              <div className="flex items-center justify-center">
                <div className="flex flex-wrap justify-center">
                  {content.choices.map((choice, index) => (
                    <div key={index}>
                      <div className="m-[1.35rem] min-w-[6.4375rem] max-w-[9rem] h-[5.3125rem] flex relative items-center justify-center">
                        <input
                          className="absolute w-full h-full m-0 cursor-pointer z-[2] opacity-0 peer"
                          type="radio"
                          name={ariaLabel}
                          id={ariaLabel}
                          value={choice}
                          checked={question.answer === choice}
                          readOnly
                        />
                        <img
                          className="absolute -top-2 -right-2 rounded-full bg-white hidden z-10 peer-checked:block"
                          src="/assets/check-icon.svg"
                          alt="check"
                        />
                        <label
                          className="relative flex items-center justify-center w-full h-full rounded-[0.625rem] border border-solid border-[#A3AED0] px-1 text-[#6274AC] text-base text-center font-normal leading-[133.333%]"
                          htmlFor={ariaLabel}>
                          {choice}
                        </label>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </>
        );
      default:
        return <></>;
    }
  };

  const renderQuestionTypeNote = () => {
    switch (question.type) {
      case "SINGLE_CHOICE":
        return <p className="text-base font-medium">Single choice question</p>;
      case "VIDEO_SINGLE_CHOICE":
        return <p className="text-base font-medium">Video single choice question</p>;
      case "CONVERSATION_SINGLE_CHOICE":
        return <p className="text-base font-medium">Conversation single choice question</p>;
      default:
        return <></>;
    }
  };

  return (
    <div className={"flex flex-col gap-2 group"}>
      {renderQuestionTypeNote()}
      <div className="flex items-center gap-2">
        <span>{index + 1}.</span>
        <h4>{question.title}</h4>
      </div>

      {!dragMode && renderCollapsedQuestion()}
    </div>
  );
}
