import axiosClient from "./AxiosClient";

class UserService {
  static login({ email, password }) {
    return axiosClient.post("/users/login", { email, password });
  }

  static register({ firstName, lastName, email, password, confirmPassword, referrerEmail }) {
    return axiosClient.post("/users", {
      firstName,
      lastName,
      email,
      password,
      confirmPassword,
      referrerEmail
    });
  }

  static getMe() {
    return axiosClient.get("/users/me");
  }

  static updateProfile({
    firstName,
    lastName,
    phoneNumber,
    gender,
    birthday,
    withdrawMethod,
    withdrawAccount,
    withdrawAccountName,
    withdrawBankName
  }) {
    return axiosClient.put("/users", {
      firstName,
      lastName,
      phoneNumber,
      gender,
      birthday,
      withdrawMethod,
      withdrawAccount,
      withdrawAccountName,
      withdrawBankName
    });
  }

  static changePassword({ currentPassword, newPassword, confirmPassword }) {
    return axiosClient.post("/users/change-password", {
      currentPassword,
      newPassword,
      confirmPassword
    });
  }

  static searchUsers({ page, size, sortBy, order, searchCriteriaList, dataOption }) {
    return axiosClient.post(
      `/users/search?sortBy=${sortBy}&page=${page}&size=${size}&order=${order}`,
      {
        dataOption,
        searchCriteriaList
      }
    );
  }

  static updatePermissions({ userId, permissionNames }) {
    return axiosClient.post("/users/permissions", {
      userId,
      permissions: permissionNames
    });
  }

  static updateSubscriptionType({ userId, subscriptionType }) {
    return axiosClient.post("/users/subscription-type", {
      userId,
      subscriptionType
    });
  }

  static processFullPayment({ userId }) {
    return axiosClient.post(`/users/${userId}/full-payment`);
  }

  static uploadAvatar({ file }) {
    return axiosClient.postForm("/users/avatar", {
      file: file
    });
  }

  static requestWithdraw() {
    return axiosClient.post("/users/withdraw");
  }
}

export default UserService;
