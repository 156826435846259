import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Dialog as MUIDialog
} from "@mui/material";
import { Fragment } from "react";
import { useNavigate } from "react-router-dom";

export const DialogButtons = {
  CANCEL_CONFIRM: [
    {
      type: "discard",
      id: "cancel",
      label: "Huỷ",
      variant: "outlined"
    },
    {
      type: "confirm",
      id: "confirm",
      label: "Xác nhận"
    }
  ],
  CANCEL_DELETE: [
    {
      type: "discard",
      id: "cancel",
      label: "Huỷ",
      variant: "outlined"
    },
    {
      type: "confirm",
      id: "delete",
      label: "Xoá"
    }
  ],
  CANCEL_SAVE: [
    {
      type: "discard",
      id: "cancel",
      label: "Huỷ",
      variant: "outlined"
    },
    {
      type: "confirm",
      id: "save",
      label: "Lưu"
    }
  ],
  OK: [
    {
      type: "confirm",
      id: "ok",
      label: "OK"
    }
  ],
  HOME_CONFIRM: [
    {
      type: "home",
      id: "home",
      label: "Về trang chủ",
      variant: "outlined"
    },
    {
      type: "confirm",
      id: "delete",
      label: "Xác nhận"
    }
  ]
};

const getBtnColor = (btnId, defaultColor) => {
  if (defaultColor) {
    return defaultColor;
  }

  switch (btnId) {
    case "save":
    case "ok":
    case "confirm":
      return "success";
    case "delete":
      return "error";
    default:
      return "primary";
  }
};

const Dialog = ({
  open,
  onClose,
  onConfirm,
  textContent,
  title,
  buttons,
  children,
  width,
  headerBgColor,
  headerTitleColor
}) => {
  const navigate = useNavigate();

  return (
    <Fragment>
      <MUIDialog
        open={open}
        onClose={onClose}
        fullWidth
        sx={{
          zIndex: 15002,
          "& .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded": {
            maxWidth: width
          }
        }}>
        <DialogTitle
          fontSize="18px"
          fontWeight="bolder"
          bgcolor={headerBgColor || "#70d8bd"}
          sx={{ color: headerTitleColor || "black" }}>
          {title || ""}
        </DialogTitle>
        <DialogContent sx={{ padding: "1rem !important" }}>
          {textContent ? (
            <DialogContentText paddingX="0.5rem" component="span" fontWeight="bold">
              {textContent}
            </DialogContentText>
          ) : null}
          {children}
        </DialogContent>
        <DialogActions>
          {(buttons || []).map(({ id, type, label, startIcon, endIcon, variant, color }) => (
            <Button
              key={id}
              variant={variant || "contained"}
              color={getBtnColor(id, color)}
              startIcon={startIcon}
              onClick={(e) => {
                if (type === "home") {
                  navigate("/");
                  onClose(e);
                } else {
                  type === "discard" ? onClose(e) : onConfirm(e);
                }
              }}
              endIcon={endIcon}>
              {label}
            </Button>
          ))}
        </DialogActions>
      </MUIDialog>
    </Fragment>
  );
};

export default Dialog;
