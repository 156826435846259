import "./index.css";
import React from "react";
import Root from "./routes/Root";
import Money from "./routes/Money";
import Lesson from "./routes/Lesson";
import Result from "./routes/Result";
import Profile from "./routes/Profile";
import ReactDOM from "react-dom/client";
import HomePage from "./routes/HomePage";
import StarPoint from "./routes/StarPoint";
import reportWebVitals from "./reportWebVitals";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import ProtectedRoutes from "./routes/ProtectedRoutes";
import EditorRoot from "./routes/EditorRoot";
import Editor from "./routes/Editor";
import WelcomePage from "./components/welcome-page/WelcomePage";
import EditorCourseListPage from "./routes/EditorCourseListPage";
import EditorChapterListPage from "./routes/EditorChapterListPage";
import EditorLectureListPage from "./routes/EditorLectureListPage";
import EditorExamListOfLecturePage from "./routes/EditorExamListOfLecturePage";
import Login from "./components/authentication/Login";
import Register from "./components/authentication/Register";
import AdminPage from "./routes/AdminPage";
import NewPassWord from "./components/authentication/NewPassword";
import LinkResetPassword from "./components/authentication/LinkResetPassword";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import NotFound from "./routes/NotFound";
import OauthCallback from "./routes/OauthCallback";

// Create a client
const queryClient = new QueryClient();

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    children: [
      {
        path: "/",
        element: <HomePage />
      },
      {
        path: "/lesson",
        element: <Lesson />
      },
      {
        path: "/result",
        element: <Result />
      },
      {
        path: "/profile",
        element: <Profile />
      },
      {
        path: "/star-point",
        element: <StarPoint />
      },
      {
        path: "/transaction-history",
        element: <Money />
      }
    ]
  },
  {
    path: "/",
    element: <WelcomePage />,
    children: [
      {
        path: "login",
        element: <Login />
      },
      {
        path: "register",
        element: <Register />
      },
      {
        path: "reset-password",
        element: <LinkResetPassword />
      },
      {
        path: "new-password",
        element: <NewPassWord />
      }
    ]
  },
  {
    path: "/editor",
    element: <EditorRoot />,
    children: [
      {
        path: "courses",
        element: (
          <ProtectedRoutes authority={"EDITOR"}>
            <EditorCourseListPage />
          </ProtectedRoutes>
        )
      },
      {
        path: "course",
        element: (
          <ProtectedRoutes authority={"EDITOR"}>
            <EditorChapterListPage />
          </ProtectedRoutes>
        )
      },
      {
        path: "chapter",
        element: (
          <ProtectedRoutes authority={"EDITOR"}>
            <EditorLectureListPage />
          </ProtectedRoutes>
        )
      },
      {
        path: "lecture",
        element: (
          <ProtectedRoutes authority={"EDITOR"}>
            <EditorExamListOfLecturePage />
          </ProtectedRoutes>
        )
      },
      {
        path: "new",
        element: (
          <ProtectedRoutes authority={"EDITOR"}>
            <Editor />
          </ProtectedRoutes>
        )
      },
      {
        path: "exam",
        element: (
          <ProtectedRoutes authority={"EDITOR"}>
            <Editor />
          </ProtectedRoutes>
        )
      }
    ]
  },
  {
    path: "oauth2/callback",
    element: <OauthCallback />
  },
  {
    path: "admin",
    element: (
      <ProtectedRoutes authority={"ADMIN"}>
        <AdminPage />
      </ProtectedRoutes>
    )
  },
  {
    path: "/404",
    element: <NotFound />
  },
  {
    path: "*",
    element: <NotFound />
  }
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
    </QueryClientProvider>
  </React.StrictMode>
);

reportWebVitals();
