import { Tooltip } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import ChapterService from "../services/ChapterService";
import CourseService from "../services/CourseService";
import { currentCourse } from "../signals/CourseSignal";
import { userInfoSignal } from "../signals/UserInfoSignal";
import { signedInState } from "../signals";
import { ScrollMenu } from "react-horizontal-scrolling-menu";
import { LeftArrow, RightArrow } from "./scrolling/Arrow";
import usePreventBodyScroll from "./scrolling/usePreventBodyScroll";
import "react-horizontal-scrolling-menu/dist/styles.css";

const getCourse = async () => {
  const res = await CourseService.searchCourses({
    dataOption: "all",
    page: 1,
    size: 1,
    sortBy: "createdAt",
    order: "desc",
    searchCriteriaList: []
  });

  return res.data.result[0] || {};
};

const getChapters = async (courseId) => {
  const res = await CourseService.getChapters({
    courseId,
    page: 1,
    size: 1000,
    sortBy: "order",
    order: "asc"
  });

  return res.data.result;
};

const getLectures = async (chapterId) => {
  const res = await ChapterService.getLectures({
    chapterId,
    page: 1,
    size: 1000,
    sortBy: "order",
    order: "asc"
  });

  return res.data.result;
};

function finishedCount(data) {
  let count = 0;
  data.forEach((element) => {
    if (element.completed) {
      count++;
    }
  });
  return count;
}

function progressBarLength(data) {
  let progress = (finishedCount(data) / data.length) * 100;
  return Math.round(progress);
}

function LessonSection() {
  const navigate = useNavigate();
  let [searchParams] = useSearchParams();
  const [currentChapter, setCurrentChapter] = useState(1);
  const [currentLecturePage, setCurrentLecturePage] = useState(1);

  const course = useQuery({
    queryKey: ["course"],
    queryFn: getCourse
  });
  const chapters = useQuery({
    queryKey: ["chapters", course.data?.id],
    queryFn: () => getChapters(course.data?.id),
    enabled: !!course.data?.id
  });
  const lectures = useQuery({
    queryKey: ["lectures", currentChapter],
    queryFn: () => getLectures(chapters.data?.[currentChapter - 1]?.id),
    enabled: !!chapters.data?.length && !!currentChapter
  });

  const [firstIndex, setFirstIndex] = useState(0);
  const [lastIndex, setLastIndex] = useState(0);
  const [displayedLectures, setDisplayedLectures] = useState([]);
  const [mobileDisplayedLectures, setMobileDisplayedLectures] = useState([]);

  useEffect(() => {
    const requestChapter = searchParams.get("chapter");
    if (requestChapter) {
      setCurrentChapter(parseInt(requestChapter));
    }
  }, [searchParams]);

  useEffect(() => {
    if (currentLecturePage === 1) {
      setFirstIndex(0);
      setLastIndex(5);
    } else {
      setFirstIndex((currentLecturePage - 1) * 5);
      if (currentLecturePage * 5 > lectures.data?.length) {
        setLastIndex(lectures.data?.length);
      } else {
        setLastIndex(currentLecturePage * 5);
      }
    }
  }, [currentLecturePage, lectures.data]);

  useEffect(() => {
    setDisplayedLectures(lectures.data?.slice(firstIndex, lastIndex));
    setMobileDisplayedLectures(lectures.data?.slice(0, lastIndex));
  }, [firstIndex, lastIndex, lectures.data]);

  const nextLecturePage = () => {
    if (currentLecturePage * 5 < lectures.data?.length) {
      setCurrentLecturePage(currentLecturePage + 1);
    }
  };

  const previousLecturePage = () => {
    if (currentLecturePage > 1) {
      setCurrentLecturePage(currentLecturePage - 1);
    }
  };

  const { disableScroll, enableScroll } = usePreventBodyScroll();

  const onWheel = (apiObj, ev) => {
    const isThouchpad = Math.abs(ev.deltaX) !== 0 || Math.abs(ev.deltaY) < 15;
    if (isThouchpad) {
      ev.stopPropagation();
      return;
    }
    if (ev.deltaY < 0) {
      apiObj.scrollNext();
    } else if (ev.deltaY > 0) {
      apiObj.scrollPrev();
    }
  };

  return (
    <div className="flex relative px-6 w-full md:h-[42.5rem] mt-[1.2rem] justify-center">
      <img
        className="hidden md:flex md:absolute w-full h-[42.5rem]"
        src="../../../assets/home-page-bg.png"
        alt="background"
      />
      <div className="w-full md:w-[68.125rem] md:h-[42.5rem] relative md:bg-white rounded-xl flex flex-col items-center">
        <h2 className="text-base md:text-[1.875rem] text-[#4412ED] font-bold leading-normal md:mt-14 mb-[0.7rem]">
          {course.data?.title}
        </h2>
        <div className="w-full px-12">
          <p className="text-[#A3AED0] text-center text-[0.875rem] md:text-[0.9375rem] font-medium leading-[1.375rem] md:leading-7 tracking-[-0.0175rem] md:tracking-[-0.01875rem]">
            {course.data?.description}
          </p>
        </div>
        <div
          className="flex flex-row justify-start md:justify-center md:px-8 w-[80%] gap-[0.88rem] mt-8 overflow-x-scroll scroll scroll-smooth scrollbar-hide"
          onMouseEnter={disableScroll}
          onMouseLeave={enableScroll}>
          <ScrollMenu
            wrapperClassName="w-full"
            LeftArrow={LeftArrow}
            RightArrow={RightArrow}
            onWheel={onWheel}>
            {(chapters.data || []).map((chapter, index) => (
              <ChapterNav
                key={index}
                chapter={chapter.order}
                isFinished={chapter.completed}
                setCurrentExercisePage={setCurrentLecturePage}
                currentChapter={currentChapter}
                setCurrentChapter={setCurrentChapter}
              />
            ))}
          </ScrollMenu>
        </div>
        <div className="flex relative w-full md:w-[59rem] h-[3rem] items-center justify-center mt-[4.3rem]">
          <div className="absolute w-full md:w-[59rem] h-[4px] bg-[#E5F1FF]" />
          <div className="w-full absolute flex flex-row items-start justify-start">
            <div className="flex flex-row w-full md:w-[59rem] items-center justify-start">
              <div
                className={"relative h-[4px] bg-[#01B574]"}
                style={{
                  width: lectures.data && `${progressBarLength(lectures.data)}%`
                }}></div>
              <Tooltip
                title={lectures.data && `${progressBarLength(lectures.data)}%`}
                arrow
                placement="top-start">
                <div className="w-5 h-5 flex items-center justify-center relative">
                  <div className="absolute w-10 h-10 rounded-full bg-[#01B574]/[0.12]"></div>
                  <div className="absolute w-5 h-5 rounded-full bg-[#01B574]"></div>
                </div>
              </Tooltip>
            </div>
          </div>
        </div>
        <div className="w-full mt-12 md:mt-20 justify-center flex md:flex-row">
          <button
            className={`h-[7.15119rem] hidden md:block ${
              currentLecturePage === 1 ? "cursor-default" : "cursor-pointer"
            }`}
            disabled={currentLecturePage === 1}
            onClick={previousLecturePage}>
            <img src="/assets/previous-button.svg" alt="button" />
          </button>
          <div className="relative w-full md:w-[85.5%]">
            <div className="w-full relative md:flex-row gap-6 z-[2] hidden md:flex">
              {displayedLectures?.map((lecture, index) => (
                <button
                  key={index}
                  className="disabled:opacity-50"
                  disabled={
                    currentChapter !== 1 && userInfoSignal.value?.subscriptionType === "FREE"
                  }
                  onClick={() => {
                    if (!signedInState.value.isSignedIn) {
                      navigate("/login");
                      return;
                    }

                    currentCourse.value = {
                      ...currentCourse.value,
                      chapter: {
                        ...currentCourse.value?.chapter,
                        lecture
                      }
                    };

                    navigate(`/lesson?id=${lecture.id}`);
                  }}>
                  <LectureNode
                    index={index}
                    data={lecture}
                    currentExercisePage={currentLecturePage}
                    isDesktop={true}
                  />
                </button>
              ))}
            </div>

            <div className="w-full relative flex flex-col gap-6 z-[2] md:hidden">
              {mobileDisplayedLectures?.map((lecture, index) => (
                <button
                  key={index}
                  className="disabled:opacity-50"
                  disabled={
                    currentChapter !== 1 && userInfoSignal.value?.subscriptionType === "FREE"
                  }
                  onClick={() => {
                    if (!signedInState.value.isSignedIn) {
                      navigate("/login");
                      return;
                    }

                    currentCourse.value = {
                      ...currentCourse.value,
                      chapter: {
                        ...currentCourse.value?.chapter,
                        lecture
                      }
                    };

                    navigate(`/lesson?id=${lecture.id}`);
                  }}>
                  <LectureNode
                    index={index}
                    data={lecture}
                    currentExercisePage={currentLecturePage}
                    isDesktop={false}
                  />
                </button>
              ))}
            </div>

            <div className="absolute top-14 gap-[7.65rem] ml-[9.03rem] z-[1] hidden md:flex">
              {displayedLectures?.slice(1, displayedLectures.length).map((lecture, index) => (
                <DesktopExerciseConnectBar key={index} record={lecture} />
              ))}
            </div>

            <div className="absolute flex flex-col top-[7.12rem] ml-[3.8rem] gap-[7.05rem] z-[1] md:hidden">
              {mobileDisplayedLectures
                ?.slice(1, mobileDisplayedLectures.length)
                .map((lecture, index) => (
                  <MobileExerciseConnectBar key={index} record={lecture} />
                ))}
            </div>
          </div>
          <button
            className={`h-[7.15119rem] hidden md:block ${
              currentLecturePage * 5 >= lectures.data?.length ? "cursor-default" : "cursor-pointer"
            }`}
            disabled={currentLecturePage * 5 >= lectures.data?.length}
            onClick={nextLecturePage}>
            <img src="/assets/next-button.svg" alt="button" />
          </button>
        </div>
        <div className="w-full flex justify-center md:hidden">
          <button
            onClick={() =>
              currentLecturePage - lectures.data?.length / 5 >= 0
                ? ""
                : setCurrentLecturePage(currentLecturePage + 1)
            }
            className={`flex flex-col items-center p-2 relative z-10 text-base text-[#5d5b5b] font-semibold leading-4 tracking-[-0.0125rem] ${
              currentLecturePage - lectures.data?.length / 5 >= 0 && "hidden"
            }`}>
            <span>Xem thêm</span>
            <img src="/assets/view-more.svg" alt="view more" className="w-4 h-4" />
          </button>
        </div>
      </div>
    </div>
  );
}

export default LessonSection;

function ChapterNav({
  chapter,
  isFinished,
  setCurrentExercisePage,
  currentChapter,
  setCurrentChapter
}) {
  function onClickAction() {
    setCurrentChapter(chapter);
    setCurrentExercisePage(1);
  }
  return (
    <div className="m-[0.5rem]">
      <button
        type="button"
        onClick={onClickAction}
        className={`w-[5.875rem] h-[2.125rem] rounded-[6.25rem] text-sm font-semibold leading-normal tracking-[0.04375rem] ${
          chapter === currentChapter
            ? "bg-[#01B574] text-white"
            : isFinished
              ? "text-white"
              : "bg-[#E5F1FF] text-[#6DA1DF]"
        }`}
        style={{
          background:
            isFinished && chapter !== currentChapter
              ? "linear-gradient(146deg, #6BCDEE 14.63%, #1CC0CB 86.96%)"
              : ""
        }}>
        {"Chapter "}
        {chapter}
      </button>
    </div>
  );
}

function LectureNode({ data }) {
  return (
    <div className="w-full flex flex-row md:flex-col items-center md:justify-center">
      <div className="flex relative w-[7.676rem] h-[7.15119rem] items-center justify-center">
        <img
          src={data.completed ? "/assets/finished-lesson-bg1.svg" : "/assets/lesson-bg1.svg"}
          alt="background"
          className="absolute z-0"
        />
        <img
          src={data.completed ? "/assets/finished-lesson-bg2.svg" : "/assets/lesson-bg2.svg"}
          alt="background"
          className="absolute z-[1]"
        />
        <div
          className="absolute z-[2] w-[4.52906rem] h-[4.52906rem] bg-white rounded-full"
          style={{
            filter: data.completed
              ? "drop-shadow(0px 10px 6px #16858C)"
              : "drop-shadow(0px 10px 6px #6DA1DF)"
          }}></div>
        <p className="absolute z-[3] text-[#2E2E2E] text-xl font-bold leading-normal">
          {data.order < 10 ? "0" + data.order : data.order}
        </p>
      </div>
      <div className="w-[60%] md:w-[10.4375rem] ml-4 md:ml-0 md:mt-4">
        <h3 className="w-full relative md:text-center text-[#2B3674] text-base font-bold leading-7 tracking-[-0.02rem]">
          {data.title}
        </h3>
        <div className="mt-2 w-full h-[3.5rem] md:h-[5rem] text-[#A3AED0] md:text-center text-[0.9375rem] font-medium leading-7 tracking-[-0.01875rem] overflow-hidden text-ellipsis">
          <p>{data.description}</p>
        </div>
      </div>
    </div>
  );
}

function DesktopExerciseConnectBar({ record }) {
  return (
    <div
      className={`w-[4.3rem] h-[0.125rem] ${record.completed ? "bg-[#5ECBE8]" : "bg-[#BFD4E8]"}`}
    />
  );
}

function MobileExerciseConnectBar({ record }) {
  return (
    <div
      className={`w-[0.125rem] h-[1.6rem] ${record.completed ? "bg-[#5ECBE8]" : "bg-[#BFD4E8]"}`}
    />
  );
}
