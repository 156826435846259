import "./flashcard.css";
import { useNavigate } from "react-router";
import { useEffect, useState } from "react";
import { signal } from "@preact/signals-react";

const isCorrect = signal(false);

function Flashcard({ signal, currentExercise, setCurrentExercise, isResult }) {
  const navigate = useNavigate();
  const [result, setResult] = useState("");
  const [isSubmited, setIsSubmited] = useState(isResult);

  useEffect(() => {
    let radidoBtns = document.querySelectorAll("input[name='radio']");
    let findSelected = () => {
      let selected = document.querySelector("input[name='radio']:checked").value;
      setResult(selected);
    };
    radidoBtns.forEach((radioBtn) => {
      radioBtn.addEventListener("change", findSelected);
    });
  });

  isCorrect.value = result === signal.value[currentExercise].content.answer;
  function onClickContinue() {
    if (currentExercise === signal.value.length - 1) {
      signal.value[currentExercise] = {
        ...signal.value[currentExercise],
        isFinished: true
      };
      signal.value[currentExercise] = {
        ...signal.value[currentExercise],
        userChoice: result
      };
      navigate("/result");
    } else {
      setResult("");
      setIsSubmited(false);
      setCurrentExercise(currentExercise + 1);
      signal.value[currentExercise] = {
        ...signal.value[currentExercise],
        isFinished: true
      };
      signal.value[currentExercise] = {
        ...signal.value[currentExercise],
        userChoice: result
      };
    }
  }

  return (
    <div className="w-full py-14 flex flex-col items-center">
      <h3 className="text-[#1B2559] text-lg font-semibold leading-normal mb-[2.3rem]">
        {signal.value[currentExercise].content.question}
      </h3>
      <audio
        src={signal.value[currentExercise].content.audioUrl}
        preload="none"
        controls
        controlsList="nodownload"
        className="w-[90%] md:w-[60%] h-[3.625rem] mb-8"></audio>
      {isSubmited ? (
        <>
          <div className="flex flex-col w-full items-center">
            <div className="flex flex-row flex-wrap items-center justify-center">
              {signal.value[currentExercise].content.choices.map((choice, index) => (
                <div key={index}>
                  <div className="w-[18.76056rem] h-[15.31475rem] relative m-8">
                    <img
                      src={
                        signal.value[currentExercise].content.answer === choice.text
                          ? "/assets/correct-check-icon.svg"
                          : (isResult ? signal.value[currentExercise].userChoice : result) ===
                              choice.text
                            ? "/assets/incorrect-check-icon.svg"
                            : "/assets/flashcard-check-icon.svg"
                      }
                      alt="check"
                      className={`absolute z-[3] -top-4 -right-4 w-[2.75rem] h-[2.75rem] rounded-full ${
                        signal.value[currentExercise].content.answer === choice.text
                          ? "block"
                          : (isResult ? signal.value[currentExercise].userChoice : result) ===
                              choice.text
                            ? "block"
                            : "hidden"
                      }`}
                    />
                    <img
                      src={choice.imgUrl}
                      alt="illustration"
                      className="w-[18.76056rem] h-[11.75956rem] relative object-fill z-[1]"
                    />
                    <div
                      className={`px-4 flex flex-row items-center w-[18.76056rem] h-[3.55519rem] text-base font-bold ${
                        signal.value[currentExercise].content.answer === choice.text
                          ? "bg-[#28B446]"
                          : (isResult ? signal.value[currentExercise].userChoice : result) ===
                              choice.text
                            ? "bg-[#E94444]"
                            : "bg-[#2947A9]"
                      }`}
                      style={{ color: "var(--neutral-white, #FFF)" }}>
                      <p>{choice.text}</p>
                      <p className="ml-auto">{index + 1}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div
              className={`w-[39rem] h-[0.0625rem] bg-[#A3AED0] mt-[0.2rem] ${
                isResult ? "w-[98%] block" : "hidden"
              }`}
            />
            <p
              className={`text-[#2B3674] text-lg font-bold leading-[118.519%] mb-8 ${
                isResult && "hidden"
              }`}>
              {isCorrect.value ? "Correct answer! 👏" : "Wrong answer"}
            </p>
            <button
              onClick={onClickContinue}
              className={`w-[17rem] h-[2.875rem] rounded-2xl bg-[#3D10EA] text-white text-sm font-semibold leading-normal tracking-[-0.0175rem] ${
                isResult && "hidden"
              }`}>
              Continue
            </button>
          </div>
        </>
      ) : (
        <>
          <div className="flex flex-col w-full items-center">
            <div className="flashcard-container">
              <div className="flashcard-radio-tile-group">
                {signal.value[currentExercise].content.choices.map((choice, index) => (
                  <div key={index}>
                    <div className="flashcard-input-container">
                      <input type="radio" name="radio" id="answer" value={choice.text} />
                      <div className="flashcard-radio-tile">
                        <img src="/assets/flashcard-check-icon.svg" alt="check" className="z-[3]" />
                        <label htmlFor="answer" className="label">
                          <img src={choice.imgUrl} alt="illustration" />
                          <div>
                            <p>{choice.text}</p>
                            <p className="ml-auto">{index + 1}</p>
                          </div>
                        </label>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <button
              onClick={() => setIsSubmited(true)}
              className="w-[17rem] h-[2.875rem] mt-7 rounded-2xl bg-[#3D10EA] text-white text-sm font-semibold leading-normal tracking-[-0.0175rem]">
              Submit
            </button>
          </div>
        </>
      )}
    </div>
  );
}

export default Flashcard;
