import { useState } from "react";
import SingleInputField from "../common/SingleInputField";
import UserService from "../../services/UserService";

function ChangePassword({ setStatus, handleClick }) {
  const [currentPassword, setCurrentPassword] = useState({
    value: "",
    visible: false
  });
  const [newPassword, setNewPassword] = useState({
    value: "",
    visible: false
  });
  const [confirmPassword, setConfirmPassword] = useState({
    value: "",
    visible: false
  });

  const passwordFields = [
    {
      id: "current-password",
      tailingComponent: (
        <img
          src={`${
            currentPassword.visible
              ? "../../../assets/visibility-off-icon.svg"
              : "../../../assets/visibility-icon.svg"
          }`}
          alt="password visibility icon"
          onClick={() => {
            setCurrentPassword({
              ...currentPassword,
              visible: !currentPassword.visible
            });
          }}
        />
      ),
      type: currentPassword.visible ? "text" : "password",
      value: currentPassword.value,
      label: "Mật khẩu hiện tại",
      onChange: (value) => setCurrentPassword({ ...currentPassword, value: value })
    },
    {
      id: "new-password",
      tailingComponent: (
        <img
          src={`${
            newPassword.visible
              ? "../../../assets/visibility-off-icon.svg"
              : "../../../assets/visibility-icon.svg"
          }`}
          alt="new password visibility icon"
          onClick={() => {
            setNewPassword({ ...newPassword, visible: !newPassword.visible });
          }}
        />
      ),
      type: newPassword.visible ? "text" : "password",
      value: newPassword.value,
      label: "Mật khẩu mới",
      onChange: (value) => setNewPassword({ ...newPassword, value: value })
    },
    {
      id: "confirm-password",
      tailingComponent: (
        <img
          src={`${
            confirmPassword.visible
              ? "../../../assets/visibility-off-icon.svg"
              : "../../../assets/visibility-icon.svg"
          }`}
          alt="confirm password visibility icon"
          onClick={() => {
            setConfirmPassword({
              ...confirmPassword,
              visible: !confirmPassword.visible
            });
          }}
        />
      ),
      type: confirmPassword.visible ? "text" : "password",
      value: confirmPassword.value,
      label: "Nhập lại mật khẩu mới",
      onChange: (value) => setConfirmPassword({ ...confirmPassword, value: value })
    }
  ];

  const changePassword = async () => {
    try {
      await UserService.changePassword({
        currentPassword: currentPassword.value,
        newPassword: newPassword.value,
        confirmPassword: confirmPassword.value
      });
      setStatus("success");
      handleClick();
    } catch (error) {
      console.error(error);
      setStatus("error");
      handleClick();
    }
  };

  return (
    <div className="w-full md:w-[65%] flex justify-center bg-white rounded-xl md:bg-none mt-12 md:mt-0 p-[10%] md:p-0">
      <div className="flex flex-col justify-center w-full md:ml-[20%]">
        <div className="w-full flex flex-col items-center gap-6">
          <h2 className="text-[#2B3674] text-[1.75rem] font-bold leading-normal">
            Thay đổi mật khẩu
          </h2>
          {passwordFields.map((field, index) => (
            <SingleInputField
              id={field.id}
              key={index}
              tailingComponent={field.tailingComponent}
              type={field.type}
              value={field.value}
              label={field.label}
              onChange={field.onChange}
            />
          ))}
          <button
            className="w-full py-6 md:p-0 md:w-[20.375rem] md:h-[3.75rem] bg-[#3D10EA] rounded-xl md:rounded-lg text-white text-base font-[Inter] font-semibold leading-4 tracking-[-0.03rem]"
            style={{
              background:
                "-webkit-linear-gradient(122deg, rgb(33, 155, 204) -20%,rgba(14, 9, 129, 0.75) 100%)"
            }}
            onClick={changePassword}>
            Xác nhận
          </button>
        </div>
      </div>
    </div>
  );
}

export default ChangePassword;
