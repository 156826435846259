function PremiumOffer() {
  return (
    <div className="w-full flex justify-center bg-[#F4F7FE] px-5">
      <div className="w-full h-[44vw] md:w-[68.125rem] md:h-[21.875rem]">
        <div className="w-full h-[44vw] flex relative md:w-[68.125rem] md:h-[21.875rem] rounded-[1.25rem]">
          <div>
            <img
              className="flex absolute rounded-[1.25rem] w-full h-[44vw] md:w-[68.125rem] md:h-[21.875rem] object-cover object-right md:object-center"
              src="../../../assets/premium-bg.png"
              alt="premium background"
            />
          </div>
          <div className="w-full font-[Inter] ml-6 my-4 md:ml-[4.44rem] md:my-[3.56rem] flex-col z-10">
            <div className="h-[26.5%] md:h-[37.5%] flex flex-col justify-center md:justify-start text-white text-[0.875rem] md:text-[2.125rem] font-bold md:font-medium leading-[2.625rem] tracking-[-0.0175rem] md:tracking-[-0.045rem] overflow-hidden text-ellipsis">
              <span className="w-[70%] md:w-[40%] flex md:block overflow-hidden text-ellipsis">
                <div className="md:inline-flex min-w-[2.2rem] h-[2.75rem] mr-1 flex items-start md:items-center justify-center">
                  <img
                    src="../../../assets/premium-icon.svg"
                    alt="premium icon"
                    className="w-9 h-9 md:w-10 md:h-10"
                  />
                </div>
                Lorem ipsum dolor amet ipsum dolor amet
              </span>
            </div>
            <p className="w-[50%] md:w-[40%] h-[38%] md:h-[35%] text-[#E3DAFF] text-base font-medium leading-7 tracking-[-0.02rem] mt-1 md:mt-[0.88rem] overflow-hidden text-ellipsis">
              Lorem ipsum dolor amet ipsum dolor amet ipsum dolor amet
            </p>
            <div className="flex gap-5 mt-2 md:mt-4 text-xs md:text-[0.875rem] font-medium leading-normal tracking-[-0.015rem] md:tracking-[-0.0175rem]">
              <button className="p-2 md:px-9 md:py-3 bg-[#01B574] rounded-3xl md:rounded-2xl text-white">
                Lorem ipsum
              </button>
              <button className="p-2 md:px-9 md:py-4 bg-white rounded-3xl md:rounded-2xl text-black">
                Lorem ipsum
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PremiumOffer;
