import { useEffect, useState } from "react";
import { History } from "../components/History";
import { TotalPoints } from "../components/TotalPoints";
import CustomPagination from "../components/CustomPagination";
import TransactionService from "../services/TransactionService";

function Money() {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [transactions, setTransactions] = useState([]);

  useEffect(() => {
    const getTransactions = async () => {
      try {
        const res = await TransactionService.getTransactions({
          page: currentPage,
          size: 15,
          sortBy: "createdAt",
          order: "desc"
        });
        setTransactions(res.data.result);
        setTotalPages(res.data.totalPages);
      } catch (error) {
        console.error(error);
      }
    };

    getTransactions();
  }, [currentPage]);

  return (
    <div className="w-full flex flex-col items-center bg-[#F4F7FE] font-[Inter]">
      <div className="w-[93.5%] md:w-[75.7%] md:bg-white relative flex flex-col rounded-xl mt-24 md:mt-48 mb-8">
        <div className="bg-[white] rounded-xl">
          <TotalPoints isStarPoint={false} />
          <div className="flex flex-row items-center gap-[0.4rem] ml-[10%] md:ml-[8.6%] mt-32 md:mt-48 mb-4">
            <img
              src="/assets/filter-icon.svg"
              alt="filter icon"
              className="w-6 md:w-[1.875rem] h-6 md:h-[1.875rem]"
            />
            <h2 className="text-[#1B2559] text-base md:text-[1.75rem] font-semibold leading-normal">
              Lịch sử
            </h2>
          </div>
          {/* <div className="w-full flex flex-col items-center justify-center">
            <Filter />
          </div> */}
        </div>
        <div className="w-full flex flex-col items-center justify-center mt-8 md:mt-0">
          <History history={transactions} isStarPoint={false} />
          <div className="mb-8">
            <CustomPagination setCurrentPage={setCurrentPage} count={totalPages} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Money;
