import axiosClient from "./AxiosClient";

class SubmissionService {
  static submitAnswers({ resultId, answers }) {
    return axiosClient.post("/submissions", {
      resultId,
      finishedAt: new Date().toISOString(),
      answers
    });
  }

  static getSubmissions({ examId, userId }) {
    return axiosClient.get(`/submissions?examId=${examId}&userId=${userId}`);
  }
}

export default SubmissionService;
