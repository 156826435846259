import { useEffect } from "react";
import CertificateService from "../../services/CertificateService";

const certificateFillTextMapping = {
  "cert1.png": {
    fontsize: 100,
    fontface: "Great Vibes",
    y: 700
  }
};

export default function Certificate({ verificationCode }) {
  useEffect(() => {
    async function draw() {
      const res = await CertificateService.verifyCertificate({ verificationCode });
      const certData = res.data;
      const certImageName = certData?.certificate.certImageName;
      const text = `${certData?.user.lastName} ${certData?.user.firstName}`;

      const canvas = document.getElementById("canvas");
      const ctx = canvas?.getContext("2d");
      let img = new Image();
      img.addEventListener("load", () => {
        canvas.width = img.width;
        canvas.height = img.height;
        const maxWidth = img.width;
        let fontsize = certificateFillTextMapping[certImageName].fontsize;
        const fontface = certificateFillTextMapping[certImageName].fontface;

        ctx?.drawImage(img, 0, 0);

        ctx.font = fontsize + "px " + certificateFillTextMapping[certImageName].fontface;
        while (ctx.measureText(text).width > maxWidth) {
          fontsize--;
          ctx.font = fontsize + "px " + fontface;
        }

        const textWidth = ctx.measureText(text).width;
        const x = (maxWidth - textWidth) / 2;
        const y = certificateFillTextMapping[certImageName].y;
        ctx?.fillText(text, x, y);
      });
      img.src = `/assets/certs/${certImageName}`;
    }

    draw();
  }, [verificationCode]);

  const download = () => {
    var canvas = document.getElementById("canvas");
    const image = canvas.toDataURL("image/png", 1.0).replace("image/png", "image/octet-stream");
    var link = document.createElement("a");
    link.download = "cert.png";
    link.href = image;
    link.click();
  };

  return (
    <div className="w-full flex items-center justify-center flex-col">
      <canvas id="canvas" className="w-full"></canvas>
      <button className="mt-4 rounded-xl border px-4 py-2 bg-green-500" onClick={download}>
        Download
      </button>
    </div>
  );
}
