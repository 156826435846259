import axiosClient from "./AxiosClient";

class CourseService {
  static getCourse({ id }) {
    return axiosClient.get(`/courses?id=${id}`);
  }

  static searchCourses({ page, size, sortBy, order, searchCriteriaList, dataOption }) {
    return axiosClient.post(
      `/courses/search?sortBy=${sortBy}&page=${page}&size=${size}&order=${order}`,
      {
        dataOption,
        searchCriteriaList
      }
    );
  }

  static createCourse({ title, description }) {
    return axiosClient.post("/courses", {
      title,
      description
    });
  }

  static updateCourse({ id, title, description }) {
    return axiosClient.put("/courses", {
      id,
      title,
      description
    });
  }

  static deleteCourse({ id }) {
    return axiosClient.delete(`/courses?id=${id}`);
  }

  static deleteCourses({ ids }) {
    return axiosClient.post("/courses/bulk-delete", {
      ids
    });
  }

  static getChapters({ courseId, page, size, sortBy, order }) {
    return axiosClient.get(
      `/courses/${courseId}/chapters?page=${page}&size=${size}&sortBy=${sortBy}&order=${order}`
    );
  }
}

export default CourseService;
