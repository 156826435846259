import { faFacebook, faInstagram, faTwitter } from "@fortawesome/free-brands-svg-icons";
import { faWifi } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function Footer() {
  return (
    <div className="hidden md:block">
      <div className="w-full h-[3.9375rem] flex items-center bg-[#193599] relative">
        <div className="w-full px-[7.5rem] flex text-white">
          <div className="flex gap-[3rem]">
            <FontAwesomeIcon icon={faTwitter} size="xl" />
            <FontAwesomeIcon icon={faFacebook} size="xl" />
            <div className="rotate-[45deg] w-[1.25738rem]">
              <FontAwesomeIcon icon={faWifi} size="xl" />
            </div>
            <FontAwesomeIcon icon={faInstagram} size="xl" />
          </div>
          <p className="font-[Roboto] ml-auto mr-[8rem]">
            COPYRIGHT EXAM 2023 -{" "}
            <a className="underline" href="/">
              TERMS & CONDITIONS
            </a>{" "}
            <a className="underline" href="/">
              PRIVACY POLICY
            </a>
          </p>
        </div>
      </div>
    </div>
  );
}

export default Footer;
