import { Outlet } from "react-router";
import "./welcome-page.css";

function WelcomePage() {
  return (
    <div className="flex items-center justify-center md:min-h-screen">
      <div className="w-screen md:w-[90rem] md:h-[58.375rem] bg-[#E0F2FF] flex flex-col md:flex-row items-center justify-center">
        <div className="relative z-0">
          <img
            src="../../../assets/login-bg.png"
            alt="german-learning-app"
            className="w-full md:w-[48.375rem] h-[30vh] md:h-[58.375rem] object-cover object-center"
          />
          <div className="mobile-gradient-box" />
        </div>
        <div className="gradient-box" />
        <div className="w-full md:w-[42rem] h-full flex items-center justify-center">
          <Outlet />
        </div>
      </div>
    </div>
  );
}

export default WelcomePage;
