const pointsToRank = (point) => {
  if (point < 1) {
    return "Bronze";
  } else if (point < 2) {
    return "Silver";
  } else if (point < 3) {
    return "Gold";
  } else if (point < 4) {
    return "Platinum";
  } else if (point < 5) {
    return "Diamond";
  } else if (point < 6) {
    return "Master";
  } else {
    return "Challenger";
  }
};

const translateRank = (rank) => {
  switch (rank) {
    case "Bronze":
      return "Đồng";
    case "Silver":
      return "Bạc";
    case "Gold":
      return "Vàng";
    case "Platinum":
      return "Bạch Kim";
    case "Diamond":
      return "Kim Cương";
    case "Master":
      return "Cao Thủ";
    case "Challenger":
      return "Thách Đấu";
    default:
      return "Đồng";
  }
};

export default function Rank({ point }) {
  const rank = pointsToRank(point);
  return (
    <div>
      <img
        width={30}
        height={30}
        title={translateRank(rank)}
        src={`/assets/ranks/${rank.toLowerCase()}.png`}
        alt={rank}></img>
    </div>
  );
}
