import { useEffect, useRef, useState } from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { EditorExpandedQuestion } from "./EditorExpandedQuestion";
import { EditorCollapsedQuestion } from "./EditorCollapsedQuestion";
import { motion } from "framer-motion";

export function EditorSortableQuestion({ id, ariaLabel, sectionIndex, index, dragMode, activeId }) {
  const [isExpanded, setIsExpanded] = useState(false);
  const [height, setHeight] = useState(0);
  const collapsedRef = useRef(null);
  const expandedRef = useRef(null);
  const [, setTriggerCalculateHeight] = useState(false);

  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  };

  useEffect(() => {
    const maxHeight =
      Math.max(
        collapsedRef.current?.getBoundingClientRect().height,
        expandedRef.current?.getBoundingClientRect().height
      ) ?? 0;

    setHeight(maxHeight);
  });

  return (
    <div
      tabIndex={0}
      aria-label={ariaLabel}
      ref={setNodeRef}
      {...attributes}
      onClick={() => {
        setIsExpanded(true);
      }}
      className={`mb-7 overflow-hidden hover:bg-gray-200 px-8 pt-2 pb-6 ${
        activeId === id ? "invisible" : "visible"
      } ${dragMode ? "border-2 border-gray-50 shadow-gray-200 shadow-lg pt-0" : ""}`}
      style={{
        ...style,
        transition: "height 0.2s ease-out"
      }}>
      <motion.div
        animate={{
          height: height
        }}
        transition={{
          type: "tween",
          ease: "linear",
          duration: 0.2
        }}>
        <div ref={collapsedRef} className={`${isExpanded ? "hidden" : "block"}`}>
          <EditorCollapsedQuestion
            id={id}
            ariaLabel={ariaLabel}
            sectionIndex={sectionIndex}
            index={index}
            dragMode={dragMode}
            activeId={activeId}
            sortableListeners={listeners}
          />
        </div>
        <div ref={expandedRef} className={`${isExpanded ? "block" : "hidden"}`}>
          <EditorExpandedQuestion
            ariaLabel={ariaLabel}
            sectionIndex={sectionIndex}
            index={index}
            setIsExpanded={setIsExpanded}
            setTriggerCalculateHeight={setTriggerCalculateHeight}
          />
        </div>
      </motion.div>
    </div>
  );
}
