function ExerciseBar({ data }) {
  return (
    <div className="relative flex items-center justify-center">
      <div className="flex w-max overflow-x-scroll scroll scroll-smooth scrollbar-hide justify-start">
        {data.map((value, index) => (
          <Exercise
            key={index}
            index={index}
            lastIndex={data.length - 1}
            isFinished={value.isFinished}
          />
        ))}
      </div>
    </div>
  );
}

export default ExerciseBar;

function Exercise({ index, lastIndex, isFinished }) {
  return (
    <>
      <div className="flex flex-row min-w-[2.625rem]">
        <button
          className={`w-[1.8125rem] h-[1.8125rem] rounded-full font-['Open_Sans'] flex items-center justify-center text-[0.9375rem] font-semibold leading-normal ${
            isFinished ? "bg-[#28B446] text-white" : "bg-[#E5F1FF] text-[#6DA1DF]"
          }`}>
          <div>
            <p>{index + 1}</p>
          </div>
        </button>
        <img
          src={isFinished ? "/assets/finished-exercise-bar.svg" : "/assets/exercise-bar.svg"}
          alt="connect bar"
          className={`${index === lastIndex ? "hidden" : ""}`}
        />
      </div>
    </>
  );
}
