import Leaderboard from "../components/leaderboard/Leaderboard";
import PremiumOffer from "../components/PremiumOffer";
import LessonSection from "../components/LessonSection";

function HomePage() {
  return (
    <div className="w-full flex flex-col relative items-center justify-center bg-[#F4F7FE] font-[Inter]">
      <PremiumOffer />
      <LessonSection />
      <div className="w-full mt-5">
        <Leaderboard />
      </div>
    </div>
  );
}

export default HomePage;
