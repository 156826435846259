import { jwtDecode } from "jwt-decode";

class Util {
  static isTokenValid(token) {
    if (token) {
      const decodedToken = jwtDecode(token);
      if (!decodedToken) {
        return false;
      }
      const now = new Date();
      if (now > new Date(decodedToken.exp * 1000)) {
        return false;
      }
      return true;
    }
    return false;
  }

  static getClaims(token) {
    if (token) {
      const decodedToken = jwtDecode(token);
      if (!decodedToken || this.isTokenValid(token) === false) {
        return null;
      }
      return decodedToken;
    }
    return null;
  }

  static getClaim(token, claimName) {
    if (token) {
      const decodedToken = jwtDecode(token);
      if (!decodedToken || this.isTokenValid(token) === false) {
        return null;
      }
      return decodedToken[claimName];
    }
    return null;
  }

  static stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }

  static stringAvatar(name) {
    if (!name) {
      return "";
    }

    return {
      sx: {
        bgcolor: Util.stringToColor(name)
      },
      children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`
    };
  }

  static hasAuthority(token, authority) {
    const authorities = this.getClaim(token, "authorities");

    if (authorities) {
      return authorities.some((item) => item.authority === authority);
    }
  }

  static copyToClipboard(value) {
    navigator.clipboard.writeText(value);
  }
}

export default Util;
