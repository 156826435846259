import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import CookieService from "../services/CookieService";
import UserService from "../services/UserService";
import { signedInState } from "../signals";
import { userInfoSignal } from "../signals/UserInfoSignal";
import Util from "../utils/Util";
import { CustomAvatar } from "./CustomAvatar";
import Rank from "./Rank";

function NavBar() {
  useEffect(() => {
    signedInState.value = {
      isSignedIn: Util.isTokenValid(CookieService.getCookie("token"))
    };
  }, []);

  return (
    <div className="flex justify-between bg-[#F4F7FE] mx-[5%] py-6 md:py-10">
      <Link to="/" className="flex items-center justify-center">
        <div className="flex items-center justify-center gap-2">
          <div className="w-[2.5625rem] md:w-[3.1875rem] h-[2.5625rem] md:h-[3.1875rem]">
            <img src="/assets/logo.png" alt="bosu icon" />
          </div>
          <p className="text-[#2B3674] text-[2.125rem] font-bold leading-[2.625rem] tracking-[-0.0425rem] hidden md:block font-sans">
            Ôn Thi Tiếng Đức
          </p>
        </div>
      </Link>
      {signedInState.value.isSignedIn ? <UserBar /> : <LoginButton />}
    </div>
  );
}

export default NavBar;

function LoginButton() {
  return (
    <div className="md:mr-[10%] h-[3.8125rem] flex gap-5 items-center justify-center">
      <Link
        className="w-24 h-8 md:w-32 md:h-[2.5625rem] rounded-[1.875rem] text-[#3D10EA] bg-white border border-[#3D10EA] hover:text-white hover:bg-[#006eff] hover:shadow-[0px_7px_17px_0px_rgba(9,87,203,0.32) flex items-center justify-center  font-[Inter] text-base font-bold leading-4 tracking-[-0.03rem]"
        to={"/register"}>
        Đăng ký
      </Link>
      <Link
        className="w-24 h-8 md:w-32 md:h-[2.5625rem] rounded-[1.875rem] bg-[#3D10EA] hover:bg-[#006eff] flex items-center justify-center text-white font-[Inter] text-base font-bold leading-4 tracking-[-0.03rem]"
        to={"/login"}>
        Đăng nhập
      </Link>
    </div>
  );
}

function UserBar() {
  const navigate = useNavigate();
  const [point, setPoint] = useState(0);

  useEffect(() => {
    const getMe = async () => {
      if (signedInState.value.isSignedIn) {
        try {
          const res = await UserService.getMe();
          userInfoSignal.value = {
            ...userInfoSignal.value,
            ...res.data
          };
          setPoint(res.data.point);
        } catch (error) {
          console.error(error);
        }
      }
    };

    getMe();
  }, []);

  return (
    <div className="md:w-[37.375rem] md:h-[3.8125rem] rounded-[1.875rem] md:bg-white flex flex-row items-center justify-evenly gap-2">
      <div className="md:w-[13.9375rem] md:h-[2.5625rem] rounded-[1.875rem] bg-white md:bg-[#F4F7FE] font-[Inter] font-normal text-sm tracking-[-0.0175rem] flex flex-row">
        <div className="flex justify-center md:justify-start items-center w-[1.8125rem] h-[1.8125rem] md:w-10 md:h-10">
          <img src="../../../assets/search-icon.svg" alt="search icon" className="md:ml-5" />
        </div>
        <input
          type="text"
          placeholder="Search"
          className="bg-white md:bg-[#F4F7FE] rounded-[1.875rem] focus:outline-none w-16 md:grow md:min-w-[3rem] md:h-[2.5625rem] px-1 md:px-3"
        />
      </div>
      <button className="w-[1.8125rem] h-[1.8125rem] md:w-[7.3125rem] md:h-[2.5625rem] bg-[#F4F7FE] rounded-[2.5625rem] flex flex-row items-center">
        <div className="bg-white w-[1.8125rem] h-[1.8125rem] rounded-full flex items-center justify-center md:ml-[0.38rem] text-[#283671] text-sm font-[Inter] font-medium tracking-[-0.0175rem]">
          <img src="/assets/star-point-icon.svg" alt="star point icon" />
        </div>
        <p className="ml-2 hidden md:block">{point}</p>
        <div className="ml-auto mr-2 cursor-default">
          <Rank point={point} />
        </div>
      </button>
      <button
        onClick={() => navigate("/transaction-history")}
        className="w-[1.8125rem] h-[1.8125rem] md:w-[7.3125rem] md:h-[2.5625rem] bg-[#F4F7FE] rounded-[2.5625rem] flex flex-row items-center">
        <div className="bg-white w-[1.8125rem] h-[1.8125rem] rounded-full flex items-center justify-center md:ml-[0.38rem] text-[#283671] text-sm font-[Inter] font-medium tracking-[-0.0175rem]">
          <img src="/assets/coin.svg" alt="coin" />
        </div>
        <p className="ml-2 hidden md:block">{userInfoSignal.value?.sumTransaction}</p>
      </button>
      {/* <Notification /> */}
      <CustomAvatar />
    </div>
  );
}
