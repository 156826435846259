import axiosClient from "./AxiosClient";

class CertificateService {
  static getAllCertificates() {
    return axiosClient.get("/certificates/all");
  }

  static addCertificate({ userId, certificateId }) {
    return axiosClient.post("/certificates", {
      userId,
      certificateId
    });
  }

  static verifyCertificate({ verificationCode }) {
    return axiosClient.get(`/certificates/verify/${verificationCode}`);
  }
}

export default CertificateService;
