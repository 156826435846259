import { useState } from "react";
import { DndContext, DragOverlay, closestCenter } from "@dnd-kit/core";
import { SortableContext, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { restrictToFirstScrollableAncestor, restrictToVerticalAxis } from "@dnd-kit/modifiers";
import { exam, moveQuestion } from "../../../signals/ExamSignal";
import { EditorSortableQuestion } from "./EditorSortableQuestion";

export function EditorSortableQuestionsContainer({ sectionIndex }) {
  const items = exam.value.sections[sectionIndex].questions;
  const ids = items.map((_, i) => `question ${sectionIndex}-${i}`);
  const [dragMode, setDragMode] = useState(false);
  const [activeId, setActiveId] = useState(null);

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      moveQuestion({
        sectionIndex,
        oldIndex: ids.findIndex((i) => i === active.id),
        newIndex: ids.findIndex((i) => i === over.id)
      });
    }
    setDragMode(false);
    setActiveId(null);
  };

  const handleDragStart = (event) => {
    setDragMode(true);
    setActiveId(event.active.id);
  };

  return (
    <DndContext
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
      modifiers={[restrictToVerticalAxis, restrictToFirstScrollableAncestor]}
      onDragStart={handleDragStart}>
      <SortableContext items={ids} strategy={verticalListSortingStrategy}>
        {items.map((_, i) => (
          <EditorSortableQuestion
            key={ids[i]}
            id={ids[i]}
            ariaLabel={ids[i]}
            sectionIndex={sectionIndex}
            index={i}
            dragMode={dragMode}
            activeId={activeId}
          />
        ))}
      </SortableContext>
      <DragOverlay>
        {activeId ? (
          <div className={"px-8 pt-7 pb-5 border-2 border-gray-50 shadow-gray-200 shadow-lg"}>
            <div className="flex items-center gap-2">
              <span>{ids.findIndex((i) => i === activeId) + 1}.</span>
              <h4>{items[ids.findIndex((i) => i === activeId)].title}</h4>
            </div>
          </div>
        ) : null}
      </DragOverlay>
    </DndContext>
  );
}
