import { Link, useNavigate, useSearchParams } from "react-router-dom";
import UserService from "../../services/UserService";
import CookieService from "../../services/CookieService";
import { Fragment, useEffect, useState } from "react";
import SingleInputField from "../common/SingleInputField";
import { globalError } from "../../signals";
import { Typography } from "@mui/material";
import { SERVER_URL } from "../../services/AxiosClient";

const errorFields = {
  email: "email",
  fullName: "first name",
  password: "invalid password",
  "Confirm password": "confirm password"
};

function Register() {
  const navigate = useNavigate();
  let [searchParams] = useSearchParams();

  useEffect(() => {
    setReferrerEmail(searchParams.get("referrer"));
  }, [searchParams.get("referrer")]);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState({
    value: "",
    visible: false
  });
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastname] = useState("");
  const [confirmPassword, setConfirmPassword] = useState({
    value: "",
    visible: false
  });
  const [referrerEmail, setReferrerEmail] = useState("");
  useEffect(() => {
    globalError.value = undefined;
  }, []);

  const registerFields = [
    {
      component: SingleInputField,
      leadingComponent: <img src="/assets/email-icon.svg" alt="email icon" />,
      name: "email",
      type: "text",
      value: email,
      label: "Địa chỉ email",
      onChange: (value) => setEmail(value)
    },
    {
      component: SingleInputField,
      leadingComponent: <img src="/assets/user-icon.svg" alt="fullname icon" />,
      name: "fullName",
      type: "text",
      value: `${lastName} ${firstName}`.trim(),
      label: "Họ và tên",
      onChange: (value) => {
        const ln = value.split(" ")[0].trim();
        const fn = value.slice(ln.length + 1).trim();

        setFirstName(fn);
        setLastname(ln);
      }
    },
    {
      component: SingleInputField,
      name: "password",
      leadingComponent: <img src="/assets/password-icon.svg" alt="password icon" />,
      tailingComponent: (
        <img
          onClick={() => setPassword({ ...password, visible: !password.visible })}
          src={`${
            password.visible
              ? "../../../assets/visibility-off-icon.svg"
              : "../../../assets/visibility-icon.svg"
          }`}
          alt="password visibility icon"
        />
      ),
      type: password.visible ? "text" : "password",
      value: password.value,
      label: "Mật khẩu",
      onChange: (value) => setPassword({ ...password, value: value })
    },
    {
      component: SingleInputField,
      name: "Confirm password",
      leadingComponent: <img src="/assets/password-icon.svg" alt="confirm password icon" />,
      tailingComponent: (
        <img
          onClick={() =>
            setConfirmPassword({
              ...confirmPassword,
              visible: !confirmPassword.visible
            })
          }
          src={`${
            confirmPassword.visible
              ? "../../../assets/visibility-off-icon.svg"
              : "../../../assets/visibility-icon.svg"
          }`}
          alt="confirm password visibility icon"
        />
      ),
      type: confirmPassword.visible ? "text" : "password",
      value: confirmPassword.value,
      label: "Nhập lại mật khẩu",
      onChange: (value) => setConfirmPassword({ ...confirmPassword, value: value })
    },
    {
      component: SingleInputField,
      leadingComponent: <img src="/assets/referral-code-icon.svg" alt="referral code icon" />,
      type: "text",
      value: referrerEmail,
      label: "Email người giới thiệu",
      onChange: (value) => setReferrerEmail(value)
    }
  ];

  const getErrorMsg = (error, fieldName) => {
    const { message } = error;
    if (!message) {
      return;
    }
    const fields = fieldName?.split(",")?.map((x) => x.trim()) || [];
    return [
      ...new Set(
        fields
          .filter((field) => message.toLowerCase().includes(field))
          .map((field) => {
            switch (field) {
              case "email":
                if (message.includes("already exists")) {
                  return "Email đã tồn tại";
                }
                return "Địa chỉ email không hợp lệ";
              case "first name":
                return "Yêu cầu nhập đầy đủ họ tên";
              case "last name":
                return "Yêu cầu nhập đầy đủ họ tên";
              case "invalid password":
                return "Mật khẩu không hợp lệ";
              case "confirm password":
                if (message.includes("required")) {
                  return "Yêu cầu nhập mật khẩu xác nhận";
                }
                return "Mật khẩu xác nhận không khớp";
              default:
                return "";
            }
          })
      )
    ].join(", ");
  };

  const register = async () => {
    try {
      const res = await UserService.register({
        email,
        password: password.value,
        firstName,
        lastName,
        confirmPassword: confirmPassword.value,
        referrerEmail
      });

      CookieService.setCookie("token", res.data.token, 1);
      navigate("/");
    } catch (error) {
      globalError.value = error.response.data;
    }
  };

  return (
    <div className="flex flex-col w-max relative items-center justify-center bg-white rounded-3xl pt-4">
      <div className="w-full absolute -top-20 md:top-auto z-20 md:relative md:mb-8 flex items-center justify-center font-[Inter] text-[#2B3674] text-lg text-center font-semibold leading-8 tracking-[-0.03375rem] uppercase">
        <h1>Tạo tài khoản mới</h1>
      </div>
      <div className="w-full flex flex-col items-center gap-4 px-8">
        {registerFields.map((field, index) => {
          const {
            component: Component,
            name,
            type,
            labels,
            values,
            onChangeFunctions,
            label,
            value,
            onChange
          } = field;
          return (
            <Fragment key={index}>
              <Component
                leadingComponent={field.leadingComponent}
                tailingComponent={field.tailingComponent}
                type={type}
                values={values}
                labels={labels}
                value={value}
                label={label}
                onChangeFunctions={onChangeFunctions}
                onChange={onChange}></Component>
              {globalError.value && getErrorMsg(globalError.value, errorFields[name]) ? (
                <Typography
                  color="error"
                  sx={{
                    alignSelf: "start",
                    fontSize: "small",
                    fontStyle: "italic",
                    paddingLeft: "0.25rem",
                    marginTop: "-10px"
                  }}>
                  {getErrorMsg(globalError.value, errorFields[name])}
                </Typography>
              ) : null}
            </Fragment>
          );
        })}
      </div>
      <div className="w-full flex items-center gap-4 pl-[10%] md:px-14 mt-[0.87rem]">
        <input
          className="w-5 h-5 rounded-[0.375rem] appearance-none bg-[#979797]/[0.1] cursor-pointer flex items-center justify-center focus:outline-none checked:bg-[#81BBFF]/30 after:font-['Font_Awesome_6_Free'] after:content-['\f00c'] after:font-black text-xs after:text-[#41579B] after:hidden checked:after:block"
          type="checkbox"
          name="check"
          id="check"
        />
        <label
          htmlFor="check"
          className="text-[#2B3674] text-base font-[Inter] font-normal leading-[1.375rem] tracking-[-0.03rem] cursor-pointer">
          Ghi nhớ đăng nhập
        </label>
      </div>
      <button
        className="w-[23.375rem] h-[3.75rem] my-5 bg-[#3D10EA] rounded-lg text-white text-base font-[Inter] font-semibold leading-4 tracking-[-0.03rem]"
        onClick={register}>
        Tạo tài khoản
      </button>
      <div className="flex flex-row items-center gap-2 ">
        <div className="w-[8.375rem] h-[1px] bg-[#41579B]/20"></div>
        <span className="text-[#41579B]/50 text-sm font-[Inter] font-medium leading-[1.375rem] tracking-[-0.02625rem]">
          Hoặc đăng nhập với
        </span>
        <div className="w-[8.375rem] h-[1px] bg-[#41579B]/20"></div>
      </div>
      <div className="flex flex-row gap-3 mt-5">
        <Link
          to={`${SERVER_URL}/oauth2/authorization/google`}
          className="w-[11.3125rem] h-[3.125rem] rounded-xl bg-white flex flex-row items-center">
          <div className="h-8 ml-8 mr-3 items-center justify-center">
            <img src="../../../assets/google-icon.svg" alt="google icon" />
          </div>
          <p className="text-[#2B3674] text-base font-[Inter] font-semibold leading-4 tracking-[-0.03rem]">
            Google
          </p>
        </Link>
        <Link
          to={`${SERVER_URL}/oauth2/authorization/facebook`}
          className="w-[11.3125rem] h-[3.125rem] rounded-xl bg-white flex flex-row items-center">
          <div className="h-10 ml-6 mr-1 items-center justify-center">
            <img src="../../../assets/facebook-icon.svg" alt="facebook icon" />
          </div>
          <p className="text-[#2B3674] text-base font-[Inter] font-semibold leading-4 tracking-[-0.03rem]">
            Facebook
          </p>
        </Link>
      </div>
      <div className="w-[23.375rem] mt-[1.6rem] mb-12 flex justify-center">
        <p className="text-[#2B3674] text-sm font-[Inter] font-medium leading-[1.375rem] tracking-[-0.02625rem]">
          Bạn đã có tài khoản?{" "}
          <Link to="/login" className="font-bold">
            Đăng nhập
          </Link>
        </p>
      </div>
    </div>
  );
}

export default Register;
