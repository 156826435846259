import axiosClient from "./AxiosClient";

class ChapterService {
  static getChapter({ id }) {
    return axiosClient.get(`/chapters?id=${id}`);
  }

  static createChapter({ title, description, courseId, order }) {
    return axiosClient.post("/chapters", {
      title,
      description,
      courseId,
      order
    });
  }

  static updateChapter({ id, title, description, order }) {
    return axiosClient.put("/chapters", {
      id,
      title,
      description,
      order
    });
  }

  static deleteChapter({ id }) {
    return axiosClient.delete(`/chapters?id=${id}`);
  }

  static deleteChapters({ ids }) {
    return axiosClient.post("/chapters/bulk-delete", {
      ids
    });
  }

  static getLectures({ chapterId, page, size, sortBy, order }) {
    return axiosClient.get(
      `/chapters/${chapterId}/lectures?page=${page}&size=${size}&sortBy=${sortBy}&order=${order}`
    );
  }
}

export default ChapterService;
