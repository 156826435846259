import { examData } from "./Lesson";
import Video from "../components/exercise/video/Video";
import Flashcard from "../components/exercise/flashcard/Flashcard";
import CircularProgressBar from "../components/CircularProgressBar";
import Conversation from "../components/exercise/conversation/Conversation";
import SingleChoice from "../components/exercise/single-choice/SingleChoice";
import { useEffect, useState } from "react";
import ResultService from "../services/ResultService";
import { Link, useSearchParams } from "react-router-dom";
import ExamService from "../services/ExamService";
import { signal } from "@preact/signals-react";
import SubmissionService from "../services/SubmissionService";
import Certificate from "../components/common/Certificate";

const resultSignal = signal();
const submissionsSignal = signal([]);

function Result() {
  let [searchParams] = useSearchParams();
  useEffect(() => {
    const initData = async () => {
      try {
        const res1 = await ResultService.getResult({ id: searchParams.get("id") });
        resultSignal.value = res1.data;
        const examId = res1.data.examId;

        const res2 = await ExamService.getExam({
          id: examId
        });
        let exam = res2.data;
        if (exam.sections.length > 0) {
          exam = {
            ...exam,
            sections: exam.sections.map((section) => ({
              ...section,
              questions: section.questions.map((question) => ({
                ...question,
                isFinished: false,
                userChoice: null,
                content: JSON.parse(question.content)
              }))
            }))
          };
        }
        examData.value = exam;

        const res3 = await SubmissionService.getSubmissions({ examId, userId: res1.data.userId });
        submissionsSignal.value = res3.data;
      } catch (e) {
        console.debug(e);
      }
    };
    if (searchParams.get("id")) {
      initData();
    }
  }, [searchParams]);

  const questions = examData.value?.sections[0]?.questions || [];

  const getSubmittedChoice = (index) => {
    const submission = submissionsSignal.value.find(
      (submission) => submission.questionId === questions[index].id
    );
    return submission?.answer;
  };

  return (
    <div className="w-full h-full px-4 md:p-0 flex flex-col relative items-center justify-center bg-[#F4F7FE] font-[Inter]">
      <div className="w-full md:w-[80%] md:bg-white rounded-xl mb-14">
        <ResultDetail />
        {resultSignal.value?.certificateCode && (
          <div className="w-[50rem] mx-auto">
            <Certificate verificationCode={resultSignal.value.certificateCode}></Certificate>
          </div>
        )}
        <div className="w-full bg-white md:bg-none flex flex-col items-center gap-4 mt-8 md:mt-0">
          {questions.map((data, index) => (
            <div key={index} className="w-full">
              {data.type === "VIDEO_SINGLE_CHOICE" && (
                <Video
                  key={index}
                  signal={examData}
                  currentExercise={index}
                  isResult={true}
                  submittedChoice={getSubmittedChoice(index)}
                />
              )}
              {data.type === 2 && (
                <Flashcard key={index} signal={examData} currentExercise={index} isResult={true} />
              )}
              {data.type === "CONVERSATION_SINGLE_CHOICE" && (
                <Conversation
                  key={index}
                  signal={examData}
                  currentExercise={index}
                  isResult={true}
                  submittedChoice={getSubmittedChoice(index)}
                />
              )}
              {data.type === "SINGLE_CHOICE" && (
                <SingleChoice
                  key={index}
                  signal={examData}
                  currentExercise={index}
                  isResult={true}
                  submittedChoice={getSubmittedChoice(index)}
                />
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Result;

function ResultDetail() {
  const [result, setResult] = useState({});

  useEffect(() => {
    setResult({
      // timeTaken: new Date(res.data.finishedAt) - new Date(res.data.startedAt),
      totalQuizzes: resultSignal.value?.totalQuestions,
      score: Math.round((resultSignal.value?.score / resultSignal.value?.totalQuestions) * 100),
      correct: resultSignal.value?.score,
      wrong: resultSignal.value?.totalQuestions - resultSignal.value?.score
    });
  }, [resultSignal.value]);

  const iconMap = {
    correct: "/assets/correct.svg",
    wrong: "/assets/wrong.svg",
    // timeTaken: "/assets/time-taken.svg",
    totalQuizzes: "/assets/total-quizzes.svg",
    score: "/assets/score.svg"
  };
  return (
    <div className="w-full relative z-10 bg-white md:bg-none md:shadow-[0px_40px_58px_-20px_rgba(112,144,176,0.12)] md:px-[5%] md:py-16 pb-8 flex flex-col md:flex-row items-center">
      <div className="w-full md:w-[12.5rem] bg-white md:bg-none rounded-xl flex flex-col items-center py-6 md:py-0 gap-6 mt-7 md:mt-0 shadow-[0px_40px_58px_-20px_rgba(112,144,176,0.12)] md:shadow-none">
        <CircularProgressBar currentValue={result.score} total={100} />
        <Link
          to="/"
          className="w-[8.75rem] h-[2.875rem] mb-3 md:mb-0 bg-[#01B574] rounded-2xl text-white text-sm font-medium leading-normal tracking-[-0.0175rem] flex items-center justify-center">
          Trở về trang chủ
        </Link>
      </div>
      <div
        className="hidden md:block w-[0.0625rem] h-[16.5rem] mx-12"
        style={{ background: "rgba(0, 93, 255, 0.12)" }}
      />
      <div className="grow flex justify-center pl-[3%] md:px-0 mt-8 md:mt-0">
        <div className="flex flex-row flex-wrap gap-6 md:w-[80%] md:gap-16">
          {Object.keys(result).map((key, index) => (
            <div
              key={index}
              className="min-w-[9.02rem] md:min-w-[10.5rem] flex flex-row items-center ">
              <img src={iconMap[key]} alt="icon" className="w-[4.375rem] h-[4.375rem]" />
              <div className="flex flex-col gap-2">
                <span className="text-[#1B2559] text-xs md:text-base font-semibold leading-normal">
                  {key}
                </span>
                <span className="text-[#7D8BB5] text-xs md:text-[0.9375rem] font-['DM_Sans'] font-normal leading-[1.3125rem] md:leading-[1.64063rem] capitalize">
                  {result[key]}
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
