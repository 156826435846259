import { useNavigate } from "react-router";
import { CustomAvatar } from "../CustomAvatar";
import { useEffect } from "react";
import { signedInState } from "../../signals";
import UserService from "../../services/UserService";
import { userInfoSignal } from "../../signals/UserInfoSignal";

function EditorNavBar() {
  const navigate = useNavigate();

  useEffect(() => {
    const getMe = async () => {
      if (signedInState.value.isSignedIn) {
        try {
          const res = await UserService.getMe();
          userInfoSignal.value = {
            ...userInfoSignal.value,
            ...res.data
          };
        } catch (error) {
          console.error(error);
        }
      }
    };

    getMe();
  }, []);

  return (
    <nav className="w-full py-2 bg-[#004EC3] flex items-center justify-between px-16 cursor-pointer">
      <div
        className="flex items-center justify-center gap-4"
        onClick={() => {
          navigate("/");
        }}>
        <div className="w-[2.5625rem] md:w-[3.1875rem] h-[2.5625rem] md:h-[3.1875rem]">
          <img src="/assets/logo.png" alt="logo" />
        </div>
        <p className="text-white">Editor</p>
      </div>
      <div className="">
        <CustomAvatar />
      </div>
    </nav>
  );
}

export default EditorNavBar;
