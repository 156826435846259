import { Link } from "react-router-dom";
import SingleInputField from "../common/SingleInputField";
import { useState } from "react";

function LinkResetPassword() {
  const [email, setEmail] = useState("");
  return (
    <div className="w-full px-4 md:px-0 relative flex flex-col items-center justify-center">
      <div className="w-[21.875rem] -top-10 md:top-auto absolute md:relative font-[Inter] text-[#2B3674] text-lg text-center font-semibold leading-8 tracking-[-0.03375rem] uppercase">
        <h1>Đặt lại mật khẩu</h1>
      </div>
      <div className="px-4 w-full md:w-[23.375rem] md:h-60 rounded-xl bg-white mt-8 flex flex-col items-center">
        <div className="w-[95%] md:w-[21.375rem] px-8 mt-5 mb-6 md:mb-[1.88rem] text-center text-[#2B3674] text-sm font-[Inter] font-normal leading-[1.375rem] tracking-[-0.02625rem]">
          <p className="text-center">
            Nhập tài khoản email đã đăng ký của bạn để nhận hướng dẫn đặt lại mật khẩu
          </p>
        </div>
        <div className="w-full flex justify-center">
          <SingleInputField
            id="email"
            type="email"
            value={email}
            label="Email"
            onChange={(value) => setEmail(value)}
          />
        </div>
        <button className="w-full mb-4 md:mb-0 py-4 md:w-[20.375rem] md:h-[3.75rem] mt-[0.62rem] bg-[#3D10EA] rounded-xl md:rounded-lg text-white font-[Inter] font-bold text-base leading-4">
          Gửi mã xác minh
        </button>
      </div>
      <div className="w-[23.375rem] mt-[1.3rem] flex justify-center mb-16 md:mb-0">
        <p className="text-[#2B3674] text-sm font-[Inter] font-medium leading-[1.375rem] tracking-[-0.02625rem]">
          Nhớ mật khẩu?{" "}
          <Link to="/login" className="font-bold">
            Đăng nhập
          </Link>
        </p>
      </div>
    </div>
  );
}

export default LinkResetPassword;
