function RankingCard({ rank, name, score, avatar }) {
  return (
    <div className="w-full flex flex-col items-center">
      <div className="text-white text-base font-medium leading-[1.63419rem] h-[2.36169rem] flex flex-row w-full items-center justify-start">
        <div className="min-w-[2rem] flex justify-center">
          <p>
            {rank}
            {"."}
          </p>
        </div>
        {avatar}
        <p className="ml-6 max-h-[3.2rem] overflow-hidden text-ellipsis">{name}</p>
        <div className="flex flex-row justify-end ml-auto min-w-[6rem]">
          <p className="mr-4">{score}</p>
          <img src="/assets/star-icon.svg" alt="star" />
        </div>
      </div>
      <div className="w-full md:w-[57.25rem] h-[0.0625rem] bg-[#FFFFFF]/50 mt-3"></div>
    </div>
  );
}

export default RankingCard;
