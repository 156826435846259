import { Outlet, useNavigate } from "react-router";
import { useEffect } from "react";
import Util from "../utils/Util";
import CookieService from "../services/CookieService";

function ProtectedRoutes({ children, authority }) {
  const navigate = useNavigate();

  useEffect(() => {
    if (!Util.isTokenValid(CookieService.getCookie("token"))) {
      navigate("/", { replace: true });
    } else if (authority && !Util.hasAuthority(CookieService.getCookie("token"), authority)) {
      navigate("/404", { replace: true });
    }
  }, []);

  return <>{children ? children : <Outlet />}</>;
}

export default ProtectedRoutes;
