import "./video.css";
import ReactPlayer from "react-player";
import { useNavigate } from "react-router";
import { useEffect, useState } from "react";
import { submissionAnswers } from "../../../routes/Lesson";
import ResultService from "../../../services/ResultService";
import SubmissionService from "../../../services/SubmissionService";

function Video({ signal, currentExercise, setCurrentExercise, isResult, submittedChoice }) {
  const navigate = useNavigate();
  const [selectedChoice, setSelectedChoice] = useState("");
  const [isSubmited, setIsSubmited] = useState(isResult);
  const [isCorrect, setIsCorrect] = useState(false);
  const question = signal.value.sections[0].questions[currentExercise];
  const numberOfQuestions = signal.value.sections[0].questions.length;

  useEffect(() => {
    if (isResult) {
      setSelectedChoice(submittedChoice);
    } else {
      let radidoBtns = document.querySelectorAll(`input[name='${question.id}']`);
      let findSelected = () => {
        let selected = document.querySelector(`input[name='${question.id}']:checked`).value;
        setSelectedChoice(selected);
      };

      radidoBtns.forEach((radioBtn) => {
        radioBtn.addEventListener("change", findSelected);
      });

      return () => {
        radidoBtns.forEach((radioBtn) => {
          radioBtn.removeEventListener("change", findSelected);
        });
      };
    }
  });

  useEffect(() => {
    if (selectedChoice) {
      const copy = [...submissionAnswers.value];
      const submissionIndex = submissionAnswers.value.findIndex(
        (submission) => submission.questionId === question.id
      );
      if (submissionIndex === -1) {
        copy.push({
          questionId: question.id,
          answer: selectedChoice
        });
      } else {
        copy[submissionIndex] = {
          questionId: question.id,
          answer: selectedChoice
        };
      }
      submissionAnswers.value = copy;

      if (isResult) {
        setIsCorrect(question.answer === selectedChoice);
      } else {
        setIsCorrect(question.answer === selectedChoice && selectedChoice);
      }
    }
  }, [selectedChoice]);

  useEffect(() => {
    if (isSubmited) {
      if (isCorrect) {
        const audio = new Audio("/assets/sound-effects/correct.mp3");
        audio.play();
        audio.remove();
      } else {
        const audio = new Audio("/assets/sound-effects/wrong.mp3");
        audio.play();
        audio.remove();
      }
    }
  }, [isSubmited]);

  async function onClickContinue() {
    signal.value = {
      ...signal.value,
      sections: signal.value.sections.map((section) => ({
        ...section,
        questions: section.questions.map((question, i) => {
          if (i === currentExercise) {
            return {
              ...question,
              isFinished: true,
              userChoice: selectedChoice
            };
          }
          return question;
        })
      }))
    };

    if (currentExercise === numberOfQuestions - 1) {
      try {
        const res = await ResultService.startExam({ id: signal.value.id });
        const resultId = res.data.id;

        await SubmissionService.submitAnswers({
          resultId,
          answers: submissionAnswers.value
        });

        submissionAnswers.value = [];

        navigate(`/result?id=${resultId}`);
      } catch (error) {
        console.error(error);
      }
    } else {
      setSelectedChoice("");
      setIsCorrect(false);
      setIsSubmited(false);
      setCurrentExercise(currentExercise + 1);
    }
  }

  return (
    <div className="w-full py-14 flex flex-col items-center">
      <h3 className="text-[#1B2559] text-lg font-semibold leading-normal mb-[2.3rem]">
        {question.title}
      </h3>
      <div className="w-[90vw] md:w-4/5 flex justify-center relative">
        {/* Download button isn't hidden */}
        <ReactPlayer controls controlsList="nodownload" url={question.media.mediaUrl} />
      </div>
      {isSubmited ? (
        <>
          <div className="flex flex-col w-full items-center">
            <div className="flex flex-row mt-8 gap-2">
              {question.content.map((choice, index) => (
                <div key={index}>
                  <div
                    className={`${
                      question.answer === choice
                        ? "min-w-[6.4375rem] max-w-[9rem] px-1 text-center h-[5.3125rem] rounded-[0.625rem] border border-solid border-[#28B446] bg-[#62FF84] flex items-center justify-center text-[#04821F] text-base font-normal leading-[133.333%]"
                        : (isResult ? question.userChoice : selectedChoice) === choice
                          ? "min-w-[6.4375rem] max-w-[9rem] px-1 text-center h-[5.3125rem] rounded-[0.625rem] border border-solid border-[#E94444] bg-[#FFA999] flex items-center justify-center text-[#FF0000] text-base font-normal leading-[133.333%]"
                          : "hidden"
                    }`}>
                    <p>{choice}</p>
                  </div>
                </div>
              ))}
            </div>
            <div
              className={`h-[0.0625rem] bg-[#A3AED0]  ${
                isResult ? "w-[98%] mt-4" : "w-[39rem] mt-[0.2rem]"
              }`}
            />
            <p
              className={`text-[#2B3674] text-lg font-bold leading-[118.519%] my-8 ${
                isResult && "hidden"
              }`}>
              {isCorrect ? "Correct answer! 👏" : "Wrong answer"}
            </p>
            <button
              onClick={onClickContinue}
              className={`w-[17rem] h-[2.875rem] rounded-2xl bg-[#3D10EA] text-white text-sm font-semibold leading-normal tracking-[-0.0175rem] ${
                isResult && "hidden"
              }`}>
              Continue
            </button>
          </div>
        </>
      ) : (
        <>
          <div className="flex flex-col w-full items-center">
            <img
              src="/assets/answer-bar.png"
              alt="answer bar"
              className="mt-[5.4rem] mb-8 w-[7.9375rem] h-[0.1875rem] object-fill"
            />
            <div className="flex items-center justify-center">
              <div className="flex flex-wrap justify-center">
                {question.content.map((choice, index) => (
                  <div key={index}>
                    <div className="m-[1.35rem] min-w-[6.4375rem] max-w-[9rem] h-[5.3125rem] flex relative items-center justify-center">
                      <input
                        className="absolute w-full h-full m-0 cursor-pointer z-[2] opacity-0 peer"
                        type="radio"
                        name={question.id}
                        value={choice}
                      />
                      <img
                        className="absolute rounded-full bg-white hidden -right-1 -top-1 z-10 peer-checked:block"
                        src="/assets/check-icon.svg"
                        alt="check"
                      />
                      <label
                        htmlFor="answer"
                        className="relative flex items-center justify-center w-full h-full rounded-[0.625rem] border border-solid border-[#A3AED0] px-1 text-[#6274AC] text-base text-center font-normal leading-[133.333%]">
                        {choice}
                      </label>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <button
              onClick={() => setIsSubmited(true)}
              className={`w-[17rem] h-[2.875rem] mt-7 rounded-2xl bg-[#3D10EA] text-white text-sm font-semibold leading-normal tracking-[-0.0175rem] ${
                !selectedChoice && "bg-gray-300 cursor-default"
              }`}
              disabled={!selectedChoice}>
              Submit
            </button>
          </div>
        </>
      )}
    </div>
  );
}

export default Video;
