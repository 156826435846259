import axiosClient from "./AxiosClient";

class LectureService {
  static getLecture({ id }) {
    return axiosClient.get(`/lectures?id=${id}`);
  }

  static createLecture({ title, description, chapterId, order }) {
    return axiosClient.post("/lectures", {
      title,
      description,
      chapterId,
      order
    });
  }

  static updateLecture({ id, title, description, order }) {
    return axiosClient.put("/lectures", {
      id,
      title,
      description,
      order
    });
  }

  static deleteLecture({ id }) {
    return axiosClient.delete(`/lectures?id=${id}`);
  }

  static deleteLectures({ ids }) {
    return axiosClient.post("/lectures/bulk-delete", {
      ids
    });
  }
}

export default LectureService;
