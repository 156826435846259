import axiosClient from "./AxiosClient";

class ResultService {
  static startExam({ id }) {
    return axiosClient.post("/results/start-exam", {
      examId: id,
      startedAt: new Date().toISOString()
    });
  }

  static getResult({ id }) {
    return axiosClient.get(`/results?id=${id}`);
  }

  static getResultsByExams({ examIds }) {
    return axiosClient.post("/results", {
      examIds
    });
  }

  static getTopUsers({ days, page, size }) {
    return axiosClient.get(`/results/top-users?days=${days}&page=${page}&size=${size}`);
  }
}

export default ResultService;
