import axiosClient from "axios";
import CookieService from "./CookieService";
import { currentRequests, globalError, requestIdCount } from "../signals";

export const API_BASE_URL = process.env.REACT_APP_API_URL || "https://api.onthitiengduc.com/api/v1";
export const SERVER_URL = process.env.REACT_APP_SERVER_URL || "http://localhost:5000";

axiosClient.defaults.baseURL = API_BASE_URL;

// CORS config
axiosClient.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
axiosClient.defaults.headers.common["Access-Control-Allow-Methods"] =
  "GET, POST, PUT, DELETE, OPTIONS";
axiosClient.defaults.headers.common["Access-Control-Allow-Headers"] = "*";

axiosClient.interceptors.request.use(function (config) {
  // reset global error on each new request
  globalError.value = undefined;
  const token = CookieService.getCookie("token");
  const requestId = requestIdCount.value + 1;
  // add unique requestId to verify in repsonse
  config.requestId = requestId;
  currentRequests.value.push({
    requestId
  });
  if (token) {
    config.headers.Authorization = "Bearer " + token;
  }
  return config;
});
axiosClient.interceptors.response.use(
  (res) => {
    const requestStacks = [...currentRequests.value];
    const { requestId } = res.config;
    const requestIndexToEject = currentRequests.value.findIndex((x) => x.requestId === requestId);
    if (requestIndexToEject !== -1) {
      requestStacks.splice(requestIndexToEject, 1);
      currentRequests.value = requestStacks;
    }
    return res;
  },
  (err) => {
    const { requestId } = err.response?.config || {};
    const requestStacks = [...currentRequests.value];
    const requestIndexToEject = currentRequests.value.findIndex((x) => x.requestId === requestId);
    if (requestIndexToEject !== -1) {
      const requestToEject = requestStacks.splice(requestIndexToEject, 1)[0];
      console.debug(JSON.stringify(requestToEject));
      currentRequests.value = requestStacks;
    }
    const errorResponse = err.response?.data || {};

    if (errorResponse.status === 500) {
      globalError.value = {
        status: errorResponse.status,
        message:
          errorResponse.message ||
          errorResponse.error ||
          "Đã xảy ra sự cố khi truy cập vào hệ thống!",
        detailsMsg: errorResponse.reason || ""
      };
    }

    return Promise.reject(err);
  }
);

export default axiosClient;
