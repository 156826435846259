import { useState } from "react";
import { addQuestion } from "../../signals/ExamSignal";
import { motion } from "framer-motion";

export function EditorAddNewButton({ sectionIndex }) {
  const [buttonExpanded, setButtonExpanded] = useState(false);

  return (
    <div
      onClick={() => {
        setButtonExpanded(!buttonExpanded);
      }}
      className={
        "w-max h-12 bg-[#2639ED] hover:bg-[#1022cb] rounded-md text-white text-[0.9375rem] font-bold leading-6 flex items-center justify-start px-5 gap-1 py-[0.2rem] z-10 relative"
      }>
      <img className="w-4 h-4 mr-4" src="/assets/plus.svg" alt="plus" />
      <motion.button
        className="bg-white text-[#2639ED] rounded text-sm font-medium leading-8 flex items-center gap-2 w-0"
        animate={{
          width: buttonExpanded ? "auto" : "0",
          visibility: buttonExpanded ? "visible" : "hidden",
          padding: buttonExpanded ? "0.25rem 0.5rem" : "0",
          whiteSpace: "nowrap",
          transition: {
            type: "tween",
            ease: "linear",
            duration: 0.2
          }
        }}
        onClick={() => {
          addQuestion({
            sectionIndex,
            question: {
              title: "New question",
              type: "SINGLE_CHOICE",
              content: ["Option 1", "Option 2"],
              answer: "Option 1"
            }
          });
        }}>
        <i>
          <img
            className="w-4 h-4"
            style={{
              filter:
                "brightness(0) saturate(100%) invert(15%) sepia(78%) saturate(5162%) hue-rotate(239deg) brightness(93%) contrast(99%)"
            }}
            src="/assets/choice.svg"
            alt="choice"
          />
        </i>
        <p>Simple Choice</p>
      </motion.button>
      <motion.button
        className="bg-white text-[#2639ED] rounded text-sm font-medium leading-8 flex items-center gap-2 w-0"
        animate={{
          width: buttonExpanded ? "auto" : "0",
          visibility: buttonExpanded ? "visible" : "hidden",
          padding: buttonExpanded ? "0.25rem 0.5rem" : "0",
          whiteSpace: "nowrap",
          transition: {
            type: "tween",
            ease: "linear",
            duration: 0.2
          }
        }}
        onClick={() => {
          addQuestion({
            sectionIndex,
            question: {
              title: "New question",
              type: "VIDEO_SINGLE_CHOICE",
              content: ["Option 1", "Option 2"],
              answer: "Option 1"
            }
          });
        }}>
        <i>
          <img
            className="w-4 h-4"
            style={{
              filter:
                "brightness(0) saturate(100%) invert(15%) sepia(78%) saturate(5162%) hue-rotate(239deg) brightness(93%) contrast(99%)"
            }}
            src="/assets/video-circle-play.svg"
            alt="choice"
          />
        </i>
        <p>Video Choice</p>
      </motion.button>
      <motion.button
        className="bg-white text-[#2639ED] rounded text-sm font-medium leading-8 flex items-center gap-2 w-0"
        animate={{
          width: buttonExpanded ? "auto" : "0",
          visibility: buttonExpanded ? "visible" : "hidden",
          padding: buttonExpanded ? "0.25rem 0.5rem" : "0",
          whiteSpace: "nowrap",
          transition: {
            type: "tween",
            ease: "linear",
            duration: 0.2
          }
        }}
        onClick={() => {
          addQuestion({
            sectionIndex,
            question: {
              title: "New question",
              type: "CONVERSATION_SINGLE_CHOICE",
              content: {
                conversation: [
                  "Lorem ipsum dolor amet ipsum dolor amet ipsum dolor amet dolor amet dolor amet",
                  "Lorem ipsum dolor amet ipsum dolor amet ipsum dolor amet dolor amet dolor amet ___"
                ],
                choices: ["Option 1", "Option 2"]
              },
              answer: "Option 1"
            }
          });
        }}>
        <i>
          <img
            className="w-4 h-4"
            style={{
              filter:
                "brightness(0) saturate(100%) invert(15%) sepia(78%) saturate(5162%) hue-rotate(239deg) brightness(93%) contrast(99%)"
            }}
            src="/assets/conversation.svg"
            alt="conversation"
          />
        </i>
        <p>Conversation Choice</p>
      </motion.button>
      <p
        className={`${
          buttonExpanded ? "invisible w-0 text-transparent" : "visible w-16"
        } transition-all duration-200 ease-linear whitespace-nowrap -ml-2`}>
        Add new
      </p>
    </div>
  );
}
