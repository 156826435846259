import { History } from "../components/History";
import { Filter } from "../components/filter/Filter";
import { TotalPoints } from "../components/TotalPoints";
import CustomPagination from "../components/CustomPagination";
import { useState } from "react";

const starPointHistory = [
  {
    type: "deduction",
    title: "Lorem ipsum dolor amet",
    time: "2023-11-29T19:53:01.836Z",
    amount: -12500
  },
  {
    type: "gain",
    title: "Lorem ipsum dolor amet",
    time: "2023-11-17T21:27:01.836Z",
    amount: 39000
  },
  {
    type: "gain",
    title: "Lorem ipsum dolor amet",
    time: "2023-11-15T07:24:06.836Z",
    amount: 12750
  },
  {
    type: "consumption",
    title: "Lorem ipsum dolor amet",
    time: "2023-11-12T14:36:01.836Z",
    amount: -29500
  },
  {
    type: "deduction",
    title: "Lorem ipsum dolor amet",
    time: "2023-11-07T04:24:01.836Z",
    amount: -12500
  },
  {
    type: "deduction",
    title: "Lorem ipsum dolor amet",
    time: "2023-10-29T09:43:01.836Z",
    amount: -12500
  },
  {
    type: "gain",
    title: "Lorem ipsum dolor amet",
    time: "2023-10-25T16:53:01.836Z",
    amount: 39000
  },
  {
    type: "gain",
    title: "Lorem ipsum dolor amet",
    time: "2023-10-17T15:55:01.836Z",
    amount: 12750
  },
  {
    type: "consumption",
    title: "Lorem ipsum dolor amet",
    time: "2023-10-09T21:34:01.836Z",
    amount: -29500
  },
  {
    type: "deduction",
    title: "Lorem ipsum dolor amet",
    time: "2023-10-01T19:23:01.836Z",
    amount: -12500
  },
  {
    type: "deduction",
    title: "Lorem ipsum dolor amet",
    time: "2023-09-17T19:10:01.836Z",
    amount: -12500
  },
  {
    type: "gain",
    title: "Lorem ipsum dolor amet",
    time: "2023-09-09T19:53:01.836Z",
    amount: 39000
  },
  {
    type: "gain",
    title: "Lorem ipsum dolor amet",
    time: "2023-08-31T19:53:01.836Z",
    amount: 12750
  },
  {
    type: "deduction",
    title: "Lorem ipsum dolor amet",
    time: "2023-02-13T22:17:01.836Z",
    amount: -4500
  },
  {
    type: "consumption",
    title: "Lorem ipsum dolor amet",
    time: "2023-02-06T22:17:01.836Z",
    amount: -9500
  },
  {
    type: "consumption",
    title: "Lorem ipsum dolor amet",
    time: "2023-02-01T15:17:01.836Z",
    amount: -19500
  },
  {
    type: "gain",
    title: "Lorem ipsum dolor amet",
    time: "2023-01-31T21:33:01.836Z",
    amount: 22450
  },
  {
    type: "consumption",
    title: "Lorem ipsum dolor amet",
    time: "2023-01-22T11:03:01.836Z",
    amount: -5500
  },
  {
    type: "deduction",
    title: "Lorem ipsum dolor amet",
    time: "2023-01-09T15:17:01.836Z",
    amount: -19500
  },
  {
    type: "consumption",
    title: "Lorem ipsum dolor amet",
    time: "2022-12-22T19:33:01.836Z",
    amount: -4500
  },
  {
    type: "gain",
    title: "Lorem ipsum dolor amet",
    time: "2022-11-28T23:55:01.836Z",
    amount: 40000
  }
];

function StarPoint() {
  const [currentPage, setCurrentPage] = useState(1);
  const firstIndex = (currentPage - 1) * 15;
  const lastIndex = currentPage * 15 - 1;
  const records = starPointHistory.slice(firstIndex, lastIndex + 1);

  return (
    <div className="w-full flex flex-col items-center bg-[#F4F7FE] font-[Inter]">
      <div className="w-[93.5%] md:w-[75.7%] md:bg-white relative flex flex-col rounded-xl mt-24 md:mt-48 mb-8">
        <div className="bg-[white] rounded-xl">
          <TotalPoints isStarPoint={true} />
          <ShareSection />
          <div className="flex flex-row items-center gap-[0.4rem] ml-[10%] md:ml-[8.6%] mt-6">
            <img
              src="/assets/filter-icon.svg"
              alt="filter icon"
              className="w-6 md:w-[1.875rem] h-6 md:h-[1.875rem]"
            />
            <h2 className="text-[#1B2559] text-base md:text-[1.75rem] font-semibold leading-normal">
              Lorem ipsum dolor amet
            </h2>
          </div>
          <div className="w-full flex flex-col items-center justify-center">
            <Filter />
          </div>
        </div>
        <div className="w-full flex flex-col items-center justify-center mt-8 md:mt-0">
          <History history={records} isStarPoint={true} />
          <div className="mb-8">
            <CustomPagination
              setCurrentPage={setCurrentPage}
              count={
                Math.floor(starPointHistory.length / 15) + 1 < 20
                  ? Math.floor(starPointHistory.length / 15) + 1
                  : 20
              }
            />
          </div>
        </div>
      </div>
      <div></div>
    </div>
  );
}

export default StarPoint;

function ShareSection() {
  return (
    <div className="mt-28 md:mt-[13rem]">
      <div className="flex flex-row items-center gap-[0.7rem] ml-[10%] md:ml-[8.6%]">
        <img
          src="/assets/share-icon.svg"
          alt="share icon"
          className="w-5 md:w-[1.5625rem] h-[1.04169rem] md:h-[1.30206rem]"
        />
        <h2 className="text-[#1B2559] text-base md:text-[1.75rem] font-semibold leading-normal">
          Lorem ipsum dolor amet
        </h2>
      </div>
      <div className="ml-[10%] md:ml-[8.6%] mt-4 flex flex-row gap-2 md:gap-5 items-center">
        <p className="max-w-[65%] text-[#A3AED0] text-[0.9375rem] font-medium leading-normal md:ml-[2.26rem]">
          Lorem ipsum dolor amet ipsum dolor amet ipsum dolorm:{" "}
        </p>
        <button className="w-[4.38225rem] md:w-[8.75rem] h-10 md:h-[2.875rem] bg-[#01B574] rounded-3xl text-white text-[0.9375rem] font-semibold leading-normal tracking-[-0.01875rem]">
          <span>Chia sẻ</span>
        </button>
      </div>
    </div>
  );
}
