import { useEffect } from "react";
import { EditorTopBar } from "../components/editor/EditorTopBar";
import { defaultExam, exam } from "../signals/ExamSignal";
import { EditorBody } from "../components/editor/EditorBody";
import { useSearchParams } from "react-router-dom";
import ExamService from "../services/ExamService";

function Editor() {
  let [searchParams] = useSearchParams();
  let examId = searchParams.get("id");

  useEffect(() => {
    if (examId) {
      ExamService.getExamEditor({ id: examId })
        .then((res) => {
          const examData = res.data;
          delete examData.createdAt;
          delete examData.userId;
          examData.sections.forEach((section) => {
            delete section.createdAt;
            section.questions.forEach((question) => {
              delete question.createdAt;
              question.content = JSON.parse(question.content);
            });
          });

          exam.value = examData;
        })
        .catch((err) => {
          console.error(err);
        });
    } else {
      exam.value = {
        ...defaultExam,
        lectureId: exam.value.lectureId
      };
    }
  }, []);

  return (
    <div className="grow w-full flex flex-col items-center">
      <EditorTopBar />
      <EditorBody />
    </div>
  );
}

export default Editor;
