import { groupBy } from "lodash";

export function History({ history, isStarPoint }) {
  let objectToMap = (obj) => new Map(Object.entries(obj).sort((a, b) => b[0] - a[0]));

  let groupByYear = groupBy(history, ({ createdAt }) => new Date(createdAt).getFullYear());
  groupByYear = objectToMap(groupByYear);
  let result = new Map();
  groupByYear.forEach((value, key) => {
    let groupByMonth = groupBy(value, ({ createdAt }) => new Date(createdAt).getMonth() + 1);
    groupByMonth = objectToMap(groupByMonth);
    result.set(key, groupByMonth);
  });

  return (
    <div className="w-full md:w-[70%] font-[Inter]">
      <div className="w-full flex flex-col justify-center">
        {[...result.entries()].map(([year, months]) => {
          return [...months.entries()].map(([month, records]) => {
            return (
              <div key={`${month}/${year}`} className="mb-8 md:mb-12">
                <h3 className="text-[#656565] text-base font-medium mb-4 md:mb-6">{`Tháng ${month}/${year}`}</h3>
                <div className="w-full p-4 md:px-0 bg-white rounded-[0.625rem]">
                  {records.map((record, i) => (
                    <div key={i} className="md:pl-4">
                      <HistoryCard
                        unit={isStarPoint ? "star" : "money"}
                        type={record.type}
                        title={record.title}
                        time={new Date(record.createdAt)}
                        amount={record.amount}
                        isStarPoint={isStarPoint}
                      />
                      {i < records.length - 1 && <HistoryBar />}
                    </div>
                  ))}
                </div>
              </div>
            );
          });
        })}
      </div>
    </div>
  );
}

function HistoryCard({ type, title, time, amount, isStarPoint }) {
  const iconMap = {
    DEPOSIT: { url: "/assets/receive-point.svg", alt: "gain icon" },
    WITHDRAW: { url: "/assets/point-out1.svg", alt: "deduction icon" },
    REFERRAL: { url: "/assets/point-out2.svg", alt: "consumption icon" }
  };
  const unitMap = {
    star: (
      <>
        <img
          src="/assets/star-icon.svg"
          alt="star"
          className="w-[0.87506rem] h-[0.87506rem] ml-[0.3rem]"
        />
      </>
    ),
    money: "đ"
  };

  return (
    <div>
      <div className="flex flex-row items-center">
        <img src={iconMap[type].url} alt={iconMap[type].alt} />
        <div className="flex flex-col ml-4">
          <p
            className="text-[#1B2559] text-[0.9375rem] font-medium leading-normal tracking-[-0.0125rem]"
            style={{ fontFeatureSettings: "'clig' off, 'liga' off" }}>
            {title}
          </p>
          <p
            className="text-[#979797] text-[0.875rem] font-normal leading-[1.4rem] tracking-[-0.0125rem]"
            style={{ fontFeatureSettings: "'clig' off, 'liga' off" }}>
            {`${time.getHours()}:${time.getMinutes()} ${time.getDate()}/${
              time.getMonth() + 1
            }/${time.getFullYear()}`}
          </p>
        </div>
        <div className="flex flex-row ml-auto items-center">
          <span
            className={`${
              amount >= 0 ? "text-[#1D971A]" : "text-[#E35214]"
            } text-base font-semibold leading-[1.6rem] tracking-[-0.0125rem]`}
            style={{ fontFeatureSettings: "'clig' off, 'liga' off" }}>
            {amount > 0 ? "+" : ""}
            {amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
          </span>
          <div
            className={`${
              amount >= 0 ? "text-[#1D971A]" : "text-[#E35214]"
            } text-base font-semibold leading-[1.6rem] tracking-[-0.0125rem]`}>
            {unitMap[isStarPoint ? "star" : "money"]}
          </div>
        </div>
      </div>
    </div>
  );
}

function HistoryBar() {
  return <div className="w-full h-[0.069rem] bg-[#EBEBF0] my-4" />;
}
